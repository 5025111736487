<template>
    <div>
        <div class="row mb-4">
            <div class="col-6">
                <div class="relance-stat">
                    <div class="relance-stat-main">
                        <div class="d-flex">
                            <div class="relance-stat-main-count">
                                {{ getFactureCount(suggestions) }}
                            </div>
                            <div class="relance-stat-main-amount">
                                ({{ getMontantTotalRelances(suggestions) }})
                            </div>
                        </div>
                        <div class="relance-stat-main-title">FACTURES À RELANCER</div>
                    </div>
                    <div class="relance-stat-detail">
                        <div v-for="payeur in payeurs">
                            {{ Translator.trans('libelle.'+payeur+'s') }}
                        </div>
                    </div>
                    <div class="relance-stat-detail">
                        <div v-for="payeur in payeurs">
                            {{ getFactureCount(suggestions.filter((item) => item.payeur === payeur)) }}
                            <small>({{ getMontantTotalRelances(suggestions.filter((item) => item.payeur === payeur)) }})</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="relance-stat">
                    <div class="relance-stat-main">
                        <div class="d-flex">
                            <div class="relance-stat-main-count">0</div>
                            <div class="relance-stat-main-amount">(0,00 €)</div>
                        </div>
                        <div class="relance-stat-main-title">RELANCES EN ATTENTE</div>
                    </div>
                    <div class="relance-stat-detail">
                        <div>Niveau 1</div>
                        <div>Niveau 2</div>
                        <div>Niveau 3</div>
                    </div>
                    <div class="relance-stat-detail">
                        <div>0<small>(0,00 €)</small></div>
                        <div>0<small>(0,00 €)</small></div>
                        <div>0<small>(0,00 €)</small></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Suggestions de relances</div>
                <div>
                    filtre type payeur | champ recherche
                </div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-table table-class="table-compact" ref="suggestionTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="suggestions" :fields="suggestionsFields" :per-page="perPage" :current-page="suggestionsCurrentPage" sort-by="date" primary-key="id">
                        <template #empty>
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #table-busy>
                            <div class="p-3">
                                <b-spinner class="mr-2"></b-spinner> Chargement…
                            </div>
                        </template>
                        <template #cell(payeur)="{item}">
                            <span class="badge badge-secondary">{{ Translator.trans('libelle.'+item.payeur) }}</span>
                            <template v-if="'patient' === item.payeur">
                                <a :href="Router.generate('shared.patient.view', {app: 'facturation', id: item.patient.id})">
                                    {{ item.patient.text }}
                                </a>
                            </template>
                        </template>
                        <template #cell(nombreFacture)="{item}">
                            {{ item.factureAbstractCollection.length }}
                        </template>
                        <template #cell(montantTotal)="{value}">
                            {{ Euro(value) }}
                        </template>
                        <template #cell(structure)="{value}">
                            <a :href="Router.generate('referentiel.structure.view', {id: value.id})" :title="value.libelleComplet" class="badge badge-block badge-secondary label-outline text-nowrap">
                                {{ value.libelle }}
                            </a>
                        </template>
                        <template #cell(action)="{item}">
                            TODO
                        </template>
                    </b-table>
                    <table-pagination :count="suggestions.length" :per-page="perPage" v-model="suggestionsCurrentPage"></table-pagination>
                </div>
            </div>
        </div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Relances à reconduire</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    TODO
                </div>
            </div>
        </div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Relances à envoyer</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    TODO
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Widget from '../../../../Mixins/TableauDeBord/Widget';
import Euro from "@/Vue/Filters/Euro";
import Translator from "@/App/Translator";
import Request from "@/App/Request";
import Router from "@/App/Router";

export default {
    mixins: [Widget],
    data() {
        return {
            loading: false,
            perPage: 15,
            suggestions: [],
            suggestionsCurrentPage: 1,
            payeurs: ['patient', 'caisse', 'mutuelle', 'prescripteur'],
            Router,
            Translator,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        suggestionsFields() {
            return [
                {key: 'payeur', label: Translator.trans('libelle.payeur'), sortable: true},
                {key: 'date2', label: 'Facture plus ancienne', thClass: 'width-1px text-nowrap', sortable: true},
                {key: 'nombreFacture', label: Translator.trans('libelle.nombre-factures-abreviation'), sortable: true, thClass: 'width-1px text-nowrap'},
                {key: 'montantTotal', label: Translator.trans('libelle.montant-total'), sortable: true, tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
                this.structures.length > 1 ? {key: 'structure', label: Translator.trans('libelle.societe'), sortable: true, thClass: 'width-1px'} : null,
                {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-right text-nowrap'},
            ];
        },
        relancesFields() {
            return [
                {key: 'date', label: Translator.trans('libelle.date'), sortable: true, thClass: 'width-1px'},
                {key: 'montantTotal', label: Translator.trans('libelle.montant-total'), sortable: true, tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
                this.structures.length > 1 ? {key: 'structure', label: Translator.trans('libelle.societe'), sortable: true, thClass: 'width-1px'} : null,
                {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-right text-nowrap'},
            ];
        },
    },
    methods: {
        getFactureCount(relances) {
            return relances.reduce((count, item) => count + item.factureAbstractCollection.length, 0);
        },
        getMontantTotalRelances(relances) {
            return Euro(relances.reduce((montant, item) => montant + parseFloat(item.montantTotal), 0));
        },
        refresh() {
            if(!this.structures.length) {
                this.suggestions = [];

                return;
            }

            this.loading = true;
            Request.postJson(Router.generate('facturation.tableau-de-bord.relances'), {
                tableau_de_bord_filter: {
                    structure: this.structures,
                }
            }).then((data) => {
                this.suggestions = data.suggestions;

                this.loading = false;
                this.$emit('refresh');
            });
        }
    },
    watch: {
        structures() {
            this.refresh();
        },
    }
}
</script>