'use strict';

import swal from 'sweetalert2'
import Moment from "moment";
import {computed, createApp, nextTick, onMounted, provide, ref, watch} from 'vue'
import Router from "@/App/Router";
import Fuse from 'fuse.js';
import Translator from "@/App/Translator";
import _ from 'lodash';
import Euro from "@/Vue/Filters/Euro";
import Request from "@/App/Request";
import VueChartkick from "vue-chartkick";
import 'chartkick/chart.js'
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";

App.Regulation.Trajet = class {
    static getEtatRessource(ressource, allTrajetCollectionByRessourceId) {
        if (ressource.pauseCourante) {
            return 'pause';
        }

        if (ressource._type === 'vehicule') {
            if (ressource.estIndisponible) {
                return 'indisponible';
            }
        }
        else if (ressource._type === 'personnel') {
            const now = Moment();
            const type = ressource.typeCourant;

            if (!type || !type.estTravailAbstract || type._debut.isAfter(now) || type._fin.isBefore(now)) {
                return 'indisponible';
            }
        }
        else if (ressource._type === 'equipage') {
            const now = Moment();

            if (ressource._debut.isAfter(now) || (ressource._fin && ressource._fin.isBefore(now))) {
                return 'indisponible';
            }
        }

        let etat = 'disponible';

        for (const trajet of allTrajetCollectionByRessourceId[ressource._type][ressource.id] || []) {
            if (!trajet.estAnnule) {
                if (trajet.estEnCours) {
                    return trajet.topDestinationArriveeReel ? 'fin-mission' : 'en-mission';
                }

                if (!trajet.estTermine) {
                    etat = 'affecte';
                }
            }
        }

        return etat;
    }

    static initMap() {
        this.idCount = 0;

        $('.js-trajet-map').each((i, el) => {
            createApp({
                template: '<trajet-map height="180px" :latitude-degre-prise-en-charge="latitudeDegrePriseEnCharge" :longitude-degre-prise-en-charge="longitudeDegrePriseEnCharge" :latitude-degre-destination="latitudeDegreDestination" :longitude-degre-destination="longitudeDegreDestination"></trajet-map>',
                data() {
                    return JSON.parse(el.dataset.data);
                },
            }).use(App.Pinia).mount(el);
        });
    }
};

App.Regulation.Trajet.Synoptique = class {
    constructor(data) {
        createApp({
            delimiters: ['[[', ']]'],
            data: () => ({
                trajets: [],
                vehicules: [],
                personnels: [],
                equipages: [],
                colonnes: {
                    'disponible': {
                        variant: 'success',
                        title: 'Disponible',
                    },
                    'affecte+1h': {
                        variant: 'info',
                        title: 'Affecté +1h',
                    },
                    'affecte-1h': {
                        variant: 'info',
                        title: 'Affecté -1h',
                    },
                    'en-mission': {
                        variant: 'danger',
                        title: 'En mission',
                    },
                    'fin-mission': {
                        variant: 'warning',
                        title: 'En fin de mission',
                    },
                    'pause': {
                        variant: 'warning',
                        title: 'En pause',
                    },
                },
                categories: {
                    ambu: {
                        title: 'AMBU',
                        icon: 'im-ambulance',
                    },
                    vsl: {
                        title: 'VSL',
                        icon: 'im-car',
                    },
                    taxi: {
                        title: 'TAXI',
                        icon: 'im-car2',
                    },
                    autre: {
                        title: 'Autre',
                        icon: 'im-rocket',
                    },
                    equipages: {
                        title: 'Équipages',
                        icon: 'im-link'
                    },
                    personnels: {
                        title: 'Personnels',
                        icon: 'im-man',
                    }
                }
            }),
            mounted() {
                let partsToRefresh = [];
                const refreshThrottled = _.throttle(() => {
                    this.refresh(partsToRefresh);
                    partsToRefresh = [];
                }, 1000);

                App.webSocket.subscribe('regulation/transport/trajet', (uri, data) => {
                    if(data.action === 'refresh') {
                        partsToRefresh = _.union(partsToRefresh, data.parts);
                        refreshThrottled();
                    }
                });

                this.refresh();

                document.dispatchEvent(new Event('app:vuejs:mounted'));
            },
            methods: {
                refresh(parts = null) {
                    let requestData = {
                        trajet_filter: data.filter
                    };
                    requestData.trajet_filter.rendezVous = Moment().format('DD/MM/YYYY');

                    if(parts !== null) {
                        requestData.parts = parts;
                    }

                    Request.postJson(Router.generate('regulation.trajet.list.ajax'), requestData).then((data) => {
                        this.handleData(data);
                    }, () => {});
                },
                handleData(data) {
                    if(data.index) {
                        this.trajets = data.index.trajetCollection;
                    }

                    if(data.vehicules) {
                        this.handleRessourceData(data.vehicules, 'vehicule');
                    }
                    if(data.equipages) {
                        this.handleRessourceData(data.equipages, 'equipage');
                    }
                    if(data.personnels) {
                        this.handleRessourceData(data.personnels, 'personnel');
                    }
                },
                handleRessourceData(ressources, type) {
                    for(const ressource of ressources) {
                        ressource._type = type;
                        ressource._trajetCollection = this.allTrajetCollectionByRessourceId[ressource._type][ressource.id] || [];

                        if (type === 'equipage') {
                            ressource._debut = ressource.debut !== null ? Moment(ressource.debut, 'DD/MM/YYYY HH:mm') : null;
                            ressource._fin = ressource.fin !== null ? Moment(ressource.fin, 'DD/MM/YYYY HH:mm') : null;
                        }

                        if (type === 'equipage') {
                            ressource._debut = ressource.debut !== null ? Moment(ressource.debut, 'DD/MM/YYYY HH:mm') : null;
                            ressource._fin = ressource.fin !== null ? Moment(ressource.fin, 'DD/MM/YYYY HH:mm') : null;
                        }

                        const typeCourant = ressource.typeCourant;

                        if (typeCourant) {
                            const debut = typeCourant.debutReel || typeCourant.debutPrevu;
                            const fin = typeCourant.finReel || typeCourant.finPrevu;

                            typeCourant._debut = debut !== null ? Moment(debut.date+' '+debut.time, 'DD/MM/YYYY HH:mm') : null;
                            typeCourant._fin = fin !== null ? Moment(fin.date+' '+fin.time, 'DD/MM/YYYY HH:mm') : null;
                        }
                    }

                    this[type+'s'] = ressources;
                },
                getColonne(ressource) {
                    let colonne = this.getEtatRessource(ressource);

                    if(!['disponible', 'affecte', 'en-mission', 'fin-mission', 'pause'].includes(colonne)) {
                        return null;
                    }

                    if('affecte' === colonne) {
                        let minDate = null;

                        for(const trajet of ressource._trajetCollection) {
                            if(trajet.etat !== 3) {
                                const newMinDate = trajet._debutMissionPrevu;

                                if (newMinDate && (null === minDate || newMinDate.isBefore(minDate))) {
                                    minDate = newMinDate;
                                }
                            }
                        }

                        if(minDate && minDate.isBefore(Moment().add(1, 'hour'))) {
                            colonne = 'affecte-1h';
                        } else {
                            colonne = 'affecte+1h';
                        }
                    }

                    return colonne;
                },
                getEtatRessource(ressource) {
                    return App.Regulation.Trajet.getEtatRessource(ressource, this.allTrajetCollectionByRessourceId);
                },
            },
            computed: {
                allTrajetCollectionByRessourceId() {
                    const fieldsByType = {
                        personnel: ['personnel1', 'personnel2'],
                        vehicule: ['vehicule'],
                        equipage: ['equipage'],
                    };

                    return this.trajets.reduce((res, trajet) => {
                        for (const type in fieldsByType) {
                            if (!res[type]) {
                                res[type] = {};
                            }

                            for (const field of fieldsByType[type]) {
                                if (null !== trajet[field]) {
                                    if (!res[type][trajet[field]]) {
                                        res[type][trajet[field]] = [];
                                    }
                                    if (!res[type][trajet[field]].includes(trajet)) {
                                        res[type][trajet[field]].push(trajet);
                                    }
                                }
                            }
                        }

                        return res;
                    }, {
                        personnel: {},
                        vehicule: {},
                        equipage: {},
                    });
                },
                chartOptions() {
                    return {
                        plugins: {
                            tooltip: {
                                enabled: false,
                            },
                        },
                        scales: {
                            x: {
                                grid: {display: false},
                                ticks: {autoSkip: true, maxTicksLimit: 31, maxRotation: 0, fontColor: '#8d9ea7', fontSize: 18}
                            },
                            y: {
                                grid: {display: false},
                                ticks: {display: false}
                            },
                        },
                    };
                },
                chartData() {
                    const time = Moment('1996-08-22 00:00:00');
                    let chartData = [
                        {
                            data: [],
                        },
                    ];

                    for (let i = 0; i < 24; ++i) {
                        for (let j = 0; j < 4; ++j) {
                            time.hours(i).minutes(j*15);

                            const timeStr = time.format('HH:mm');
                            const nextTimeStr = time.clone().add(15, 'minutes').format('HH:mm');
                            let count = 0;

                            for (const trajet of this.trajets) {
                                const firstTop = trajet.topDebut || trajet.topPriseEnChargeArrivee;
                                const lastTop = trajet.topFin || trajet.topDestinationArrivee || trajet.topPriseEnChargeArrivee;
                                if (lastTop.time >= timeStr && firstTop.time < nextTimeStr) {
                                    ++count;
                                }
                            }

                            chartData[0].data.push([timeStr, count]);
                        }
                    }

                    time.hours(23).minutes(59);
                    chartData[0].data.push([time.format('HH:mm'), 0]);

                    return chartData;
                },
                vehiculesByType() {
                    return this.vehicules.reduce((res, item) => (res[item.icone.title] = item, res), {});
                },
                trajetsEnCoursCount() {
                    return this.trajets.filter((trajet) => {
                        return trajet.estEnCours;
                    }).length;
                },
                trajetsTerminesCount() {
                    return this.trajets.filter((trajet) => {
                        return trajet.estTermine;
                    }).length;
                },
                trajetsPlanifiesCount() {
                    return this.trajets.filter((trajet) => {
                        return !trajet.estEnCours && !trajet.estTermine && !trajet.estAnnule;
                    }).length;
                },
                compteurs() {
                    let compteurs = {};
                    for(const categorie in this.categories) {
                        compteurs[categorie] = {};

                        for(const colonne in this.colonnes) {
                            compteurs[categorie][colonne] = {count: 0, ressources: []};
                        }
                    }

                    for(const vehicule of this.vehicules) {
                        let categorie = 'autre';
                        if(vehicule.categorie.estAmbu) {
                            categorie = 'ambu';
                        }
                        else if(vehicule.categorie.estVsl) {
                            categorie = 'vsl';
                        }
                        else if(vehicule.categorie.estTaxi) {
                            categorie = 'taxi';
                        }

                        const col = this.getColonne(vehicule);

                        if(col) {
                            compteurs[categorie][col].count++;
                            compteurs[categorie][col].ressources.push(vehicule);
                        }
                    }

                    for(const field of ['personnels', 'equipages']) {
                        for(const ressource of this[field]) {
                            const col = this.getColonne(ressource);

                            if(col) {
                                compteurs[field][col].count++;
                                compteurs[field][col].ressources.push(ressource);
                            }
                        }
                    }

                    return compteurs;
                }
            },
        }).mount('#app');
    }
};

App.Regulation.Trajet.Index = class {
    constructor(params) {
        const el = document.getElementById('app');

        createApp({
            delimiters: ['[[', ']]'],
            setup() {
                const store = useRegulationStore();

                const {
                    refresh,
                    step,
                    print,
                    canSendPause,
                    canSendFinService,
                    explodeRessource,
                    envoyerTla,
                    setTrajet,
                    updateTrajet,
                    saveSimultane,
                    editSimultane,
                    toggleSimultane,
                    cancelSimultane,
                    onMessagerieSend,
                    getRessourcesAppareilCollection,
                    categoriesColor
                } = store;

                const { rendezVousInput,
                    structureCollection,
                    structuresRacine,
                    trajetCollection,
                    currentTrajet,
                    currentTrajetId,
                    ajaxLocked,
                    patientIds,
                    trajetCollectionPretEnvoiTla,
                    chartData,
                    chartOptions,
                    filter,
                    isLoading,
                    salaries,
                    estimationMontantTouteTaxeFiltre,
                    estimationMontantTouteTaxeTotal,
                    trajetsEstimationByCategorieChartData,
                    trajetsEstimationByCategorieChartConfig,
                    allTrajetCollection,
                    trajetIndexById,
                    personnels,
                    personnelsById,
                    vehicules,
                    vehiculesById,
                    equipages,
                    equipagesById,
                    showAdvancedFilters,
                    rendezVous,
                    simultane,
                    simultaneTrajetIds,
                    simultaneLoading,
                    messagerie,
                    hasUnreadMessage,
                    sort,
                    filterEtatChoices,
                    trajetsCountByCategorieChartData,
                    trajetsByCategorie,
                    trajetsCountByCategorie,
                    filterCategorieChoices,
                } = storeToRefs(store)
                rendezVousInput.value = el.dataset.rendezVous;

                structuresRacine.value = params.structuresRacine;

                onMounted(() => {
                    // Date picker
                    let $body = $('body');

                    // TREE SELECT
                    let treeSelectInputSelector = 'input[name="trajet_filter[structureCollection][]"]';
                    $body.on('change', treeSelectInputSelector, () => {
                        structureCollection.value = $.map($(treeSelectInputSelector + ':checked'), function(el){return el.value; });
                    }).one('treeselect.init', () => {
                        $(treeSelectInputSelector).filter(':checked').change();
                    }).on('change', 'select#trajet_filter_structureCollection', (e) => {
                        structureCollection.value = $(e.currentTarget).val();
                    });

                    $body.on('rightPanel.open', () => {
                        App.Layout.updateShuffle();
                    });

                    $body.on('click', (e) => {
                        if ($(e.target).parents('.edit-in-place').length === 0 && $(e.target).parents('.clockpicker-popover').length === 0) {
                            editInPlaceField.value = null;
                        }
                    });

                    // CONTEXT MENU
                    let handleMenuLink = (url, ajax = false, post = null) => {
                        return (k, i, e) => {
                            if(ajax) {
                                ajaxLocked.value = true;

                                if (post) {
                                    Request.postJson(url, post).then((data) => {
                                        if (data.success) {
                                            setTrajet(data.data.id, data.data);
                                        }
                                    }).finally(() => {
                                        ajaxLocked.value = false;
                                    });
                                } else {
                                    Request.getJson(url).then((data) => {
                                        setTrajet(data.id, data);
                                    }, (response) => {
                                        // err
                                    }).finally(() => {
                                        ajaxLocked.value = false;
                                    });
                                }

                            } else {
                                const newTab = params.modeOuvertureTransport === 1 || (e && (e.which === 2 || (e.which === 1 && e.ctrlKey)));

                                if (newTab) {
                                    let win = window.open(url, '_blank');
                                    if (win) win.focus();
                                } else {
                                    window.location.href = url;
                                }
                            }
                        }
                    };

                    $.contextMenu({
                        selector: '#app tbody tr',
                        build: ($trigger, event) => {
                            let index = $trigger.index();
                            let trajet = trajetCollection.value[index];

                            if(!trajet) {
                                return false;
                            }

                            let fromBtn = $(event.target).is('.js-context-menu-btn');
                            focusTrajetIndex.value = index;
                            let items = {};

                            // Voir / Modifier
                            if(!fromBtn) {
                                if(trajet.roles.ROLE_REGULATION_TRANSPORT_VIEW) {
                                    items.view = {name: Translator.trans('action.voir'), icon: 'fa-solid fa-eye', callback: handleMenuLink(Router.generate('shared.transport.view', {app: 'regulation', id: trajet.transport.id, trajet: trajet.type}))};
                                }
                                if(trajet.roles.ROLE_REGULATION_TRANSPORT_EDIT) {
                                    items.edit = {name: Translator.trans('action.modifier'), icon: 'fa-solid fa-pencil', callback: handleMenuLink(Router.generate('shared.transport.edit', {app: 'regulation', id: trajet.transport.id}))};
                                }
                            }

                            // Envoi TLA
                            if (trajet.roles.ROLE_REGULATION_TRAJET_TLA_SEND) {
                                if(!fromBtn) {
                                    items.sep1 = '';
                                }

                                if (trajet.canRetrySendTla) {
                                    items.renvoyerTla = {name: params.libelles.renvoyerTla, icon: 'fa-solid fa-paper-plane', disabled: !trajet.canRetrySendTla || ajaxLocked.value || trajet.estEnvoyeTla, callback: () => envoyerTla([trajet])};
                                } else {
                                    items.envoyerTla = {name: params.libelles.envoyerTla, icon: 'fa-solid fa-paper-plane', disabled: !trajet.canFirstSendTla || ajaxLocked.value || trajet.estEnvoyeTla, callback: () => envoyerTla([trajet])};
                                }
                            }

                            items.envoyerMessage = getMessageItem([trajet._vehicule, trajet._personnel1, trajet._personnel2]);

                            items.envoyerPause = {name: params.libelles.envoyerPause, disabled: !canSendPause(trajet._personnel1, trajet._personnel2, trajet._vehicule), icon: 'fa-solid fa-pause', callback: () =>
                                openEnvoiPauseSwal(trajet._personnel1, trajet._personnel2, trajet._vehicule, trajet._equipage)
                            };
                            items.envoyerFinService = {name: params.libelles.envoyerFinService, disabled: !canSendFinService(trajet._personnel1, trajet._personnel2, trajet._vehicule), icon: 'fa-solid fa-stop', callback: () =>
                                openEnvoiFinServiceSwal(trajet._personnel1, trajet._personnel2, trajet._vehicule, trajet._equipage)
                            };

                            // Annule / Ne pas facture
                            if(trajet.roles.ROLE_REGULATION_TRANSPORT_EDIT) {
                                items.sep2 = '';
                                if (trajet.estAnnule) {
                                    items.reactiver = {
                                        name: params.libelles.reactiver,
                                        icon: 'fa-regular fa-circle-check',
                                        disabled: ajaxLocked.value,
                                        callback: handleMenuLink(Router.generate('regulation.trajet.reactiver.ajax', {id: trajet.id}), true),
                                    };

                                } else {
                                    items.annuler = {
                                        name: params.libelles.annuler,
                                        icon: 'fa-regular fa-circle-xmark',
                                        disabled: ajaxLocked.value,
                                    };

                                    if (!items.annuler.disabled) {
                                        const racinePartage = trajet._structure.racinePartage;
                                        let motifs = [];
                                        let motifRequis = false;

                                        if (racinePartage) {
                                            motifs = Object.values(racinePartage.motifsAnnulation);
                                            motifRequis = racinePartage.motifAnnulationRequis;
                                        }

                                        if (motifs.length) {
                                            items.annuler.items = [];
                                            if (!motifRequis) {
                                                items.annuler.items.push({
                                                    name: 'Sans motif',
                                                    callback: handleMenuLink(Router.generate('regulation.trajet.annuler.ajax', {id: trajet.id}), true, {}),
                                                });
                                                items.annuler.items.push('');
                                            }

                                            for (const motif of motifs) {
                                                items.annuler.items.push({
                                                    name: motif.libelle,
                                                    callback: motif.modeCommentaire === 0 ? handleMenuLink(Router.generate('regulation.trajet.annuler.ajax', {id: trajet.id}), true, {motif: motif.id}) : () => {
                                                        App.Utils.openVueSwal('regulation-annulation-trajet', {
                                                            trajet, motif
                                                        }, {
                                                            data: (data) => {
                                                                setTrajet(data.id, data);
                                                            }
                                                        }, '600px');
                                                    },
                                                });
                                            }
                                        }
                                        else if (!motifRequis) {
                                            items.annuler.callback = handleMenuLink(Router.generate('regulation.trajet.annuler.ajax', {id: trajet.id}), true, {});
                                        }
                                    }
                                }

                                if(!trajet.etatFacture) {
                                    items.sep2 = '';
                                    items.nePasFacturer = {
                                        name: trajet.nePasFacturer ? 'Autoriser facturation' : 'Ne pas facturer',
                                        icon: trajet.nePasFacturer ? 'fa-solid fa-check' : 'fa-solid fa-xmark',
                                        disabled: ajaxLocked.value,
                                        callback: handleMenuLink(Router.generate(trajet.nePasFacturer ? 'regulation.trajet.facturer.ajax' : 'regulation.trajet.ne-pas-facturer.ajax', {id: trajet.id}), true)
                                    };
                                }
                            }

                            // Etats
                            if(trajet.roles.ROLE_REGULATION_TRANSPORT_EDIT) {
                                items.sep3 = '';

                                items.etatPrecedent = {name: params.libelles.etatPrecedent, icon: 'fa-solid fa-backward-step', disabled: ajaxLocked.value || !trajet.canPrecedent, callback: handleMenuLink(Router.generate('regulation.trajet.etat-precedent.ajax', {id: trajet.id}), true)};
                                items.etatSuivant = {name: params.libelles.etatSuivant, icon: 'fa-solid fa-forward-step', disabled: ajaxLocked.value || !trajet.canSuivant, callback: handleMenuLink(Router.generate('regulation.trajet.etat-suivant.ajax', {id: trajet.id}), true)};
                                items.terminerMission = {name: params.libelles.terminerMission, icon: 'fa-solid fa-forward-fast', disabled: ajaxLocked.value || !trajet.canSuivant, callback: handleMenuLink(Router.generate('regulation.trajet.terminer.ajax', {id: trajet.id}), true)};
                            }

                            if(simultane.value) {
                                items.sep4 = '';
                                items.validerSimultane = {name: params.libelles.validerSimultane, icon: 'fa-solid fa-check', callback: () => saveSimultane(), disabled: simultaneLoading.value};
                                items.annulerSimultane = {name: params.libelles.annulerSimultane, icon: 'fa-solid fa-xmark', callback: () => cancelSimultane(), disabled: simultaneLoading.value};
                            }
                            else if(trajet.simultane) {
                                if(trajet.roles.ROLE_REGULATION_TRAJET_SIMULTANE_EDIT) {
                                    items.sep4 = '';
                                    items.simultane = {name: params.libelles.modifierSimultane, icon: 'fa-regular fa-square-pen', callback: () => editSimultane(trajet)};
                                }
                            } else {
                                if(trajet.roles.ROLE_REGULATION_TRAJET_SIMULTANE_NEW) {
                                    items.sep4 = '';
                                    items.simultane = {name: params.libelles.creerSimultane, icon: 'fa-solid fa-plus', callback: () => editSimultane(trajet)};
                                }
                            }

                            if(trajet.serie) {
                                if(trajet.roles.ROLE_REGULATION_SERIE_EDIT) {
                                    items.sep4 = '';
                                    items.editSerie = {name: params.libelles.modifierSerie, icon: 'fa-regular fa-calendar-pen', callback: handleMenuLink(Router.generate('shared.serie.edit', {app: 'regulation', id: trajet.serie.id}))};
                                }
                            } else if(trajet.roles.ROLE_REGULATION_SERIE_NEW) {
                                items.sep4 = '';
                                items.newSerie = {name: params.libelles.creerSerie, icon: 'fa-regular fa-calendar-plus', callback: handleMenuLink(Router.generate('shared.serie.new', {app: 'regulation', base: trajet.transport.id}))};
                            }

                            // Dupliquer / Détacher
                            if(trajet.roles.ROLE_REGULATION_TRANSPORT_NEW) {
                                items.sep4 = '';
                                items.duplicate = {name: params.libelles.dupliquer, icon:  'fa-regular fa-clone', callback: handleMenuLink(Router.generate('shared.transport.duplicate', {app: 'regulation', base: trajet.transport.id}))};
                            }

                            if(trajet.roles.ROLE_REGULATION_TRANSPORT_DETACH) {
                                items.sep4 = '';
                                items.detacherRetour = {name: params.libelles.detacherRetour, icon: 'fa-regular fa-code-fork', callback: () => {
                                        swal({
                                            title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                                            type: 'warning',
                                            text: params.libelles.detacherRetourText,
                                            showCancelButton: true,
                                            confirmButtonClass: 'bg-primary',
                                            confirmButtonText: params.libelles.detacherRetour,
                                            cancelButtonText: Translator.trans('action.annuler')
                                        }).then((result) => {
                                            if (result.value) {
                                                handleMenuLink(Router.generate('shared.transport.detach', {app: 'regulation', id: trajet.transport.id}))();
                                            }
                                        });
                                    }};
                            }

                            return {items};
                        },
                        events: {
                            hide: () => {
                                focusTrajetIndex.value = null;
                            }
                        }
                    });

                    // KEYBOARD
                    window.addEventListener('keydown', (e) => {
                        if (!$('.swal2-show').length) {
                            if(e.key === 'Escape') {
                                if (editInPlaceField.value) {
                                    editInPlaceField.value = null;
                                }
                                else if (searchMode.value) {
                                    searchMode.value = false;
                                } else {
                                    selectTrajet(null);
                                }
                            }
                            else if(e.key === ' ') {
                                if($(document.activeElement).is(':input')) {
                                    return;
                                }

                                if (currentTrajetElement.value) {
                                    currentTrajetElement.value.querySelector('.js-context-menu-btn').click();
                                }

                                e.preventDefault();
                            }
                            else if(e.key === 'Enter') {
                                if(editInPlaceField.value) {
                                    if(editInPlaceFields[editInPlaceField.value].type !== 'autocomplete') {
                                        let newIndex = editInPlaceIndex.value + 1;

                                        if(newIndex < 0 || newIndex >= Object.keys(editInPlaceFields).length) {
                                            newIndex = null;
                                        }

                                        submitEditInPlace().then(() => editInPlaceIndex.value = newIndex, () => {});
                                    }
                                }
                                else if(searchMode.value) {
                                    stepSearch(e.shiftKey ? -1 : 1);
                                } else {
                                    // if trajet sélectionné => open context menu
                                }
                            }
                            else if(['ArrowUp', 'ArrowDown'].includes(e.key)) {
                                if($(document.activeElement).is(':input')) {
                                    return;
                                }

                                if(searchMode.value) {
                                    stepSearch(e.shiftKey ? -1 : 1);
                                } else {
                                    let index = 0;

                                    if (currentTrajetIndex.value !== null) {
                                        index = currentTrajetIndex.value + ('ArrowUp' === e.key ? -1 : 1);
                                    }

                                    selectTrajet(index, true);
                                }

                                e.preventDefault();
                            }
                            else if(e.key === 'F3' || (e.key === 'f' && e.ctrlKey)) {
                                searchMode.value = !searchMode.value;
                                e.preventDefault();
                            }
                            else if(e.key === 'Tab') {
                                if(!editInPlaceLoading.value) {
                                    let newIndex = editInPlaceIndex.value === null ? (e.shiftKey ? Object.keys(editInPlaceFields).length - 1 : 0) : editInPlaceIndex.value + (e.shiftKey ? -1 : 1);

                                    if(newIndex < 0 || newIndex >= Object.keys(editInPlaceFields).length) {
                                        newIndex = null;
                                    }

                                    submitEditInPlace().then(() => editInPlaceIndex.value = newIndex, () => {});
                                }

                                e.preventDefault();
                            }
                            else if(e.key === 'F1') {
                                window.location.href = Router.generate('shared.transport.new', {app: 'regulation'});
                                e.preventDefault();
                            }
                            else if(e.key === 'F2') {
                                if(currentTrajet.value) {
                                    window.location.href = Router.generate('shared.transport.edit', {app: 'regulation', id: currentTrajet.value.transport.id});
                                }
                                e.preventDefault();
                            }
                        }
                    });

                    initLegacyJs();

                    document.dispatchEvent(new Event('app:vuejs:mounted'));
                });

                const editInPlaceFields = {
                    'equipage': {type: 'autocomplete', route: 'regulation.trajet.equipage.ajax', valueField: 'id'},
                    'vehicule': {type: 'autocomplete', route: 'regulation.trajet.vehicule.ajax', valueField: 'id'},
                    'personnel1': {type: 'autocomplete', route: 'regulation.trajet.personnel.ajax', valueField: 'id'},
                    'personnel2': {type: 'autocomplete', route: 'regulation.trajet.personnel.ajax', valueField: 'id'},
                    'topDebut': {type: 'top'},
                    'topPriseEnChargeArrivee': {type: 'top'},
                    'topDestinationArrivee': {type: 'top'},
                    'topFin': {type: 'top'}
                };

                const trajetRows = ref({});

                const trajetTab = ref();
                const messagerieTab = ref();
                const ressourcesTab = ref();

                const searchInput = ref();
                const searchMode = ref(false);
                const searchQuery = ref('');

                const searchIndex = ref(null);
                const fuseSearch = ref(null);

                const editInPlaceField = ref(null);
                const editInPlaceValue = ref(null);
                const editInPlaceError = ref(null);
                const editInPlaceLoading = ref(false);

                const focusTrajetIndex = ref(null);
                const activeTab = ref(0);
                const ressourceDraggingTrajetSource = ref(null);
                const ressourceDragging = ref(false);
                const ressourceDraggingBureaux = ref([]);
                const hasNewRessourceDispo = ref(false);
                const ressourceDropSaving = ref(null);
                const tops = ref(params.tops);


                const openTarget = computed(() => {
                    return params.modeOuvertureTransport === 1 ? '_blank' : null;
                });

                const currentTrajetRow = computed(() => {
                    if (null !== currentTrajetId.value) {
                        return trajetRows.value[currentTrajetId.value] ?? null;
                    }

                    return null;
                });
                const currentTrajetElement = computed(() => {
                    if(null !== currentTrajetRow.value) {
                        return currentTrajetRow.value.el;
                    }

                    return null;
                });
                const editInPlaceIndex = computed({
                    get() {
                        return null !== editInPlaceField.value ? Object.keys(editInPlaceFields).indexOf(editInPlaceField.value) : null;
                    },
                    set(value) {
                        editInPlaceField.value = null !== value ? Object.keys(editInPlaceFields)[value] : null;
                    },
                });

                const searchResults = computed(() => {
                    if(!searchMode.value || !fuseSearch.value || searchQuery.value === '') {
                        return [];
                    }

                    return fuseSearch.value.search(searchQuery.value);
                });

                const currentTrajetIndex = computed({
                    get() {
                        if (currentTrajetId.value === null) {
                            return null;
                        }

                        return trajetIndexById.value[currentTrajetId.value];
                    },
                    set(value) {
                        if(value === null) {
                            currentTrajetId.value = null;
                        } else {
                            currentTrajetId.value = trajetCollection.value[value].id;
                        }
                    }
                });

                const getMessageCallback = (appareilCollection) => {
                    return () => {
                        activeTab.value = 1;
                        nextTick(() => messagerieTab.value.setDestinataires(appareilCollection));
                    };
                };

                const getMessageItem = (ressources) => {
                    const appareilCollection = getRessourcesAppareilCollection(ressources);

                    return {name: 'Envoyer un message', icon: 'fa-solid fa-comments', disabled: !appareilCollection.length, callback: appareilCollection.length ? getMessageCallback(appareilCollection) : undefined};
                };

                const openEnvoiPauseSwal = (personnel1, personnel2, vehicule, equipage) => {
                    App.Utils.openVueSwal('regulation-envoi-pause', {
                        modeles: salaries.value.modeles.pause,
                        lieux: salaries.value.lieux,
                        personnel1, personnel2, vehicule, equipage
                    });
                };

                const openEnvoiFinServiceSwal = (personnel1, personnel2, vehicule, equipage) => {
                    App.Utils.openVueSwal('regulation-envoi-fin-service', {
                        personnel1, personnel2, vehicule, equipage
                    });
                };

                const openRessourceDetailSwal = (ressource) => {
                    swal({
                        showConfirmButton: false,
                        showCancelButton: false,
                        width: '800px',
                        onOpen: () => {
                            createApp({
                                template: '<regulation-ressource-detail :ressource="ressource" :current-trajet="currentTrajet" @change="change" @trajet-select="selectTrajet"></regulation-ressource-detail>',
                                data: () => ({
                                    ressource,
                                }),
                                computed: {
                                    currentTrajet() {
                                        return currentTrajet.value;
                                    },
                                },
                                methods: {
                                    change(newRessource) {
                                        this.ressource = newRessource;
                                    },
                                    selectTrajet(id) {
                                        selectTrajetById(id, true);
                                    }
                                },
                            }).mount(swal.getContent().parentElement);
                        },
                    })
                };

                const cancelPause = (pause, pauseSecondaire) => {
                    if(!pause) {
                        return;
                    }

                    swal({
                        title: App.Constants.LIBELLE_ANNULER_PAUSE_QUESTION,
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonClass: 'bg-info',
                        confirmButtonText: Translator.trans('action.confirmer'),
                        cancelButtonText: Translator.trans('action.fermer'),
                    }).then((result) => {
                        if (result.value) {
                            Request.postJson(Router.generate('regulation.supprimer-pause.ajax', {
                                pause: pause.id,
                                pauseSecondaire: pauseSecondaire ? pauseSecondaire.id : null,
                            }), {}).then((response) => {
                                if (!response.data.success) {
                                    swal({
                                        title: response.data.title,
                                        text: response.data.text,
                                        type: 'warning',
                                        confirmButtonClass: 'bg-info',
                                        confirmButtonText: Translator.trans('action.fermer'),
                                    }).then((result) => {});
                                }
                            });
                        }
                    });
                };

                const onRessourceClick = (ressource) => {
                    const ids = ressource._trajetCollection.map(trajet => trajet.id);

                    if(ids.length) {
                        if (!currentTrajetId.value || !ids.includes(currentTrajetId.value)) {
                            const id = ressource._trajetInfo.minTrajet ? ressource._trajetInfo.minTrajet.id : ids[0];

                            selectTrajetById(id, true);
                        } else {
                            const currentIndex = ids.indexOf(currentTrajetId.value);
                            const newIndex = currentIndex === ids.length - 1 ? 0 : currentIndex + 1;
                            selectTrajetById(ids[newIndex], true);
                        }
                    }
                };

                const getRessourceSection = (ressource) => {
                    return ressource._etat.section;
                };

                const refreshRessource = (ressource) => {
                    const section = getRessourceSection(ressource);

                    if(section === 'pause') {
                        let progress = 0;
                        // TODO fuck: need timeout pour update..
                        if(ressource.pauseCourante) {
                            const debut = Moment(ressource.pauseCourante.debut, 'DD/MM/YYYY HH:mm');
                            const fin = Moment(ressource.pauseCourante.fin, 'DD/MM/YYYY HH:mm');

                            const duree = fin.diff(debut, 'seconds');
                            const elapsed = Moment().diff(debut, 'seconds');
                            if(0 !== duree) {
                                progress = elapsed >= duree ? 1 : elapsed / duree;
                            }
                        }

                        ressource._progress = progress;
                    }
                };

                const getRessourceTrajetInfo = (ressource) => {
                    let minTrajet = null;
                    let count = 0;
                    let total = 0;
                    const section = getRessourceSection(ressource);

                    if(section === 'mission') {
                        let minTop = null;

                        for(const trajet of ressource._trajetCollection) {
                            if(trajet.etat !== 3) {
                                if (trajet.topCourant !== null) {
                                    if (null === minTop || minTop > trajet.topCourant) {
                                        minTop = trajet.topCourant;
                                        minTrajet = trajet;
                                        count = 1;
                                    } else if (minTop === trajet.topCourant) {
                                        count++;
                                    }

                                    total++;
                                }
                            }
                        }

                        if(null !== minTop) {
                            ressource._progress = {0: .25, 1: .5, 2: .5, 3: .75, 4: .75, 5: 1}[minTop];
                        }
                    }
                    else if(section === 'affecte') {
                        let minDate = null;

                        for(const trajet of ressource._trajetCollection) {
                            if(trajet.etat !== 3) {
                                const newMinDate = trajet._debutMissionPrevu;

                                if (newMinDate && (null === minDate || newMinDate.isBefore(minDate))) {
                                    minDate = newMinDate;
                                    minTrajet = trajet;
                                }

                                total++;
                            }
                        }
                    }
                    else if(section === 'disponible') {
                        const now = Moment();
                        let maxDate = null;

                        for(const trajet of ressource._trajetCollection) {
                            if(trajet.etat === 3) {
                                const newMaxDate= trajet._topFinReel;

                                if (newMaxDate && (null === maxDate || newMaxDate.isAfter(maxDate)) && newMaxDate.isSameOrBefore(now)) {
                                    maxDate = newMaxDate;
                                    minTrajet = trajet;
                                }

                                total++;
                            }
                        }
                    }

                    return {count, total, minTrajet};
                };

                const onEditInPlaceUpdate = (value) => {
                    editInPlaceValue.value = value;

                    if(editInPlaceField.value && editInPlaceFields[editInPlaceField.value].type === 'autocomplete') {
                        let newIndex = editInPlaceIndex.value + 1;
                        let fieldsByIndex = Object.keys(editInPlaceFields);

                        if(editInPlaceField.value === 'equipage' || null === value || !(newIndex in fieldsByIndex)  || editInPlaceFields[fieldsByIndex[newIndex]].type !== 'autocomplete') {
                            newIndex = null;
                        }

                        submitEditInPlace().then(() => editInPlaceIndex.value = newIndex, () => {});
                    }
                };

                const initLegacyJs = () => {
                    let $body = $('body');

                    // New transport btn
                    let $newTransportLink = $('#newTransportLink');
                    $newTransportLink.find('a').addClass('js-transport-new');
                    const target = $newTransportLink.find('a').attr('target');

                    let updateNewTransportDropdown = () => {
                        let $dropdown = $('<ul class="hover-menu"></ul>');
                        let $items = $('#trajet_filter_structureCollection input[data-action="input"]:checked');
                        $newTransportLink.find('.hover-menu').remove();

                        if(!$items.length) {
                            $newTransportLink.removeClass('hover-menu-wrapper');
                            return;
                        }

                        $newTransportLink.addClass('hover-menu-wrapper');

                        $items.each((index, element) => {
                            let label = $(element).parent().siblings('label').text();
                            let url = Router.generate('shared.transport.new', {app: 'regulation', structure: $(element).val()});

                            $dropdown.append('<li><a' + (target ? ' target="' + target + '"' : '') + ' href="' + url + '" class="js-transport-new">' + label + '</a></li>');
                        });

                        $newTransportLink.append($dropdown);

                        updateRendezVous();
                    };

                    $body.on('change', '#trajet_filter_structureCollection input', updateNewTransportDropdown);

                    updateNewTransportDropdown();
                };

                const submitEditInPlace = () => {
                    return new Promise((resolve, reject) => {
                        if(!editInPlaceField.value || !currentTrajet.value) {
                            return resolve();
                        }

                        let field = editInPlaceFields[editInPlaceField.value];
                        let oldValue = currentTrajet.value[editInPlaceField.value];
                        let newValue = editInPlaceValue.value;

                        if(field.valueField) {
                            oldValue = oldValue ? oldValue[field.valueField] : null;
                            newValue = newValue ? newValue[field.valueField] : null;
                        }

                        if(oldValue === newValue) {
                            return resolve();
                        }

                        editInPlaceLoading.value = true;

                        updateTrajet(currentTrajet.value, editInPlaceField.value, newValue).then(() => {
                            editInPlaceError.value = null;
                            resolve();
                        }, (data) => {
                            editInPlaceError.value = data;
                            editInPlaceLoading.value = false;
                            reject();
                        });
                    });
                };

                const onRessourceDispo = (ressource) => {
                    if(activeTab.value !== 2) {
                        hasNewRessourceDispo.value = true;
                    }
                };

                const onMapSelectVehicule = (event) => {
                    ressourceDropSaving.value = {trajet: event.trajet, field: 'vehicule'};
                    updateTrajet(event.trajet, 'vehicule', event.id).finally(() => {
                        ressourceDropSaving.value = null;
                    });
                };

                const onRessourceDrop = (trajet, field, ressource) => {
                    if(canDropRessource(trajet, field, ressource)) {
                        selectTrajetById(trajet.id);
                        ressourceDropSaving.value = {trajet, field};

                        updateTrajet(trajet, field, ressource.id).finally(() => {
                            ressourceDropSaving.value = null;
                        });
                    }
                };

                const onRessourceDragStart = (ressource, trajetSource = null) => {
                    ressourceDragging.value = true;
                    ressourceDraggingTrajetSource.value = trajetSource;
                    ressourceDraggingBureaux.value = ressource.bureauCollection || [ressource.structure];
                };

                const onRessourceDragEnd = (ressource) => {
                    ressourceDragging.value = false;
                };

                const onRessourceDragLeave = (ressource, valid, event) => {
                    event.target.classList.remove('bg-warning');
                };

                const onRessourceDragEnter = (trajet, field, ressource, valid, event) => {
                    if (canDropRessource(trajet, field, ressource)) {
                        event.target.classList.add('bg-warning');
                    }
                };

                const canDropRessource = (trajet, field, ressource) => {
                    if (ressourceDraggingTrajetSource.value && ressourceDraggingTrajetSource.value === trajet) {
                        return false;
                    }

                    if (ressource) {
                        if (field && !field.startsWith(ressource._type)) {
                            return false;
                        }

                        const bureaux = ressource.bureauCollection || [ressource.structure];
                        return bureaux.includes(trajet.structure.id);
                    }

                    return ressourceDraggingBureaux.value.includes(trajet.structure.id);
                };

                const selectSearchResult = () => {
                    if(searchIndex.value in searchResults.value) {
                        nextTick(() => {
                            selectTrajetById(searchResults.value[searchIndex.value].item.id, true, false);
                        });
                    }
                };

                const stepSearch = (direction) => {
                    let newIndex = searchIndex.value + direction;
                    let total = searchResults.value.length;

                    if(total === 0 || newIndex >= total) {
                        searchIndex.value = 0;
                    }
                    else if(newIndex < 0) {
                        searchIndex.value = total - 1;
                    }
                    else {
                        searchIndex.value = newIndex;
                    }
                };

                watch(activeTab, () => {
                    if (activeTab.value === 2) {
                        hasNewRessourceDispo.value = false;
                    }
                    else if(activeTab.value === 1) {
                        hasUnreadMessage.value = false;
                        nextTick(() => {
                            messagerieTab.value.refresh();
                            messagerieTab.value.scrollBottom();
                        });
                    }
                });

                const selectTrajet = (index, scroll = false, smooth = true) => {
                    if (null === index || index in trajetCollection.value) {
                        currentTrajetIndex.value = index;

                        if (null !== index && scroll && currentTrajetElement.value) {
                            currentTrajetElement.value.scrollIntoView({
                                behavior: smooth ? 'smooth' : 'instant',
                                block: 'center',
                            });
                        }
                    }
                };

                const selectTrajetById = (id, scroll = false, smooth = true) => {
                    selectTrajet(trajetIndexById.value[id], scroll, smooth);
                };

                watch (hasUnreadMessage, () => {
                    if(activeTab.value === 1) {
                        hasUnreadMessage.value = false;
                    }
                });

                watch(currentTrajet, () => {
                    if (currentTrajet.value && editInPlaceField.value) {
                        const value = currentTrajet.value[editInPlaceField.value];
                        const type = editInPlaceFields[editInPlaceField.value].type;

                        let newValue = value;

                        if (null !== value) {
                            if (type === 'top') {
                                newValue = value.time;
                            }
                            else if (type === 'autocomplete') {
                                newValue = currentTrajet.value['_'+editInPlaceField.value];
                            }
                        }
                        editInPlaceValue.value = newValue;                    }
                });

                watch(editInPlaceField, () => {
                    editInPlaceError.value = null;
                    editInPlaceLoading.value = false;

                    if(editInPlaceField.value && currentTrajet.value) {
                        const value = currentTrajet.value[editInPlaceField.value];
                        const type = editInPlaceFields[editInPlaceField.value].type;

                        let newValue = value;

                        if (null !== value) {
                            if (type === 'top') {
                                newValue = value.time;
                            }
                            else if (type === 'autocomplete') {
                                newValue = currentTrajet.value['_'+editInPlaceField.value];
                            }
                        }
                        editInPlaceValue.value = newValue;
                    } else {
                        editInPlaceValue.value = null;
                    }
                });

                watch(trajetCollection, () => {
                    fuseSearch.value = new Fuse(trajetCollection.value, {
                        includeMatches: true,
                        keys: ['rendezVous.time', 'transport.patient.text', 'adressePriseEnCharge', 'villePriseEnCharge', 'adresseDestination', 'villeDestination', 'transport.motif.abreviation', 'transport.note', 'transport.patient.assure.note', 'transport.patient.assure.notePublique', 'transport.patient.beneficiaire.note', 'transport.patient.beneficiaire.notePublique'],
                        minMatchCharLength: 1,
                        tokenize: true,
                        threshold: 0,
                    });
                });

                watch(searchMode, () => {
                    if (searchMode.value) {
                        searchInput.value.focus();
                    } else {
                        searchQuery.value = '';
                        searchInput.value.blur();
                    }
                });

                const updateRendezVous = () => {
                    $('.js-transport-new').each((i,el) => {
                        let newUrl = $(el).attr('href').replace(/\?.*/,'');

                        if(rendezVousInput.value !== Moment().format('DD/MM/YYYY')) {
                            newUrl += '?date='+ encodeURIComponent(rendezVousInput.value);
                        }

                        $(el).attr('href', newUrl);
                    });

                    document.title = rendezVousInput.value + ' - '+ document.title.substring(document.title.indexOf('Trajets - '));
                }
                watch(rendezVous, updateRendezVous);

                watch(currentTrajetId, () => {
                    editInPlaceField.value = null;
                });

                watch(searchResults, () => {
                    if(!(searchIndex.value in searchResults.value)) {
                        searchIndex.value = searchResults.value.length ? 0 : null;
                    }
                    selectSearchResult();
                });

                watch(searchIndex, () => {
                    selectSearchResult();
                });

                provide('openRessourceDetailSwal', openRessourceDetailSwal);
                provide('openEnvoiPauseSwal', openEnvoiPauseSwal);
                provide('openEnvoiFinServiceSwal', openEnvoiFinServiceSwal);
                provide('canDropRessource', canDropRessource);
                provide('onRessourceDrop', onRessourceDrop);
                provide('onRessourceDragStart', onRessourceDragStart);
                provide('onRessourceDragEnd', onRessourceDragEnd);
                provide('onRessourceDragEnter', onRessourceDragEnter);
                provide('onRessourceDragLeave', onRessourceDragLeave);
                provide('editInPlaceField', editInPlaceField);
                provide('editInPlaceFields', editInPlaceFields);
                provide('getMessageItem', getMessageItem);
                provide('selectTrajetById', selectTrajetById);

                return {
                    Euro,
                    trajetRows,
                    trajetTab,
                    messagerieTab,
                    ressourcesTab,
                    searchInput,
                    editInPlaceFields,
                    searchMode,
                    showAdvancedFilters,
                    searchQuery,
                    searchIndex,
                    fuseSearch,
                    filter,
                    currentTrajetId,
                    editInPlaceField,
                    editInPlaceValue,
                    editInPlaceError,
                    editInPlaceLoading,
                    focusTrajetIndex,
                    allTrajetCollection,
                    structureCollection,
                    rendezVous,
                    isLoading,
                    simultane,
                    simultaneLoading,
                    simultaneTrajetIds,
                    personnels,
                    vehicules,
                    equipages,
                    activeTab,
                    messagerie,
                    salaries,
                    ressourceDraggingTrajetSource,
                    ressourceDragging,
                    ressourceDraggingBureaux,
                    hasUnreadMessage,
                    hasNewRessourceDispo,
                    ressourceDropSaving,
                    tops,
                    sort,
                    ajaxLocked,
                    trajetCollectionPretEnvoiTla,
                    openTarget,
                    patientIds,
                    chartOptions,
                    chartData,
                    estimationMontantTouteTaxeFiltre,
                    estimationMontantTouteTaxeTotal,
                    personnelsById,
                    vehiculesById,
                    equipagesById,
                    rendezVousInput,
                    trajetIndexById,
                    trajetCollection,
                    filterEtatChoices,
                    categoriesColor,
                    trajetsCountByCategorieChartData,
                    trajetsByCategorie,
                    trajetsCountByCategorie,
                    trajetsEstimationByCategorieChartConfig,
                    trajetsEstimationByCategorieChartData,
                    filterCategorieChoices,
                    currentTrajet,
                    currentTrajetRow,
                    currentTrajetElement,
                    editInPlaceIndex,
                    searchResults,
                    explodeRessource,
                    envoyerTla,
                    getMessageCallback,
                    canSendFinService,
                    canSendPause,
                    openEnvoiPauseSwal,
                    openEnvoiFinServiceSwal,
                    openRessourceDetailSwal,
                    cancelPause,
                    onMessagerieSend,
                    onRessourceClick,
                    getRessourceSection,
                    refreshRessource,
                    getRessourceTrajetInfo,
                    editSimultane,
                    toggleSimultane,
                    cancelSimultane,
                    saveSimultane,
                    onEditInPlaceUpdate,
                    submitEditInPlace,
                    onRessourceDispo,
                    updateTrajet,
                    onMapSelectVehicule,
                    onRessourceDrop,
                    onRessourceDragStart,
                    onRessourceDragEnd,
                    onRessourceDragLeave,
                    onRessourceDragEnter,
                    canDropRessource,
                    selectSearchResult,
                    stepSearch,
                    print,
                    selectTrajetById,
                    selectTrajet,
                    step,
                    refresh,
                };
            },
        }).use(VueChartkick).mount(el);
    }
};
