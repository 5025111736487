import {ref} from "vue";

export function useWebSocket() {
    const webSocketOnline = ref(null);

    if (App.webSocket.isInit()) {
        webSocketOnline.value = App.webSocket.isOnline();
    }

    document.addEventListener('websocket:status', (e) => {
        webSocketOnline.value = e.detail.online;
    });

    return {webSocketOnline}
}