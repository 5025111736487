<template>
    <div>
        <div class="search-form row mb-3">
            <div class="order-1 col-8 col-sm-12 col-lg-5 mb-2 mb-lg-0">
                <input type="text" v-model="filtre.recherche" placeholder="Recherche..." class="form-control">
            </div>
            <div class="order-2 col-4 col-sm-4 col-lg-2 order-sm-4">
                <button @click="resetFiltre" type="button" class="btn btn-secondary btn-block"><i class="fa-regular fa-arrows-rotate"></i></button>
            </div>
            <div class="order-3 col-12 col-sm-8 col-lg-5">
                <btn-group-filter :choices="filterStatusChoices" :values="filtre.status" @values="filtre.status = $event"></btn-group-filter>
            </div>
        </div>
        <b-table class="mb-0" no-border-collapse sticky-header="30vh" head-variant="light" :fields="suiviFields" :items="suivisFiltres" sort-by="envoi, acquittement" :busy="loading" show-empty>
            <template #cell(destinataire)="{item}">
                <strong :title="item.personnelDestinataire.text + ' (Personnel)'" v-if="item.personnelDestinataire">{{ item.personnelDestinataire.text }}</strong>
                <strong :title="item.vehiculeDestinataire.text + ' (Véhicule)'" v-else-if="item.vehiculeDestinataire">{{ item.vehiculeDestinataire.text }}</strong>
                <strong :title="item.appareilDestinataire.text + ' (Appareil)'" v-else-if="item.appareilDestinataire">{{ item.appareilDestinataire.text }}</strong>
                <strong v-else>Donnée absente</strong>
            </template>
            <template #cell(envoi)="{item}">
                <span v-if="item.erreurAcheminementDelaiExpire && !item.topTlaEnvoi" class="text-danger"><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;{{ Translator.trans('libelle.delai-expire') }}</span>
                <span v-else-if="item.topTlaEnvoi" class="text-success"><i class="fa-regular fa-paper-plane"></i>&nbsp;{{ Translator.trans('libelle.envoye-le') }}&nbsp;{{ item.topTlaEnvoi }}</span>
                <span v-else class="text-muted"><i class="fa-solid fa-spinner fa-pulse"></i>&nbsp;{{ Translator.trans('libelle.en-attente') }}...</span>
            </template>
            <template #cell(acquittement)="{item}">
                <span v-if="item.erreurAcheminementDelaiExpire && !item.topTlaEnvoi" class="text-danger"><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;Non envoyé.</span>
                <span v-else-if="item.topTlaEnvoi && item.erreurAcheminementDelaiExpire" class="text-danger"><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;Délai expiré.</span>
                <span v-else-if="item.topTlaAcquittement" class="text-success"><i class="fa-solid fa-check"></i>&nbsp;{{ Translator.trans('libelle.recu-le') }}&nbsp;{{ item.topTlaAcquittement }}</span>
                <span v-else-if="item.topTlaEnvoi" class="text-muted"><i class="fa-solid fa-spinner fa-pulse"></i>&nbsp;{{ Translator.trans('libelle.en-attente') }}...</span>
            </template>
            <template #cell(lu)="{item}">
                <span v-if="item.erreurAcheminementDelaiExpire && !item.topTlaEnvoi" class="text-danger"><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;Non envoyé.</span>
                <span v-else-if="item.topTlaEnvoi && item.topTlaLuDelaiExpire" class="text-danger"><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;Délai expiré.</span>
                <span v-else-if="item.topTlaLu" class="text-success"><i class="fa-solid fa-check-double"></i>&nbsp;{{ Translator.trans('libelle.lu-le') }}&nbsp;{{ item.topTlaLu }}</span>
                <span v-else-if="item.topTlaAcquittement" class="text-muted"><i class="fa-solid fa-spinner fa-pulse"></i>&nbsp;{{ Translator.trans('libelle.en-attente') }}...</span>
            </template>
            <template #empty>
                <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
            </template>
            <template #table-busy>
                <div class="p-3 text-center">
                    <b-spinner class="mr-2"></b-spinner> Chargement…
                </div>
            </template>
        </b-table>
    </div>
</template>

<script setup>
import Moment from "moment/moment";
import {computed, ref, toRefs} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import Translator from "@/App/Translator";

const props = defineProps({
    message: Object
});

const {message} = toRefs(props);

const suiviFields = [
    {
        key: 'destinataire',
        sortable: true,
        sortByFormatted: true,
        label: 'Destinataire',
        formatter: (value, key, item) => {
            return (item.personnelDestinataire?.text ?? item.vehiculeDestinataire?.text ?? item.appareilDestinataire?.text ?? '').toLowerCase()
        }
    },
    {
        key: 'envoi',
        sortable: true,
        sortByFormatted: true,
        label: 'Envoi',
        formatter: (value, key, item) => {
            if (item.topTlaEnvoi) {
                const datetime = Moment(item.topTlaEnvoi, 'DD/MM/YYYY HH:mm');

                if (datetime.isValid()) {
                    return '0' + datetime.format('YYYY-MM-DD-HH-mm')
                }

                return '0';
            }

            if (item.erreurAcheminementDelaiExpire) {
                return '1';
            }

            return '2';
        }
    },
    {
        key: 'acquittement',
        sortable: true,
        sortByFormatted: true,
        label: 'Acquittement',
        formatter: (value, key, item) => {
            if (item.topTlaAcquittement) {
                const datetime = Moment(item.topTlaAcquittement, 'DD/MM/YYYY HH:mm');

                if (datetime.isValid()) {
                    return '0' + datetime.format('YYYY-MM-DD-HH-mm')
                }

                return '0';
            }

            if (item.erreurAcheminementDelaiExpire) {
                return '1';
            }

            return '2';
        }
    },
    {
        key: 'lu',
        sortable: true,
        sortByFormatted: true,
        label: 'Lu',
        formatter: (value, key, item) => {
            if (item.topTlaLu) {
                const datetime = Moment(item.topTlaLu, 'DD/MM/YYYY HH:mm');

                if (datetime.isValid()) {
                    return '0' + datetime.format('YYYY-MM-DD-HH-mm')
                }

                return '0';
            }

            if (item.erreurAcheminementDelaiExpire) {
                return '1';
            }

            return '2';
        }
    },
];

const filterStatusChoices = ref({
    nonEnvoye: 'Non envoyé',
    nonAcquitte: 'Non acquitté',
});

const filtre = ref({});
const loading = ref(false);

let suiviCollection;

if (message.value.suiviCollection) {
    suiviCollection = computed(() => message.value.suiviCollection);
} else {
    suiviCollection = ref([]);

    loading.value = true;
    Request.getJson(Router.generate('regulation.trajet.message.suvi', {id: message.value.id})).then(data => {
        suiviCollection.value = data.suiviCollection;
        loading.value = false;
    });
}

const suivisFiltres = computed(() => {
    return suiviCollection.value.filter((suivi) => {
        let destStr = ((suivi.appareilDestinataire?.text ?? '') + (suivi.personnelDestinataire?.text ?? '') + (suivi.vehiculeDestinataire?.text ?? '')).toLowerCase() ?? '';
        return (suivi.topTlaEnvoi || filtre.value.status.includes('nonEnvoye')) && (suivi.topTlaAcquittement || filtre.value.status.includes('nonAcquitte')) && (filtre.value.recherche === '' || filtre.value.recherche === undefined || destStr.includes(filtre.value.recherche.toLowerCase()));
    }) ?? [];
});

const resetFiltre = () => {
    filtre.value = {
        status: ['nonEnvoye', 'nonAcquitte'],
        recherche: undefined,
    }
};

resetFiltre();

</script>
