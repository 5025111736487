<template>
    <div class="position-relative tailwind" @wheel.stop v-if="paths.length" style="height: 100%;">
        <Map :config="{
            provider: 'tomtom',
            map: '2/basic_street-light',
            poi: '2/poi_light',
            trafficIncidents: '2/incidents_light',
            trafficFlow: '',
        }" :bounds="bounds">
            <Path v-for="features in allFeatures" color="#3b82f6" :features="[features]"></Path>
        </Map>
    </div>
</template>

<script setup>
import Map from "@/Vue/Components/RegulationV2/Map/Base/Map.vue";
import Path from '@/Vue/Components/RegulationV2/Map/Base/Path';
import {toRefs, computed} from "vue";
import bbox from "@turf/bbox";

const props = defineProps(['paths']);
const {paths} = toRefs(props);

const allFeatures = computed(() => paths.value.map(path => ({type: 'Feature', geometry: {
    type: 'LineString',
    coordinates: path,
}})));
const bounds = computed(() => bbox({
    type: 'FeatureCollection',
    features: allFeatures.value.flat(),
}));
</script>
