<template>
    <li class="px-2" :class="{odd: message.estEmetteurRegulation, systeme: message.estEmetteurSysteme, 'opacity-50': message.estLieAmplitudeFinService && null === message.topTlaQuantite}" :data-index="index">
        <div class="chat-body" v-element-hover="onHover">
            <div class="chat-text js-messagerie-message cursor-pointer" v-b-modal="'details-modal-' + message.id" :class="{'border border-info': focus}">
                <div class="d-flex align-items-center justify-content-between">
                    <h4 class="text-ellipsis">
                        <template v-if="message.estEmetteurRegulation || message.estEmetteurSysteme">
                            {{ message.createdBy ? message.createdBy.longText : 'Régulation' }}
                        </template>
                        <template v-else-if="message.personnelEmetteur">
                            <span title="Personnel">{{ message.personnelEmetteur.longText }}</span>
                        </template>
                        <template v-else-if="message.vehiculeEmetteur">
                            <span title="Véhicule">{{ message.vehiculeEmetteur.longText }}</span>
                        </template>
                        <template v-else-if="message.appareilEmetteur">
                            <span title="Appareil">{{ message.appareilEmetteur.text }}</span>
                        </template>
                    </h4>
                    <div class="line-height-1">
                        <i class="fa-solid fa-square-check text-success" v-if="message.traiteBy && message.traiteAt" :title="'Traité par ' + message.traiteBy.longText + ' le '+ message.traiteAt"></i>
                    </div>
                </div>
                <p class="chat-message">{{ message.message }}</p>
                <div class="chat-info mt-1">
                    <div class="text-nowrap">
                        <MessageStatus :message="message"></MessageStatus>
                    </div>
                    <div class="ml-3 text-ellipsis" v-if="message.estEmetteurRegulation || message.estEmetteurSysteme">
                        <MessageDestinataire :message="message"></MessageDestinataire>
                    </div>
                </div>
            </div>
        </div>

        <b-modal :id="'details-modal-' + message.id" size="lg" :title="Translator.trans('libelle.detail-message')" centered hide-footer body-class="white-box m-0" @shown="onDetailShown">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h3 class="box-title mb-0">{{ Translator.trans('libelle.emetteur') }}</h3>
                    <template v-if="!message.estEmetteurRegulation && !message.estEmetteurSysteme">
                        <div class="row py-2" v-if="message.personnelEmetteur">
                            <div class="col-6 font-bold">{{ Translator.trans('libelle.personnel-emetteur') }}</div>
                            <div class="col-6">
                                <fallback-label target="_blank" :role="message._roles?.ROLE_REFERENTIEL_PERSONNEL_VIEW" :url="Router.generate('referentiel.personnel.view', {id: message.personnelEmetteur?.id})">
                                    {{ message.personnelEmetteur?.text }}
                                </fallback-label>
                            </div>
                        </div>
                        <div class="row py-2" v-if="message.vehiculeEmetteur">
                            <div class="col-6 font-bold">{{ Translator.trans('libelle.vehicule-emetteur') }}</div>
                            <div class="col-6">
                                <fallback-label target="_blank" :role="message._roles?.ROLE_REFERENTIEL_VEHICULE_VIEW" :url="Router.generate('referentiel.vehicule.view', {id: message.vehiculeEmetteur?.id})">
                                    {{ message.vehiculeEmetteur?.text }}
                                </fallback-label>
                            </div>
                        </div>
                        <div class="row py-2" v-if="message.appareilEmetteur">
                            <div class="col-6 font-bold">{{ Translator.trans('libelle.appareil-emetteur') }}</div>
                            <div class="col-6">
                                <fallback-label target="_blank" :role="message._roles?.ROLE_REFERENTIEL_APPAREIL_VIEW" :url="Router.generate('referentiel.appareil.view', {id: message.appareilEmetteur?.id})">
                                    {{ message.appareilEmetteur?.text }}
                                </fallback-label>
                            </div>
                        </div>
                    </template>
                    <div v-if="message.estEmetteurRegulation || message.estEmetteurSysteme" class="row py-2">
                        <div class="col-6 font-bold">{{ Translator.trans('libelle.source') }}</div>
                        <div class="col-6">{{ Translator.trans(message.estEmetteurRegulation ? 'application.apps.regulation' : 'libelle.systeme') }}</div>
                    </div>
                    <div class="row py-2" v-if="message.createdBy">
                        <div class="col-6 font-bold">{{ Translator.trans('libelle.cree-par') }}</div>
                        <div class="col-6">
                            {{ message.createdBy.text }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <h3 class="box-title mb-0">{{ Translator.trans('libelle.horodatage') }}</h3>
                    <div class="row py-2">
                        <div class="col-6 font-bold">{{ Translator.trans('libelle.envoye') }}</div>
                        <div class="col-6">{{ message.horodatage.date }} à {{ message.horodatage.time }}</div>
                    </div>
                </div>
            </div>
            <div class="mt-3" v-if="message.estEmetteurRegulation || message.estEmetteurSysteme">
                <h3 class="box-title mb-0">{{ Translator.trans('libelle.destinataires') }}</h3>
                <messagerie-suivi :message="message"></messagerie-suivi>
            </div>
        </b-modal>
    </li>
</template>

<script setup>
import Translator from "@/App/Translator";
import MessageStatus from "@/Vue/Components/MessageStatus";
import MessageDestinataire from "@/Vue/Components/MessageDestinataire";
import Router from "@/App/Router";

import { vElementHover } from '@vueuse/components'

const emit = defineEmits(['hover']);

const props = defineProps({
    message: Object,
    index: Number,
    focus: Boolean,
});
const onHover = (hover) => {
    emit('hover', hover);
};
const onDetailShown = (event) => {
    $(event.target).parent().css('z-index', 1060);
};
</script>
