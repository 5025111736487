<template>
    <MenuDropdown v-model="open">
        <template #button>
            <div @click="toggle" @contextmenu.prevent="togglePause()" class="menu-dropdown-btn-item d-flex align-items-center cti-poste" id="ctiPoste">
                <i class="fa fa-2x fa-phone-office" :class="'text-'+statusVariant"></i>
                <div class="pl-2 flex-1 width-50px text-left">
                    <div class="text-normal">
                        <template v-if="'UNAVAILABLE' !== status && device">
                            {{ device }}
                        </template>
                        <template v-else-if="'UNAVAILABLE' === status && deviceInput">
                            {{ deviceInput }}
                        </template>
                        <template v-else>
                            <i class="fa-solid fa-fw fa-exclamation-triangle text-warning mr-1"></i>Poste
                        </template>
                    </div>

                    <div v-if="'UNAVAILABLE' !== status && groupItem" class="small text-muted text-ellipsis" :title="groupItem.name">
                        {{ groupItem.name }}
                    </div>
                    <div v-else-if="'UNAVAILABLE' === status && groupInputItem" class="small text-muted text-ellipsis" :title="groupInputItem.name">
                        {{ groupInputItem.name }}
                    </div>
                    <div v-else-if="ready" class="text-warning small">
                        <i class="fa-solid fa-fw fa-exclamation-triangle mr-1" title="Pas de groupe actif"></i>
                    </div>
                    <div v-else class="small">
                        <i class="fa-solid fa-spinner fa-spin fa-fw"></i>
                    </div>
                </div>
            </div>
        </template>
        <template #dropdown>
            <div class="menu-dropdown-content-title">
                <div class="width-100px">
                    <form-select2
                        class="input-sm w-100"
                        v-model="deviceInput"
                        :disabled="!webSocketOnline || status === 'BUSY'"
                        :choices="deviceChoices"
                        @change="saveAgentConf"
                        placeholder="Poste"
                        title="Poste"
                        :required="true"
                    ></form-select2>
                </div>
                <div class="mx-2 flex-1">
                    <form-select2
                        class="input-sm w-100"
                        v-model="groupInput"
                        :disabled="!webSocketOnline || status === 'BUSY'"
                        :choices="groups.reduce((res, item) => (res[item.extension] = item.name, res), {})"
                        @change="saveAgentConf"
                        placeholder="Groupe"
                        title="Groupe"
                        :required="true"
                    ></form-select2>
                </div>
                <div class="btn-group">
                    <button
                        v-for="(status2, key) in statuses"
                        class="btn btn-outline-light btn-sm"
                        :class="{'active': status === key}"
                        :disabled="!isReady || statusLoading || status === 'BUSY'"
                        :title="status2.label"
                        @click="setStatus(key)"
                    >
                        <i class="fa-lg" :class="[status === key ? 'text-'+status2.variant : 'text-muted', status2.icon]"></i>
                    </button>
                </div>
            </div>
            <div class="px-3 py-2">
                <div class="input-group">
                    <input type="text" class="form-control input-sm" placeholder="Rechercher ou composer…" v-model="searchQuery" @keyup.enter="call(searchQuery)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="call(searchQuery)">
                            <i class="fa-solid fa-phone"></i>
                        </button>
                    </div>
                </div>
                <div class="position-relative" v-if="searchQuery">
                    <div class="cti-search-results">
                        <div v-for="(result, resultIndex) in searchResults" class="cti-search-results-item" :class="{'cti-search-results-item-focus': resultIndex === searchIndex}" style="margin: 4px;border-radius: 4px;">
                            <div class="d-flex p-2 px-3">
                                <div class="text-normal text-dark">{{ result.displayText }}</div>
                                <div class="ml-auto">
                                    <i :class="contactIcon(result.contactType)"></i>
                                </div>
                            </div>
                            <div class="d-flex px-3 cti-search-results-item-numbers">
                                <div class="px-2 cti-search-results-item-number" v-for="(number, numberIndex) in result.numbers" @click="searchNumberIndex = numberIndex" :class="{'cti-search-results-item-number-focus': numberIndex === searchNumberIndex}">
                                    <i class="fa-solid " :class="number.type === 'mobile' ? 'fa-mobile' : 'fa-phone'"></i> {{ number.number }}
                                </div>
                            </div>
                        </div>
                        <div v-if="!searchResults.length" class="text-muted px-3 py-2">
                            Aucun résultat
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-top">
                <b-tabs fill content-class="my-3" nav-class="customtab menu-dropdown-content-tabs">
                    <b-tab title="Historique" active>
                        <div v-if="appelsLoading" class="d-flex align-items-center justify-content-center p-5">
                            <b-spinner class="mr-2"></b-spinner> Chargement…
                        </div>
                        <div v-else>
                            <div v-for="(appel, index) in appels">
                                <div class="chat-separator" v-if="index === 0 || appel.sonnerie.date !== appels[index-1].sonnerie.date"><span :title="appel.sonnerie.date">{{ formatDateSeparator(appel.sonnerie.date) }}</span></div>
                                <div class="d-flex border border-light m-3 overflow-hidden align-items-center" style="border-radius: 8px">
                                    <div class="py-2 bg-light text-normal width-55px text-center">{{ appel.sonnerie.time }}</div>
                                    <div class="mx-3">
                                        <i class="fa-solid" :class="[appel.debut ? (appel.direction === 'IN' ? 'text-success' : 'text-info') : 'text-danger', appel.direction === 'IN' ? 'fa-phone-arrow-down-left' : 'fa-phone-arrow-up-right']"></i>
                                    </div>
                                    <div class="flex-1">
                                        <a :href="'tel:'+appel.numero">{{ appel.numero }}</a>
                                    </div>
                                    <div class="font-size-12px">
                                        <span class="text-success" v-if="appel.dureeAppel !== null">
                                            <i class="fa-solid fa-waveform-lines"></i>
                                            {{ formatDuree(appel.dureeAppel) }}
                                        </span>
                                        <span class="text-dark" v-else>
                                            <i class="fa-solid fa-phone-volume"></i>
                                            {{ formatDuree(appel.dureeSonnerie) }}
                                        </span>
                                    </div>
                                    <div class="ml-2 mr-3 d-flex">
                                        <template v-if="appel.enregistrement">
                                            <i class="fa-solid fa-circle-stop text-warning font-size-18px cursor-pointer" @click.prevent="stopRecord" v-if="recordAppel === appel"></i>
                                            <i class="fa-solid fa-circle-play text-success font-size-18px cursor-pointer" @click.p.prevent="playRecord(appel)" v-else></i>
                                        </template>
                                        <span v-else>
                                            <i class="fa-solid fa-circle-play text-light font-size-18px"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
<!--                    <b-tab title="Postes">-->
<!--                        <div class="p-5">-->
<!--                            Bientôt !-->
<!--                        </div>-->
<!--                    </b-tab>-->
                </b-tabs>
            </div>
        </template>
    </MenuDropdown>
</template>

<script setup>
import {computed, ref, toRefs, watch} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import Moment from "moment/moment";
import Duree from "@/Vue/Filters/Duree";
import {useRequest} from "@/Vue/Composables/Request";
import {useWebSocket} from "@/Vue/Composables/WebSocket";
import MenuDropdown from "@/Vue/Components/MenuDropdown";

const emit = defineEmits(['dialing']);
const props = defineProps({
    device: {
        type: String,
        required: false,
    },
    group: {
        type: String,
        required: false,
    },
    groups: {
        type: Array,
        required: true,
    },
    phones: {
        type: Array,
        required: true,
    },
    status: {
        type: String,
        required: true,
    },
    ready: {
        type: Boolean,
        required: true,
    },
});

const {webSocketOnline} = useWebSocket();

const {device, group, groups, phones, status, ready} = toRefs(props);

const appels = ref([]);
const deviceInput = ref(device.value);
const groupInput = ref(group.value);
const open = ref(false);
const searchErrors = ref({});
const searchIndex = ref(0);
const searchLoading = ref(false);
const searchNumberIndex = ref('telephone1');
const searchQuery = ref('');
const searchResults = ref([]);
const matchType = ref(null);
const statusLoading = ref(false);
const appelsLoading = ref(true);
const recordAppel = ref(null);
const recordAudio = ref(new Audio());
recordAudio.value.onended = () => {
    recordAppel.value = null;
};
const stopRecord = () => {
    recordAppel.value = null;
    recordAudio.value.pause();
}
const playRecord = (appel) => {
    recordAudio.value.setAttribute('src', Router.generate('cti.record', {id: appel.id}));
    recordAudio.value.play();
    recordAppel.value = appel;
};
const toggle = () => open.value = !open.value;
const {getJson} = useRequest();

const groupItem = computed(() => group.value ? groups.value.find(g => g.extension === group.value) : null);
const groupInputItem = computed(() => groupInput.value ? groups.value.find(g => g.extension === groupInput.value) : null);

watch(open, () => {
    if (open.value) {
        getJson(Router.generate('cti.appels')).then((data) => {
            appelsLoading.value = false;
            appels.value = data.appels;
        });
    } else {
        stopRecord();
    }
});

watch(status, () => {
    statusLoading.value = false;
});

watch(device, () => {
    deviceInput.value = device.value;
});

watch(group, () => {
    groupInput.value = group.value;
});

watch(searchQuery, () => {
    searchIndex.value = 0;
    searchNumberIndex.value = 0;

    if (searchQuery.value.length > 1) {
        // todo debounce / cancel requests
        Request.getJson(Router.generate('cti.contact', {limit: 5, displayName: searchQuery.value})).then((data) => {
            searchResults.value = data;
        });
    } else {
        searchResults.value = [];
    }
})

const formatDuree = (duree) => Duree(duree, 'hhmm');

const statusVariant = computed(() => {
    switch (status.value) {
        case 'BUSY':
            return 'danger';
        case 'PAUSE':
            return 'info';
        case 'WRAPUP':
            return 'primary';
        case 'READY':
            return 'success'
    }

    return 'secondary';
});

const setStatus = (newStatus) => {
    if (!isReady.value || 'BUSY' === status.value) {
        return;
    }
    if (newStatus !== status.value) {
        statusLoading.value = true;
        switch (newStatus) {
            case 'READY':
                App.webSocket.publish('cti', {action: 'agentReady', device: deviceInput.value, group: groupInput.value});
                localStorage.removeItem('ctiManuallyLoggedOff');
                break;
            case 'PAUSE':
                App.webSocket.publish('cti', {action: 'agentPause', device: deviceInput.value, group: groupInput.value});
                localStorage.removeItem('ctiManuallyLoggedOff');
                break;
            case 'UNAVAILABLE':
                App.webSocket.publish('cti', {action: 'agentLogout'});
                localStorage.setItem('ctiManuallyLoggedOff', '1');
                break;
        }
    }
};

const togglePause = () => {
    if (isReady.value && 'BUSY' !== status.value) {
        if ('UNAVAILABLE' === status.value || 'PAUSE' === status.value) {
            setStatus('READY');
        } else if ('READY' === status.value) {
            setStatus('PAUSE');
        }
    }
};

const statuses = computed(() => {
    const res = {
      'UNAVAILABLE': {label: 'Déconnecté', variant: 'dark', icon: 'fa-solid fa-circle-xmark'},
      'PAUSE': {label: 'Absent', variant: 'info', icon: 'fa-solid fa-circle-pause'},
    }

    if (status.value === 'WRAPUP') {
      res.WRAPUP = {label: 'Disponible', variant: 'primary', icon: 'fa-solid fa-circle-check'}
    } else {
      res.READY = {label: 'Disponible', variant: 'success', icon: 'fa-solid fa-circle-check'}
    }

    return res;
});

const deviceChoices = computed(() => phones.value.reduce((res, item) => {
    const group = item.available ? 'Disponible' : 'Utilisé';
    if (!(group in res)) {
        res[group] = {};
    }
    res[group][item.device] = '['+item.device+'] '+ item.name;

    return res;
}, {}));

const isReady = computed(() => {
    return !!(
        webSocketOnline.value
        && deviceInput.value
        && groupInput.value
    );
});




const formatDateSeparator = (date) => {
    const moment = Moment(date, 'DD/MM/YYYY');

    if(moment.isValid()) {
        if(moment.isSame(Moment(), 'day')) {
            return 'Aujourd\'hui';
        }
        if(moment.isSame(Moment().subtract(1, 'day'), 'day')) {
            return 'Hier';
        }
    }

    return date;
};

const saveAgentConf = () => {
    if ((!deviceInput.value || !groupInput.value) && 'UNAVAILABLE' !== status.value) {
        // Si on vide une info
        App.webSocket.publish('cti', {action: 'agentLogout'});
    } else if (webSocketOnline.value && deviceInput.value && groupInput.value && (deviceInput.vaue !== device.value || groupInput.value !== group.value || 'UNAVAILABLE' === status.value)) {
        App.webSocket.publish('cti', {action: 'updateAgent', device: deviceInput.value, group: groupInput.value});
    }
};


const call = (number, match) => {
    if (!isReady.value || 'BUSY' === status.value) {
        return;
    }

    searchQuery.value = '';

    if (number !== '') {
        App.webSocket.publish('cti', {action: 'call', number});

        emit('dialing', {number, match});}
};

const searchSelectedNumber = computed(() => {
    if(searchItem.value) {
        return searchItem.value.numbers[searchNumberIndex.value].number;
    }

    return null;
});
const searchItem = computed(() => {
    if(searchResults.value.length) {
        return searchResults.value[searchIndex.value];
    }

    return null;
});

const contactIcon = (type) => {
    switch (type) {
        case 'vehicule': return 'ti-car';
        case 'personnel': return 'fa-light fa-user-nurse';
        case 'patient': return 'icon-people';
        case 'appareil': return 'ti-tablet';
        case 'caisse': return 'fa-light fa-building-columns';
        case 'mutuelle': return 'fa-light fa-people-roof';
        case 'prescripteur': return 'fa-light fa-house-medical';
        case 'divers': return 'fa-light fa-building';
    }

    return 'fa-solid fa-question';
};


window.addEventListener('keydown', (e) => {
    if(e.key === 'Escape') {
        if(searchQuery.value !== '') {
            searchQuery.value = '';
            return;
        }
    }

    if(e.key === 'Enter') {
        call(searchSelectedNumber.value || searchQuery.value);
        searchQuery.value = '';
        return;
    }

    if(!searchResults.value.length) {
        return;
    }

    if(['ArrowUp', 'ArrowDown'].includes(e.key)) {
        let newIndex = searchIndex.value + ('ArrowUp' === e.key ? -1 : 1);
        if(newIndex in searchResults.value) {
            searchIndex.value = newIndex;
        } else {
            searchIndex.value = ('ArrowUp' === e.key ? searchResults.value.length-1 : 0);
        }
        searchNumberIndex.value = 0;

        e.preventDefault();
    }
    else if(['ArrowLeft', 'ArrowRight'].includes(e.key)) {
        let newIndex = searchNumberIndex.value + ('ArrowLeft' === e.key ? -1 : 1);
        if(newIndex in searchItem.value.numbers) {
            searchNumberIndex.value = newIndex;
        } else {
            searchNumberIndex.value = ('ArrowLeft' === e.key ? searchItem.value.numbers.length-1 : 0);
        }

        e.preventDefault();
    }
});


</script>