<template>
    <div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Factures à corriger</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-table table-class="table-compact" ref="aCorrigerTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="aCorriger" :fields="aCorrigerFields" :per-page="perPage" :current-page="aCorrigerCurrentPage" sort-by="date" primary-key="id">
                        <template #empty>
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #table-busy>
                            <div class="p-3">
                                <b-spinner class="mr-2"></b-spinner> Chargement…
                            </div>
                        </template>
                        <template #cell(numero)="{item}">
                            <fallback-link target="_blank" :role="item._roles.VOTE_FACTURATION_FACTURE_VIEW" :url="Router.generate('facturation.facture.view', {id: item.id})">
                                {{ item.numero }}
                            </fallback-link>
                        </template>
                        <template #cell(patient)="{value}">
                            <a target="_blank" :href="Router.generate('shared.patient.view', {app: 'facturation', id: value.id})">
                                {{ value.text }}
                            </a>
                        </template>
                        <template #cell(caisse)="{value}">
                            <a target="_blank" :href="Router.generate('referentiel.organisme.caisse.view', {id: value.id})">
                                {{ value.libelle }}
                            </a>
                        </template>
                        <template #cell(partCaisseMontant)="{value}">
                            {{ Euro(value) }}
                        </template>
                        <template #cell(retourItemAbstractCollection)="{item, value}">
                            <template v-for="retourItem in value">
                                <template v-for="ligne in retourItem.ligneUniqueCollection">
                                    <a target="_blank" :href="Router.generate('facturation.noemie.retour.view', {id: retourItem.retour.id})" class="badge badge-secondary">
                                        {{ ligne.libelle }}
                                    </a>
                                </template>
                            </template>
                            <span v-if="item.estTraitementManuel" class="badge badge-warning" v-b-tooltip.hover title="La facture va être traitée manuellement par la caisse. Il est conseillé d'attendre d'avoir plus d'informations concernant le paiement de la facture avant de traiter ce retour.">
                                <i class="fa-solid fa-circle-info"></i>
                            </span>
                        </template>
                        <template #cell(structure)="{value}">
                            <a target="_blank" :href="Router.generate('referentiel.structure.view', {id: value.id})" :title="value.libelleComplet" class="badge badge-block badge-secondary label-outline text-nowrap">
                                {{ value.libelle }}
                            </a>
                        </template>
                        <template #cell(action)="{item}">
                            <button type="button" :disabled="aCorrigerPending" @click="imputerRejet(item)" v-if="item.estTraitementManuel && item._roles.ROLE_FACTURATION_NOEMIE_RETOUR_REJET_IMPUTER" :title="Translator.trans('action.ignorer-rejet-creer-reglement')" class="btn btn-table btn-success">
                                <i class="fa-regular fa-fw fa-credit-card"></i>
                            </button>
                            <button type="button" :disabled="aCorrigerPending" @click="ignorerRejet(item)" :title="Translator.trans('action.ignorer-rejet')" class="btn btn-table btn-secondary">
                                <i class="fa-solid fa-fw fa-check"></i>
                            </button>
                            <a v-if="item._roles.VOTE_FACTURATION_FACTURE_INVALIDER" :class="{disabled: aCorrigerPending}" :href="Router.generate('facturation.facture.invalider', {id: item.id})" :title="Translator.trans('action.invalider')" class="btn btn-table btn-info js-confirm-btn" data-confirm-color="info" :data-action="Translator.trans('action.invalider')" :data-text="Translator.trans('facturation.retour.confirm.invalider')">
                                <i class="fa-solid fa-fw fa-unlock"></i>
                            </a>
                        </template>
                    </b-table>
                    <table-pagination :count="aCorriger.length" :per-page="perPage" v-model="aCorrigerCurrentPage"></table-pagination>
                </div>
            </div>
        </div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Retours à imputer</div>
                <div>
                    <span class="badge badge-secondary" :title="Translator.trans('libelle.montant-total')" v-if="aImputer.length">
                        {{ Euro(aImputer.reduce((montant, retour) => montant + parseFloat(retour.montantTotal) || 0, 0)) }}
                    </span>
                </div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-table table-class="table-compact" ref="aImputerTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="aImputer" :fields="aImputerFields" :per-page="perPage" :current-page="aImputerCurrentPage" sort-by="date" primary-key="id">
                        <template #empty>
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #table-busy>
                            <div class="p-3">
                                <b-spinner class="mr-2"></b-spinner> Chargement…
                            </div>
                        </template>
                        <template #cell(numero)="{item}">
                            <fallback-link target="_blank" :role="item._roles.ROLE_FACTURATION_NOEMIE_RETOUR_VIEW" :url="Router.generate('facturation.noemie.retour.view', {id: item.id})">
                                {{ item.numero }}
                            </fallback-link>
                        </template>
                        <template #cell(montantTotal)="{value}">
                            {{ Euro(value) }}
                        </template>
                        <template #cell(lotCollection)="{value, item}">
                            <fallback-label target="_blank" v-for="lot in value" :key="lot.id" :role="item._roles.ROLE_FACTURATION_B2_SEFI_LOT_VIEW" :url="Router.generate('facturation.lot.b2-sefi.view', {id: lot.id})">
                                {{ lot.numero }}
                            </fallback-label>
                        </template>
                        <template #cell(itemCollection)="{value}">
                            {{ value.length }}
                        </template>
                        <template #cell(caisse)="{value}">
                            <a target="_blank" :href="Router.generate('referentiel.organisme.caisse.view', {id: value.id})">
                                {{ value.libelle }}
                            </a>
                        </template>
                        <template #cell(structure)="{value}">
                            <a target="_blank" :href="Router.generate('referentiel.structure.view', {id: value.id})" :title="value.libelleComplet" class="badge badge-block badge-secondary label-outline text-nowrap">
                                {{ value.libelle }}
                            </a>
                        </template>
                        <template #cell(action)="{item}">
                            <template v-if="item._impute">
                                <a :href="Router.generate('facturation.noemie.retour.courrier.generate', {id: item.id})" :title="Translator.trans('action.generer-courriers')" class="btn btn-table btn-info" target="_blank">
                                    <i class="fa-regular fa-fw fa-envelope"></i>
                                </a>
                            </template>
                            <template v-else>
                                <button type="button" :disabled="aImputerPending" v-if="item._roles.ROLE_FACTURATION_NOEMIE_RETOUR_PAIEMENT_IMPUTER" @click="imputerPaiement(item)" :title="Translator.trans('action.imputer')" class="btn btn-table btn-primary">
                                    <i class="fa-regular fa-fw fa-credit-card"></i>
                                </button>
                                <button type="button" :disabled="aImputerPending" v-if="item._roles.ROLE_FACTURATION_NOEMIE_RETOUR_PAIEMENT_IGNORER" @click="ignorerPaiement(item)" :title="Translator.trans('action.ignorer')" class="btn btn-table btn-secondary">
                                    <i class="fa-solid fa-fw fa-xmark"></i>
                                </button>
                            </template>
                        </template>
                    </b-table>
                    <table-pagination :count="aImputer.length" :per-page="perPage" v-model="aImputerCurrentPage"></table-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Euro from "@/Vue/Filters/Euro";
import swal from "sweetalert2";
import Translator from "@/App/Translator";
import {computed, onMounted, ref, toRefs, watch} from 'vue'
import Request from "@/App/Request";
import Router from "@/App/Router";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const loading = ref(false);
const aCorriger = ref([]);
const aImputer = ref([]);
const perPage = ref(15);
const aCorrigerCurrentPage = ref(1);
const aImputerCurrentPage = ref(1);
const aImputerPending = ref(false);
const aCorrigerPending = ref(false);

const aCorrigerFields = computed(() => {
    return [
        {key: 'numero', label: Translator.trans('libelle.numero'), sortable: true, thClass: 'width-1px'},
        {key: 'date', label: Translator.trans('libelle.date'), sortable: true, thClass: 'width-1px'},
        {key: 'patient', label: Translator.trans('libelle.patient'), sortable: true},
        {key: 'caisse', label: Translator.trans('libelle.caisse'), sortable: true},
        {key: 'partCaisseMontant', label: Translator.trans('libelle.montant-caisse-abreviation'), sortable: true, tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
        {key: 'retourItemAbstractCollection', label: Translator.trans('libelle.retour-s')},
        structures.value.length > 1 ? {key: 'structure', label: Translator.trans('libelle.societe'), sortable: true, thClass: 'width-1px'} : null,
        {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-right text-nowrap'},
    ];
});
const aImputerFields = computed(() => {
    return [
        {key: 'numero', label: Translator.trans('libelle.numero'), sortable: true, thClass: 'width-1px'},
        {key: 'date', label: Translator.trans('libelle.date'), sortable: true, thClass: 'width-1px', tdClass: 'text-nowrap'},
        {key: 'caisse', label: Translator.trans('libelle.caisse'), sortable: true},
        {key: 'lotCollection', label: Translator.trans('libelle.lots'), sortable: true},
        {key: 'itemCollection', label: Translator.trans('libelle.nombre-factures-abreviation'), headerTitle: Translator.trans('libelle.nombre-factures'), thClass: 'width-1px text-nowrap', sortable: true},
        {key: 'dateComptable', label: Translator.trans('libelle.dateComptable'), sortable: true, thClass: 'width-1px text-nowrap'},
        {key: 'montantTotal', label: Translator.trans('libelle.montant-total'), sortable: true, tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
        structures.value.length > 1 ? {key: 'structure', label: Translator.trans('libelle.societe'), sortable: true, thClass: 'width-1px'} : null,
        {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-center text-nowrap'},
    ];
});

const getImputerOptionsHtml = (item) => {
    return '<div class="input-group mt-3 w-50 mx-auto">\n' +
        '    <div class="input-sm d-none"></div>\n' +
        '    <div class="input-group-prepend"><span class="input-group-text">Date de règlement</span></div>\n' +
        '    <input type="text" class="form-control js-datetimepicker input-sm" data-confirm-option="imputer_date" data-format="DD/MM/YYYY" data-inputmask="\'alias\': \'custom-datetime\'" data-inputmask-inputformat="dd/mm/yyyy" id="imputer_date" value="'+item.dateComptable+'">\n' +
        '</div>\n' +
        '<div class="input-group mt-3 w-50 mx-auto">\n' +
        '    <div class="input-sm d-none"></div>\n' +
        '    <div class="input-group-prepend"><span class="input-group-text">Commentaire</span></div>\n' +
        '    <input type="text" class="form-control input-sm" data-confirm-option="imputer_commentaire" id="imputer_commentaire" value="">\n' +
        '</div>';
};
const refresh = () => {
    aCorriger.value = [];
    aCorrigerPending.value = false;
    aCorrigerCurrentPage.value = 1;

    aImputer.value = [];
    aImputerPending.value = false;
    aImputerCurrentPage.value = 1;

    if(!structures.value.length) {
        abort();
        loading.value = false;
        emit('refresh');

        return;
    }

    loading.value = true;
    Request.postJson(Router.generate('facturation.tableau-de-bord.noemie'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((data) => {
        aCorriger.value = data.aCorriger;
        aImputer.value = data.aImputer;

        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
};
const ignorerRejet = (facture) => {
    let html = 'Le rejet de la facture '+facture.numero+' sera ignoré.';

    if (!facture.estBasculeeEnComptabilite) {
        html += '<div class="swal2-confirm-options"><div class="checkbox"><input type="checkbox" id="confirm_resetEtatFacture"><label for="confirm_resetEtatFacture">Rendre la facture à nouveau télétransmissible</label></div></div>';
    }

    swal({
        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
        type: 'warning',
        html,
        showCancelButton: true,
        cancelButtonText: Translator.trans('action.annuler'),
        confirmButtonText: Translator.trans('action.ignorer'),
        confirmButtonClass: 'bg-dark',
    }).then((result) => {
        if (result.value) {
            let params = {id: facture.id};
            if($('#confirm_resetEtatFacture').is(':checked')) {
                params.resetEtatFacture = '';
            }

            aCorrigerPending.value = true;

            Request.getJson(Router.generate('facturation.tableau-de-bord.noemie.rejet.ignorer', params)).then(() => {
                aCorriger.value.splice(aCorriger.value.indexOf(facture), 1);
                aCorrigerPending.value = false;
                App.Notification.success('Les rejets de la facture '+facture.numero+' ont été ignorés.');
            });
        }
    });
};
const imputerRejet = (facture) => {
    swal({
        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
        type: 'warning',
        html: 'Le rejet de la facture '+facture.numero+' sera ignoré et un règlement de '+Euro(facture.partCaisseMontant)+' sera créé.'+getImputerOptionsHtml(facture.retourItemAbstractCollection[0].retour),
        showCancelButton: true,
        cancelButtonText: Translator.trans('action.annuler'),
        confirmButtonText: Translator.trans('action.imputer'),
        confirmButtonClass: 'bg-success',
        onOpen: () => App.Layout.enableCustomInputs(),
    }).then((result) => {
        if (result.value) {
            aCorrigerPending.value = true;

            Request.getJson(Router.generate('facturation.tableau-de-bord.noemie.rejet.imputer', {
                id: facture.id,
                imputer_date: $('#imputer_date').val(),
                imputer_commentaire: $('#imputer_commentaire').val(),
            })).then(() => {
                aCorriger.value.splice(aCorriger.value.indexOf(facture), 1);
                aCorrigerPending.value = false;

                App.Notification.success('Le rejet a été ignoré et un règlement a été créé pour la facture '+facture.numero+'.');
            });
        }
    });
};
const imputerPaiement = (item) => {
    swal({
        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
        type: 'warning',
        html: Translator.trans('facturation.retour.confirm.imputer', {numero: item.numero})+getImputerOptionsHtml(item),
        showCancelButton: true,
        cancelButtonText: Translator.trans('action.annuler'),
        confirmButtonText: Translator.trans('action.imputer'),
        confirmButtonClass: 'bg-primary',
        onOpen: () => App.Layout.enableCustomInputs(),
    }).then((result) => {
        if (result.value) {
            aImputerPending.value = true;

            Request.getJson(Router.generate('facturation.noemie.retour.paiement.imputer', {
                id: item.id,
                imputer_date: $('#imputer_date').val(),
                imputer_commentaire: $('#imputer_commentaire').val(),
            })).then((data) => {
                if (data._roles.ROLE_FACTURATION_NOEMIE_RETOUR_COURRIER_GENERATE) {
                    item._impute = true;
                } else {
                    aImputer.value.splice(aImputer.value.indexOf(item), 1);
                }
                aImputerPending.value = false;
                App.Notification.success(Translator.trans('facturation.retour.flash.paiement-impute', {numero: item.numero}));
            });
        }
    });
};
const ignorerPaiement = (item) => {
    swal({
        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
        type: 'warning',
        text: Translator.trans('facturation.retour.confirm.ignorer-paiements', {numero: item.numero}),
        showCancelButton: true,
        cancelButtonText: Translator.trans('action.annuler'),
        confirmButtonText: Translator.trans('action.ignorer'),
        confirmButtonClass: 'bg-dark',
    }).then((result) => {
        if (result.value) {
            aImputerPending.value = true;

            Request.getJson(Router.generate('facturation.noemie.retour.paiement.ignorer', {id: item.id})).then(() => {
                aImputer.value.splice(aImputer.value.indexOf(item), 1);
                aImputerPending.value = false;
                App.Notification.success(Translator.trans('facturation.retour.flash.paiement-ignore', {numero: item.numero}));
            });
        }
    });
};


watch(structures, () => refresh());
onMounted(() => refresh());

</script>
