<template>
    <div class="notifications">
        <notification-bandeau :dropdown-open="isOpen" :badge-is-large="showCounter && badgeCollapsed" @click="isOpen = true"></notification-bandeau>
        <transition name="dropdown-notifications" duration="800">
        <div class="dropdown-notifications" style="z-index: 0; min-width: 0;" ref="dropdownElt" v-if="isOpen">
            <div class="notification-inner-badge badge-light border p-1 d-flex align-items-center" @click="isOpen = false">
                <div class="flex-1">
                    <i class="mx-2 fa-regular fa-bell"></i>
                    <span>Centre de notifications</span>
                </div>
                <div class="notification-inner-badge-close">
                    <i class="mx-2 fa-regular fa-xmark"></i>
                </div>
            </div>
            <div class="mt-2 js-perfect-scrollbar">
                <notification-liste :notifications="notifications" v-if="notifications.length"></notification-liste>
                <div class="text-center mt-5" v-else>
                    <i class="fa-4x fa-regular fa-message-xmark"></i>
                    <h3>Aucune notification</h3>
                    <p class="text-muted">Lorsque vous en recevrez,<br>un indicateur apparaîtra sur la cloche.</p>
                </div>
            </div>
        </div>
        </transition>
        <div v-if="badgeCollapsed" :title="Translator.trans('libelle.centre-notifications')" @click="toggle" class="cursor-pointer notifications-badge badge-light border p-1 d-flex align-items-center" ref="badgeELt" :class="{'open': isOpen, [badgeBeatClass]: true}">
            <span style="width: 1.6em!important; border-radius: 100%!important;" class="text-center" :class="showCounter ? 'mr-1' : ''">
                <i class="mx-2 fa-regular fa-bell" v-if="countNonLu === 0"></i>
                <i class="mx-2 fa-solid fa-bell-on fa-beat-fade" v-else></i>
            </span>
            <span class="px-2 text-center font-weight-600" :class="(countNonLu > 0 ? 'ml-1 text-light bg-' : 'text-') + (criticiteBadge === 2 ? 'danger' : (criticiteBadge === 1 ? 'warning' : 'info'))" style="border-radius: 13px; width: 1.6em!important; text-shadow: 0 0 2px rgba(0,0,0,0.3)" v-if="showCounter">
                <i v-if="countNonLu === 0" class="fa-solid fa-circle-dot"></i>
                <template v-else>{{ Math.min(countNonLu, 9) + (countNonLu > 9 ? '+' : '') }}</template>
            </span>
        </div>
    </div>
</template>
<script setup>
import {useNotificationsStore} from "@/Vue/Stores/Notifications";
import {computed, onMounted, ref, watch} from 'vue';
import {onClickOutside, onKeyStroke} from "@vueuse/core";
import {storeToRefs} from "pinia";
import Translator from "@/App/Translator";

const props = defineProps({
    initData: Object
})

const notificationsStore = useNotificationsStore();
const {notifications, criticiteNonLu, criticiteEnCours, countNonLu} = storeToRefs(notificationsStore)

const isOpen = ref(false);
const badgeCollapsed = ref(true);
const dropdownElt = ref(null);
const badgeELt = ref(null);
const showCounter = computed(() => countNonLu.value > 0 || criticiteEnCours.value >= 0);

const criticiteBadge = computed(() => Math.max(criticiteEnCours.value, criticiteNonLu.value));

// const notificationSound = new Audio(props.notificationSoundPath)

notificationsStore.init(props.initData);

const badgeBeatClass = ref(null);

const badgeBeat = (startDelay, small) => {
    setTimeout(() => badgeBeatClass.value = small ? 'beat-small' : 'beat', startDelay);
    setTimeout(() => badgeBeatClass.value = null, startDelay+120);
}


const toggle = () => isOpen.value = !isOpen.value;

watch(isOpen, () => {
    if (isOpen.value) {
        badgeCollapsed.value = false;
    } else {
        notificationsStore.markAsRead();

        setTimeout(() => {
            badgeCollapsed.value = true;
        }, 800)
    }
});

watch(badgeCollapsed, () => {
    if (badgeCollapsed.value) {
        badgeBeat(0);
    }
});

onMounted(() => {
    App.Layout.enablePerfectScrollbar()
})

onClickOutside(dropdownElt, () => {
    isOpen.value = false;
}, {ignore: [badgeELt]})

onKeyStroke('Escape', () => {
    isOpen.value = false;
})

setInterval(() => {
    if (!isOpen.value && countNonLu.value) {
        badgeBeat(0,true);
        badgeBeat(300,true);
    }
}, 5000)
</script>
