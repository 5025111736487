<template>
    <div class="row">
        <div class="col-xl-4 order-1 order-xl-2">
            <div class="white-box">
                <h3 class="text-theme mt-0">
                    <i class="fa-solid fa-grid-2"></i>
                    {{ Translator.trans('action.ajouter') }}
                </h3>
                <div id="dropzone" class="dropzone">
                    <div class="dz-message">
                        <div class="font-size-24px text-normal">Cliquez ici</div>
                        <div class="text-muted">ou</div>
                        <div class="font-size-16px text-normal mt-2">déposez directement les fichiers</div>
                    </div>
                </div>
            </div>
            <div class="white-box p-0" v-if="hasScor">
                <div class="p-4">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <h3 class="text-theme mt-0">
                                <i class="fa-solid fa-grid-2"></i>
                                {{ Translator.trans('chapitre.scor') }}
                            </h3>
                            <div class="custom-switch serie-switch text-theme ml-3 mb-3" :class="{'opacity-50': !standard.piecesScor.length}">
                                <input type="checkbox" class="custom-control-input" id="pretEnvoiScor" v-model="standard.pretEnvoiScor" :disabled="scorLoading || !standard.piecesScor.length" @change="saveScor">
                                <label class="mr-3" for="pretEnvoiScor"></label>
                                <label class="custom-control-label ml-5" for="pretEnvoiScor">Prêt pour envoi</label>
                            </div>
                        </div>
                        <div>
                            <b-btn title="Ajouter les pièces sélectionnées" variant="success" :disabled="!canAddSelectedPiecesToScor || scorLoading" @click="addSelectedPiecesToScor">
                                <i class="fa-regular fa-copy"></i><span class="ml-2" v-if="canAddSelectedPiecesToScor">{{ canAddSelectedPiecesToScor }}</span>
                            </b-btn>
                            <b-dropdown right title="Ajouter une pièce papier" variant="secondary" :disabled="scorLoading">
                                <template #button-content>
                                    <i class="fa-solid fa-plus"></i>
                                </template>
                                <b-dropdown-item href="#" @click="addTypeToScor(typeScor)" v-for="typeScor in typesScor" :key="typeScor.id">{{ typeScor.libelle }}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <piece-scor v-if="standard.piecesScor.length" :pieces-scor="standard.piecesScor" :scor-loading="scorLoading" :form="true" @open="openPiece" @remove="removePieceScor"></piece-scor>
                    <div v-else>
                        <div class="piece-empty">{{ Translator.trans('libelle.aucun-element') }}</div>
                    </div>
                </div>
                <div v-if="standard.lotsScor.length" class="bg-light px-4 pb-4 pt-3">
                    <div class="font-bold">Lots déjà envoyés</div>
                    <div v-for="lotScor in standard.lotsScor" class="d-flex d-flex align-items-center border mt-2 pl-3 pr-2 py-2 bg-white">
                        <div class="flex-1">
                            <span class="badge badge-dark">{{ lotScor.lotAbstract.numero }}</span>
                            <span class="badge badge-secondary">{{ lotScor.pieceCollection.length }} {{ lotScor.pieceCollection.length > 1 ? 'pièces' : 'pièce' }}</span>
                            <span class="ml-2">{{ lotScor.date }}</span>
                        </div>
                        <div>
                            <b-btn variant="info" size="sm" :href="Router.generate('facturation.lot.scor.view', {id: lotScor.id})">
                                <i class="fa-solid fa-eye"></i>
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8 order-2 order-xl-1">
            <div class="white-box">
                <div class="row">
                    <div class="col-xl-2 col-xxl-3">
                        <h3 class="text-theme mt-0">
                            <i class="fa-solid fa-grid-2"></i>
                            {{ Translator.trans('chapitre.pieces') }}
                        </h3>
                    </div>
                    <div class="col-xl-10 col-xxl-9">
                        <div class="search-form d-flex mt-n2">
                            <piece-filter :groups="groups" :types="types" v-model="filter" :full="true"></piece-filter>
                            <piece-popover class="ml-4" @update="refresh" @scor="togglePieceScor($event.piece, $event.scor)" :url="nonAffecteUrl" :update-url="updateUrl+'?nonAffecte'" :types="types" :type-presets="typePresets" :liens="liens" :standard="standard" :batch-delete-url="batchDeleteUrl+'?nonAffecte'" :batch-update-url="batchUpdateUrl+'?nonAffecte'" ></piece-popover>
                        </div>
                    </div>
                </div>
                <piece-container ref="pieces" id="pieces" :groups="groups" :piece-per-page="10" :filter="filter" :types="types" @update="handleUpdate" @scor="togglePieceScor($event.piece, $event.scor)" :update-url="updateUrl" :batch-delete-url="batchDeleteUrl" :batch-update-url="batchUpdateUrl" :type-presets="typePresets" :liens="liens" :standard="standard" @selected="selectedPieces = $event" :has-scor="hasScor"></piece-container>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, computed, ref, provide} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import Translator from "@/App/Translator";

const props = defineProps({
   initData: Object,
});

const selectedPieces = ref([]);
const filter = ref({
    search: '',
    type: [],
});

const pieces = ref();
const scorLoading = ref(false);
const standard = ref(props.initData.standard);
const liens = ref(props.initData.liens);
const types = ref(props.initData.types);
const typePresets = ref(props.initData.typePresets);
const groups = ref(props.initData.groups);

const uploadUrl = props.initData.uploadUrl;
const refreshUrl = props.initData.refreshUrl;
const updateUrl = props.initData.updateUrl;
const batchUpdateUrl = props.initData.batchUpdateUrl;
const batchDeleteUrl = props.initData.batchDeleteUrl;
const nonAffecteUrl = props.initData.nonAffecteUrl;
const updateScorUrl = props.initData.updateScorUrl;

const hasScor = computed(() => standard.value && standard.value.canScor);
const canRemoveSelectedPiecesToScor = computed(() => canRemovePiecesToScor(selectedPieces.value));
const canAddSelectedPiecesToScor = computed(() => canAddPiecesToScor(selectedPieces.value));
const typesScor = computed(() => types.value);
const typesById = computed(() => types.value.reduce((res, type) => (res[type.id] = type, res), {}));
const piecesByIdByGroup = computed(() => groups.value.map(group => group.pieces.reduce((res, item) => (res[item.id] = item, res), {})));
const allPieces = computed(() => groups.value.map(group => group.pieces).flat());
const allPiecesById = computed(() => allPieces.value.reduce((res, item) => (res[item.id] = item, res), {}));

const refresh = () => {
    Request.getJson(refreshUrl).then((data) => {
        handleUpdate(data);
    });
};

const togglePieceScor = (piece, toggle) => {
    if (piece) {
        if (toggle) {
            addPieceToScor(piece);
            saveScor();
        } else {
            const pieceScor = getPieceScorForPiece(piece);
            if (pieceScor) {
                removePieceScor(pieceScor);
            }
        }
    }
};

const canRemovePiecesToScor = (pieces) => {
    for(const piece of pieces) {
        if(getPieceScorForPiece(piece)) {
            return true;
        }
    }

    return false;
};

const canAddPiecesToScor = (pieces) => {
    let count = 0;

    for(const piece of pieces) {
        if(canAddPieceToScor(piece)) {
            count++;
        }
    }

    return count;
};

const canAddPieceToScor = (piece) => piece.type && piece.estControle && piece.document.filesize <= App.Constants.PIECE_MAX_FILE_SIZE * 1024 && !getPieceScorForPiece(piece);;

const addSelectedPiecesToScor = () => {
    for(const piece of selectedPieces.value) {
        addPieceToScor(piece);
    }

    saveScor();
};

const removeSelectedPiecesFromScor = () => {
    for(const piece of selectedPieces.value) {
        const pieceScor = getPieceScorForPiece(piece);
        if(pieceScor) {
            removePieceScor(pieceScor, false);
        }
    }

    saveScor();
};

const getPieceScorForPiece = (piece) => {
    if (standard.value) {
        for (const pieceScor of standard.value.piecesScor) {
            if (pieceScor.piece === piece) {
                return pieceScor;
            }
        }
    }

    return null;
};

const removePieceScor = (pieceScor, save = true) => {
    const index = standard.value.piecesScor.indexOf(pieceScor);
    standard.value.piecesScor.splice(index, 1);
    standard.value.pretEnvoiScor = false;

    if (save) {
        saveScor();
    }
};

const addPieceToScor = (piece) => {
    if(canAddPieceToScor(piece)) {
        standard.value.pretEnvoiScor = false;
        standard.value.piecesScor.push({
            piece, type: piece.type,
        });
    }
};

const addTypeToScor = (type) => {
    standard.value.pretEnvoiScor = false;
    standard.value.piecesScor.push({
        piece: null, type,
    });

    saveScor();
};

const handleUpdate = (data) => {
    if (data.groups) {
        groups.value = data.groups; // todo find duplicated pieces accross group and set same instance
    }
    if (data.standard) {
        standard.value = data.standard;
    }

    if (standard.value) {
        updatePiecesScor(standard.value.piecesScor);
    }
};

const saveScor = () => {
    if(updateScorUrl) {
        scorLoading.value = true;

        Request.postJson(updateScorUrl, {
            pretEnvoiScor: standard.value.pretEnvoiScor,
            pieceScorPanierCollection: standard.value.piecesScor.map(pieceScor => ({
                type: pieceScor.type ? pieceScor.type.id : null,
                piece: pieceScor.piece ? pieceScor.piece.id : null,
            })),
        }).then((data) => {
            handleUpdate(data);
            scorLoading.value = false;
        });
    }
};

const updatePiecesScor = (data) => {
    standard.value.piecesScor = data.map(pieceScor => {
        if (pieceScor.piece) {
            pieceScor.piece = allPiecesById.value[pieceScor.piece.id] || pieceScor.piece;
        }
        if (pieceScor.type) {
            pieceScor.type = typesById.value[pieceScor.type.id] || pieceScor.type;
        }

        return pieceScor;
    });
};

const openPiece = (piece, stack = []) => {
    pieces.value.openPiece(piece, stack);
};

onMounted(() => {
    const dropzone = App.Shared.Piece.dropzone('#dropzone', {
        url: uploadUrl,
        uploadMultiple: true,
    });

    dropzone.on('error', function(file, response) {
        console.log(response);
    });

    dropzone.on('success', (file, data) => {
        handleUpdate(data);

        const newPieces = data.pieces.map(id => piecesByIdByGroup.value[0][id]);
        openPiece(newPieces[0], newPieces);

        setTimeout(() => dropzone.removeFile(file), 1000);
    });

    if (standard.value) {
        updatePiecesScor(standard.value.piecesScor);
    }

    document.dispatchEvent(new Event('app:vuejs:mounted'));
});

provide('canAddPiecesToScor', canAddPiecesToScor);
provide('canRemovePiecesToScor', canRemovePiecesToScor);
provide('addSelectedPiecesToScor', addSelectedPiecesToScor);
provide('removeSelectedPiecesFromScor', removeSelectedPiecesFromScor);
</script>
