<template>
    <div class="item item-event" @mouseleave="$emit('mouseleave')" @mouseover="$emit('mouseover')" @mousedown="$emit('mousedown')" :class="{'opacity-80': !personnel.estActif, 'ui-selected': selected, 'ui-selecting': !!selecting, 'jour-non-travaille': estJourNonTravaille, 'glow': estDansPressePapier && !hideGlow}" :title="!event ? jourNonTravailleTitle :''" :style="cssVars">
        <div class="publication" :title="'Publié jusqu\'au ' + dateStr" :class="{'cursor-col-resize': editEnabled && 'select' === tool, 'opacity-50': publicationHover === day && personnel.publication !== day.id}" v-if="(personnel.publication === day.id && !publicationHover) || publicationHover === day" @mouseover="$emit('publication-hover')" @mouseleave="$emit('publication-hover-leave')" @mousedown="$emit('start-publication-drag')"></div>
        <div class="validation" :title="'Validé jusqu\'au ' + dateStr" :class="{'cursor-col-resize': editEnabled && 'select' === tool, 'opacity-50': validationHover === day && personnel.validation !== day.id}" v-if="(personnel.validation === day.id && !validationHover) || validationHover === day" @mouseover="$emit('validation-hover')" @mouseleave="$emit('validation-hover-leave')" @mousedown="$emit('start-validation-drag')"></div>
        <div class="event position-relative" :style="{ color: '#' + event.couleurTexte, backgroundColor: '#' + event.couleur }" v-if="event && !hovered" :class="{ 'event-clickable': canView, 'event-start': isEventStart, 'event-end': isEventEnd}" :title="event.libelle + (eventState ? ' ('+Translator.trans('libelle.event-state.'+eventState)+')' : '' )" @click="$emit('open-view')" @dblclick="$emit('open-edit')">
            {{ event.code }}
            <span v-if="eventState" class="position-absolute fa-stack fa-sm" style="top: -4px; right: -5px; z-index: 2;">
              <i class="fa-solid fa-circle fa-stack-1x" :style="{'text-stroke': '1.5px' + '#'+event.couleur}"></i>
              <i v-if="eventState === 'new'" class="fa-solid fa-stack-1x fa-inverse fa-plus" :style="{color: '#' + event.couleur}" style="font-size: 75%; top: 2px"></i>
              <i v-if="eventState === 'edited'" class="fa-solid fa-stack-1x fa-inverse fa-pen" :style="{color: '#' + event.couleur}" style="font-size: 60%; top: 4px"></i>
            </span>
            <i class="fa-solid fa-exclamation event-jour-non-travaille" v-if="event.estTravailAbstract && estJourNonTravaille" :title="jourNonTravailleTitle"></i>
            <i class="fa-solid fa-check event-acquitte" v-if="event.acquittementAt && event.acquittementDebutPrevu.date === event.debutPrevu.date && event.acquittementDebutPrevu.time === event.debutPrevu.time"></i>
        </div>
        <div class="event event-ghost" v-if="hovered" :style="{ color: '#' + currentType.couleurTexte, backgroundColor: '#' + currentType.couleur }">{{ currentType.code }}</div>
        <div v-if="(eventState === 'removed' || eventState === 'cut' || eventState === 'tempCut') && !event && !hovered" class="event border text-center" :class="{'text-danger border-danger': eventState === 'removed', 'text-success border-success': eventState === 'cut' || eventState === 'tempCut' }" style="border-radius: .5em;" :style="{'opacity': estDansPressePapier ? '0.8' : '0.3'}">
          <i v-if="eventState === 'removed'" class="fa-solid fa-xmark"></i>
          <i v-if="eventState === 'cut' || eventState === 'tempCut'" class="fa-solid fa-scissors"></i>
        </div>
    </div>
</template>

<script>
import Translator from "@/App/Translator";

export default {
    props: ['day', 'event', 'tool', 'canView', 'isEventStart', 'isEventEnd', 'personnel', 'selected', 'selecting', 'hovered', 'currentType', 'validationHover', 'publicationHover', 'editEnabled', 'personnelIndex', 'estDansPressePapier', 'hideGlow', 'eventState'],
    setup: () => {
        return {
            Translator,
        };
    },
    computed: {
        dateStr() {
            return this.day.date.format('DD/MM/YYYY');
        },
        joursNonTravailles() {
            return this.personnel.joursNonTravailles;
        },
        jourNonTravailleTitle() {
            if (!this.joursNonTravailles.length) {
                return null;
            }

            let isJourNonTravaille = false;

            let title = 'Ce jour est marqué comme un jour non travaillé pour ce personnel';
            let commentaires = [];

            const typeSemaine = (0 === this.day.date.format('w') % 2) ? 'paires' : 'impaires';

            for (const jourNonTravaille of this.joursNonTravailles) {
                if (jourNonTravaille.debut <= this.day.id && (!jourNonTravaille.fin || this.day.id <= jourNonTravaille.fin) && jourNonTravaille.semaines[jourNonTravaille.estPairImpair ? typeSemaine : 'paires'][this.day.date.isoWeekday()-1]) {
                    isJourNonTravaille = true;
                    if (null !== jourNonTravaille.commentaire && !commentaires.includes(jourNonTravaille.commentaire)) {
                        commentaires.push(jourNonTravaille.commentaire);
                    }
                }
            }

            if(!isJourNonTravaille) {
                return null;
            }

            if(commentaires.length) {
                title += ' ('+commentaires.join(', ')+')';
            }

            title += '.';

            return title;
        },
        estJourNonTravaille() {
            if (!this.joursNonTravailles.length) {
                return false;
            }

            const typeSemaine = (0 === this.day.date.format('w') % 2) ? 'paires' : 'impaires';

            for (const jourNonTravaille of this.joursNonTravailles) {
                if (jourNonTravaille.debut <= this.day.id && (!jourNonTravaille.fin || this.day.id <= jourNonTravaille.fin) && jourNonTravaille.semaines[jourNonTravaille.estPairImpair ? typeSemaine : 'paires'][this.day.date.isoWeekday()-1]) {
                    return true;
                }
            }

            return false;
        },
        cssVars() {
            return {
                '--eventColor': '#' + (this.event ? this.event.couleur : 'fff'),
            }
        },
    },
}
</script>
