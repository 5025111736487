<template>
    <a v-if="route && value && value.id" v-bind="attributes" :href="Router.generate(route, {...routeParams, id: value.id})">{{ text }}</a>
    <span v-else-if="value" v-bind="attributes">{{ text }}</span>
</template>

<script setup>
import Router from "@/App/Router";
import {computed, toRefs} from "vue";

const props = defineProps(['value', 'route', 'routeParams', 'textFields']);
const {value, route, routeParams, textFields} = toRefs(props);

const attributes = computed(() => {
    return {
        class: value.value && value.value.couleur ? 'badge badge-primary' : null,
        style: value.value && value.value.couleur ? 'background: '+value.value.couleur+';' : null,
    };
});
const text = computed(() => {
    if(textFields.value) {
        return textFields.value.map((field) => value.value[field]).join(' ');
    }

    return value.value.text;
});
</script>
