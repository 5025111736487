<template>
    <span v-if="timer !== null">
        {{ timer }}
    </span>
</template>

<script>
    import moment from 'moment';

    export default {
        props: ['value'],
        data() {
            return {
                timer: null,
            };
        },
        mounted() {
            setInterval(this.updateTimer, 1000);
            this.updateTimer();
        },
        computed: {
            date() {
                if(this.value) {
                    return moment(this.value, 'DD/MM/YYYY HH:mm:ss');
                }

                return null;
            },
        },
        methods: {
            updateTimer() {
                if(this.date) {
                    const diff = this.date.diff(moment());
                    const duration = moment.duration(diff).abs();
                    let timer = (''+Math.floor(duration.minutes())).padStart(2, '0');
                    if (duration.asHours() >= 1) {
                        timer = (''+Math.floor(duration.asHours())).padStart(2, '0')+':'+timer;
                    } else {
                        timer = timer+':'+(''+Math.floor(duration.seconds())).padStart(2, '0');
                    }

                    this.timer = timer;
                } else {
                    this.timer = null;
                }
            }
        }
    }
</script>
