<template>
    <ApiPopup name="SEFi" :title="title">
        <template #title>
            <span class="badge badge-success mr-3">SEFi</span> {{ title }}
        </template>
        <template #header>
            <b-btn variant="secondary" size="sm" title="Accéder à la documentation" target="_blank" :href="Router.generate('ressources.documentation.chapitre', {chapitre: 'sefi'})+(error.code ? '#sefi-liste-des-erreurs-sefi-'+(true ? 'transport' : 'taxi')+'-'+error.code.toUpperCase() : '')" v-if="error && error.type === 'sefi'">
                <i class="fa-fw fa-regular fa-circle-question"></i>
            </b-btn>
            <template v-if="showForm">
                <b-btn variant="secondary" size="sm" id="inputs" :disabled="loading" class="mr-2">
                    <i class="fa-solid fa-fw fa-list"></i>
                </b-btn>
                <Popover target="inputs" placement="bottom-end" title="Données" @hide="emit('refresh')" :offset="46">
                    <slot name="form"></slot>
                </Popover>
            </template>
            <b-btn variant="secondary" size="sm" @click="emit('refresh')" :disabled="loading" v-if="showRefresh">
                <i class="fa-solid fa-fw fa-arrows-rotate"></i>
            </b-btn>
        </template>
        <template #content>
            <template v-if="loading || (error !== null && !showInitForm)">
                <Loader :error="loading ? null : error"></Loader>
            </template>
            <slot name="initForm" v-else-if="showInitForm"></slot>
            <slot name="result" v-else-if="result !== null"></slot>
        </template>
        <template #footer>
            <slot name="initFormButtons" v-if="showInitForm && !loading"></slot>
            <slot name="resultButtons" v-else-if="!loading && null === error && result !== null"></slot>
        </template>
    </ApiPopup>
</template>

<script setup>
import Router from "@/App/Router";
import {computed, toRefs} from "vue";
import ApiPopup from '@/Vue/Components/Api/Popup';
import Loader from '@/Vue/Components/Sefi/Loader';
import Popover from '@/Vue/Components/Popover';

const emit = defineEmits(['refresh']);
const props = defineProps(['title', 'loading', 'error', 'result', 'noForm', 'noRefresh', 'initForm']);
const {title, loading, error, result, noForm, noRefresh, initForm} = toRefs(props);

const showInitForm = computed(() => initForm.value && null === result.value && (null === error.value || error.value.type === 'form'));
const showRefresh = computed(() => !noRefresh.value && !showInitForm.value);
const showForm = computed(() => !noForm.value && !showInitForm.value);
</script>
