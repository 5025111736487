<template>
    <b-pagination
        pills
        size="lg"
        :value="value"
        @input="emit('input', $event)"
        :total-rows="pieceStack.length"
        :per-page="1"
        :hide-goto-end-buttons="true"
        :hide-ellipsis="true"
        page-class="piece-viewer-page"
        :limit="3"
        :disabled="piece._loading"
    >
        <template #page="{ page, active }">
            <div class="text-ellipsis width-75px" :title="pieceStack[page-1].libelle">
                {{ pieceStack[page-1].libelle }}
            </div>
            <div v-if="page !== value" class="piece-viewer-page-background" :style="'background-image: url('+Router.generate('shared.piece.apercu', {id: pieceStack[page-1].id})+')'"></div>
        </template>
    </b-pagination>
</template>

<script setup>
import Router from "@/App/Router";

const emit = defineEmits(['input'])

const props = defineProps({
    piece: Object,
    pieceStack: Array,
    value: Number,
});
</script>
