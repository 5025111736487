'use strict';

import {createApp} from 'vue'
import Cookies from "js-cookie";
import Moment from 'moment';
import swal from 'sweetalert2';
import Router from '@/App/Router';
import Request from '@/App/Request';
import Duree from "@/Vue/Filters/Duree";
import Translator from "@/App/Translator";

App.Administration.Periodic = class {};

App.Administration.Periodic.Index = class {

    constructor(params) {
        this.params = params;

        const filter = Cookies.getJSON('ambuerp_administration_periodic_filter') || {
            status: ['running', 'pending', 'unregistered', 'stopped'],
        };

        createApp({
            delimiters: ['[[', ']]'],
            data: () => ({
                searchQuery: '',
                filter,
                queueCollection: params.data,
                isRestarting: false,
                filterStatusChoices: {
                    'running': 'Running',
                    'pending': 'Pending',
                    'unregistered': 'Unregistered',
                    'stopped': 'Stopped'
                },
                now: ((new Date()).getTime() / 1000) >> 0,
                refresh: 1000,
                memory: {},
                periodicCollection: [],
            }),
            mounted() {
                document.dispatchEvent(new Event('app:vuejs:mounted'));

                setInterval(() => {
                    this.now = ((new Date()).getTime() / 1000) >> 0
                }, 1000);

                setInterval(() => {
                    this.sync();
                }, this.refresh);
                this.sync();
            },
            watch: {
                queueCollection() {
                    this.periodicCollection = this.computePeriodicCollection();
                }
            },
            methods: {
                computePeriodicCollection() {
                    let result = [];

                    for (let queue of this.queueCollection) {
                        let queueClone = JSON.parse(JSON.stringify(queue));

                        const periodics = queueClone.periodicCollection;
                        queueClone.periodicCollection = [];

                        for (let periodic of periodics) {
                            for (let field in this.filter) {
                                let value = this.filter[field];
                                let lastTick = null;

                                if (!value.includes(periodic[field])) {
                                    continue;
                                }

                                if (this.searchQuery !== '') {
                                    if (!periodic.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                                        continue;
                                    }
                                }

                                periodic.percent = null;
                                periodic.late = 0;

                                if ('running' === periodic.status) {
                                    periodic.percent = 100;
                                } else if ('pending' === periodic.status && (periodic.lastTick || queueClone.startedAt)) {
                                    lastTick = Moment(periodic.lastTick ? periodic.lastTick : queueClone.startedAt, 'DD/MM/YYYY HH:mm:ss').format('X') >> 0;

                                    if (this.memory && this.memory[periodic.id] && this.memory[periodic.id].wasPending && this.memory[periodic.id].lastTick !== lastTick) {
                                        periodic.status = 'running';
                                        periodic.percent = 100;
                                    } else if (lastTick === this.now) {
                                        periodic.percent = 0;
                                    } else if (lastTick + periodic.interval <= this.now) {
                                        const late = this.now - lastTick - periodic.interval;

                                        periodic.percent = 100;
                                        periodic.late = late <= (this.refresh / 1000) + 1 ? 0 : late;
                                    } else {
                                        periodic.percent = ((100 / (periodic.interval - 1)) * (this.now - lastTick - 1)) >> 0;
                                    }

                                }

                                this.memory[periodic.id] = {
                                    wasPending: 'pending' === periodic.status,
                                    lastTick,
                                };

                                queueClone.periodicCollection.push(periodic);
                            }
                        }

                        if (!queueClone.periodicCollection.length) {
                            continue;
                        }

                        result.push(queueClone);
                    }

                    return result;
                },
                Duree,
                sync() {
                    Request.postJson(Router.generate('administration.periodic.ajax.data')).then((data) => {
                        this.queueCollection = data;
                    });
                },
                statusClasses: function (object) {
                    const base = 'badge ';

                    switch (object.status) {
                        case 'running':
                            return base + ' badge-success';
                        case 'pending':
                            return base + ' badge-secondary';
                        case 'unregistered':
                            return base + ' badge-light';
                        case 'stopped':
                            return base + ' badge-danger';
                        case 'started':
                            return base + ' badge-info'
                    }

                    return base;
                },
                statusBadge: function (object) {
                    return '<span class="'+this.statusClasses(object)+'">' + object.status + '</span>';
                },
                restartAll: function () {
                    swal({
                        type: 'warning',
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonClass: 'bg-danger',
                        confirmButtonText: Translator.trans('action.redemarrer'),
                        text: Translator.trans('administration.periodic.restart.confirm')
                    }).then((result) => {
                        if (result.value) {
                            this.isRestarting = true;

                            Request.getJson(Router.generate('administration.periodic.ajax.restart')).then((data) => {
                                App.Notification[data.status](data.message);
                            }).finally(() => {
                                this.isRestarting = false;
                            });
                        }
                    });
                }
            }
        }).mount('#wrapper');
    }
}