<template>
    <div :id="id">
        <div v-if="loading" class="p-4">
            <div class="p-2">
                <b-spinner class="mr-2"></b-spinner> Chargement…
            </div>
        </div>
        <template v-else>
            <div class="piece-container" v-selectable="{ selectedGetter, selectedSetter, selectingSetter }" data-items=".piece" ref="vsel">
                <div class="selection" :class="{ 'visibility-hidden': !selecting || previewPiece }"></div>
                <div v-for="(fullGroup, groupIndex) in fullGroups">
                    <div class="section-title mt-5 mb-3" v-if="fullGroup.group.name">
                        <span class="text-normal">{{ fullGroup.group.name }}</span>
                        <span class="ml-2"><template v-if="fullGroup.filteredPieces.length !== fullGroup.group.pieces.length">{{ fullGroup.filteredPieces.length }} / </template>{{ fullGroup.group.pieces.length }}</span>
                    </div>
                    <div class="piece-grid" v-if="fullGroup.paginatedPieces.length">
                        <div v-for="piece in fullGroup.paginatedPieces" :key="piece.id" class="piece" :data-group="groupIndex" :data-id="piece.id" :class="{'piece-selected': selectedPieces.includes(piece), 'piece-selecting': selectingPieces.includes(piece)}" @dblclick="openPiece(piece)">
                            <div class="piece-file">
                                <div class="piece-file-preview" :style="'background-image: url('+Router.generate('shared.piece.apercu', {id: piece.id})+')'"></div>
                                <div class="piece-file-corner">
                                    <div>
                                        <i class="fa-solid fa-cloud-plus text-theme" v-if="getPieceScorForPiece(piece)"></i>
                                        <i class="fa-solid fa-cloud-check text-success" v-else-if="piece.pieceScorCollection.filter(pieceScor2 => pieceScor2.lot).length"></i>
                                        <i class="fa-solid fa-check text-success" v-else-if="piece.estControle"></i>
                                        <i class="fa-solid fa-eye text-danger" v-else></i>
                                    </div>
                                </div>
                                <div class="piece-file-type">{{ piece.document.extension }}</div>
                                <div class="piece-file-size">{{ piece.document.humanFilesize }}</div>
                                <div class="piece-file-loader" v-if="piece._loading">
                                    <span class="spinner-border" role="status"></span>
                                </div>
                            </div>
                            <div class="piece-name">
                                <span v-if="piece.type" class="badge badge-secondary" :title="piece.type.libelle">{{ piece.type.abreviation }}</span>
                                <span v-else class="badge badge-danger">Inconnu</span>
                                <span :title="piece.libelle">{{ piece.libelle }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex justify-content-center">
                        <div class="piece-empty">{{ Translator.trans('libelle.aucun-element') }}</div>
                    </div>
                    <table-pagination class="mt-2" :count="fullGroup.filteredPieces.length" :per-page="piecePerPage" v-model="pageByGroup[groupIndex]" :no-text="true"></table-pagination>
                </div>
            </div>
        </template>
        <piece-viewer :index="index" :structures="structures" :piece="previewPiece" :piece-stack="previewStack" @piece="openPiece($event, previewStack)" @scor="emit('scor', $event)" :piece-scor="getPieceScorForPiece(previewPiece)" :update-url="updateUrl" :batch-delete-url="batchDeleteUrl" :types="types" :type-presets="typePresets" :liens="liens" @update="handleUpdate" :standard="standard"></piece-viewer>
    </div>
</template>

<script setup>
import * as selectable from 'vue-selectable';
import Router from "@/App/Router";
import swal from "sweetalert2";
import Translator from "@/App/Translator";
import {computed, inject, nextTick, onMounted, onUnmounted, ref, toRefs, watch} from "vue";
import Request from "@/App/Request";

const emit = defineEmits(['selected', 'update', 'scor']);
const props = defineProps(['id', 'groups', 'piecePerPage', 'filter', 'types', 'pieceScor', 'updateUrl', 'batchUpdateUrl', 'batchDeleteUrl', 'typePresets', 'liens', 'standard', 'structures', 'loading', 'hasScor', 'index']);

const {id, structures, groups, piecePerPage, batchUpdateUrl, batchDeleteUrl, types, standard, hasScor, filter} = toRefs(props);

const vsel = ref();
const selected = ref([]);
const selecting = ref([]);
const pageByGroup = ref({});
const changingPage = ref(false);
const contextMenuActive = ref(false);
const previewPiece = ref(null);
const previewStack = ref([]);

const vSelectable = selectable.default;

const contextMenuSelector = computed(() => {
    return (id.value ? ('#'+id.value+' ') : '')+'.piece';
});
const selectedPieces = computed({
    get() {
        return selected.value.map((selected, key) => selected ? visiblePieces.value[key] : null).filter(item => item !== null && item !== undefined);
    },
    set(pieces) {
        selected.value = visiblePieces.value.map(piece => pieces.includes(piece))
    },
});
const selectingPieces = computed(() => selecting.value.map((selecting, key) => selecting ? visiblePieces.value[key] : null).filter(item => item !== null && item !== undefined));
const visiblePieces = computed(() => fullGroups.value.map(fullGroup => fullGroup.paginatedPieces).flat());
const visiblePiecesWithFullGroup = computed(() => fullGroups.value.map(fullGroup => fullGroup.paginatedPieces.map(piece => ({piece, fullGroup}))).flat());
const piecesByIdByGroup = computed(() => groups.value.map(group => group.pieces.reduce((res, item) => (res[item.id] = item, res), {})));
const filteredPieces = computed(() => fullGroups.value.map(fullGroup => fullGroup.filteredPieces).flat());
const allPieces = computed(() => fullGroups.value.map(fullGroup => fullGroup.group.pieces).flat());
const allPiecesById = computed(() => allPieces.value.reduce((res, item) => (res[item.id] = item, res), {}));

const fullGroups = computed(() => {
    return groups.value.map(((group, groupIndex) => {
        const page = pageByGroup.value[groupIndex] || 1;
        const start = (page - 1) * piecePerPage.value;
        const end = start + piecePerPage.value;

        const filteredPieces = group.pieces.filter(piece => {
            if (filter.value) {
                if (filter.value.self && piece.createdBy?.id !== App.Constants.USER_ID) {
                    return false;
                }
                if (filter.value.nonControle && piece.estControle) {
                    return false;
                }
                if (filter.value.type && filter.value.type.length && !filter.value.type.includes(piece.type ? piece.type.id : null)) {
                    return false;
                }
                if (filter.value.search && filter.value.search.length && !piece.libelle.toLowerCase().includes(filter.value.search.toLowerCase())) {
                    return false;
                }
            }

            return true;
        });
        const paginatedPieces = filteredPieces.slice(start, end);

        return {
            group,
            filteredPieces,
            paginatedPieces,
            page,
            maxPage: Math.ceil(filteredPieces.length / piecePerPage.value),
        }
    }));
});
const typesById = computed(() => {
    return types.value.reduce((res, type) => (res[type.id] = type, res), {});
});

const handleUpdate = (data) => {
    emit('update', data);
};

const openPiece = (piece, stack = []) => {
    if(piece && piece._loading) {
        return;
    }

    previewPiece.value = piece;
    if (piece) {
        previewStack.value = stack.length ? stack : filteredPieces.value;
    }
};

const deletePieces = (pieces) => {
    for (const piece of pieces) {
        piece._loading = true;
    }

    const data = {
        update: {
            pieceCollection: pieces.map(piece => piece.id),
        },
    }

    if (structures.value) {
        data.update.structureCollection = structures.value;
    }

    Request.postJson(batchDeleteUrl.value, data).then((data) => {
        handleUpdate(data);
    });
};

const setPiecesType = (pieces, typeId) => {
    for(const piece of pieces) {
        piece._loading = true;
    }

    const data = {
        update: {
            pieceCollection: pieces.map(piece => piece.id),
            data: {
                type: typeId,
            },
        },
    };

    if (structures.value) {
        data.update.structureCollection = structures.value;
    }

    Request.postJson(batchUpdateUrl.value, data).then((data) => {
        handleUpdate(data);
    });
};

const selectedGetter = () => selected.value;

const selectedSetter = (v) => {
    if(!changingPage.value && !previewPiece.value && !contextMenuActive.value) {
        selected.value = v;
    }
};

const selectingSetter = (v) => {
    if(!previewPiece.value && !contextMenuActive.value) {
        selecting.value = v;
    }
};

const getPieceScorForPiece = (piece) => {
    if (standard.value) {
        for (const pieceScor of standard.value.piecesScor) {
            if (pieceScor.piece === piece) {
                return pieceScor;
            }
        }
    }

    return null;
};

const canAddPiecesToScor = inject('canAddPiecesToScor');
const canRemovePiecesToScor = inject('canRemovePiecesToScor');
const addSelectedPiecesToScor = inject('addSelectedPiecesToScor');
const removeSelectedPiecesFromScor = inject('removeSelectedPiecesFromScor');

watch(selectedPieces, () => {
    emit('selected', selectedPieces.value);
});
watch(fullGroups, () => {
    nextTick(() => {
        selectable.setSelectableItems(vsel.value);
        changingPage.value = false;
    });
});
watch(groups, () => {
    if (previewPiece.value) {
        previewPiece.value = allPiecesById.value[previewPiece.value.id] || null;
    }

    previewStack.value = previewStack.value.map(piece => allPiecesById.value[piece.id]).filter(Boolean);
});
watch(previewPiece, () => {
    if (!previewPiece.value) {
        previewStack.value = [];
    }
});

onMounted(() => {
    $.contextMenu({
        selector: contextMenuSelector.value,
        itemClickEvent: 'click',
        build: ($trigger, event) => {
            const piece = piecesByIdByGroup.value[$trigger.data('group')][$trigger.data('id')];

            if(!$trigger.hasClass('piece-selected')) {
                selectedPieces.value = [piece];
            }

            const items = {};
            const disabled = piece._loading;

            items.show = {disabled, name: 'Afficher', icon: 'fa-solid fa-eye', callback: () => openPiece(piece, selectedPieces.value.length > 1 ? selectedPieces.value : filteredPieces.value)};
            items.separator0 = '';
            items.print = {name: Translator.trans('action.imprimer'), icon: 'fa-solid fa-print', callback: () => App.Shared.Piece.print(piece)};
            items.download = {disabled, name: 'Télécharger', icon: 'fa-solid fa-download', callback: () => {
                    // todo handle multiple
                    const win = window.open(Router.generate('shared.piece.download', {id: piece.id}), '_blank');
                    if(win) win.focus();
                }};

            if (batchUpdateUrl.value || (standard.value && hasScor.value)) {
                items.separator1 = '';
            }
            if (batchUpdateUrl.value) {
                items.type = {
                    disabled,
                    name: 'Définir le type',
                    icon: 'fa-solid fa-tag',
                    items: !disabled ? types.value.map(item => ({
                        name: item.libelle,
                        callback: () => {
                            setPiecesType(selectedPieces.value, item.id);
                        }
                    })) : null
                };
            }
            if (standard.value && hasScor.value) {
                if(canAddPiecesToScor(selectedPieces.value)) {
                    items.scor = {name: 'Ajouter à l\'envoi SCOR', icon: 'fa-solid fa-cloud-plus', disabled, callback: () => addSelectedPiecesToScor()};
                } else if(canRemovePiecesToScor(selectedPieces.value)) {
                    items.scor = {disabled, name: 'Retirer de l\'envoi SCOR', icon: 'fa-solid fa-cloud-minus', callback: () => removeSelectedPiecesFromScor()};
                } else {
                    items.scor = {name: 'Ajouter à l\'envoi SCOR', icon: 'fa-solid fa-cloud-plus', disabled: true};
                }
            }
            if (batchDeleteUrl.value) {
                items.separator2 = '';
                items.delete = {
                    disabled: disabled, name: 'Supprimer', icon: 'fa-solid fa-trash-can', callback: () => {
                        swal({
                            title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonClass: 'bg-danger',
                            confirmButtonText: Translator.trans('action.supprimer'),
                            cancelButtonText: Translator.trans('action.annuler')
                        }).then((result) => {
                            if (result.value) {
                                deletePieces(selectedPieces.value);
                            }
                        });
                    }
                };
            }

            return Object.keys(items).length ? {
                items: items
            } : false;
        },
        events: {
            show: () => {
                contextMenuActive.value = true;
            },
            hide: () => {
                contextMenuActive.value = false;
            }
        }
    });

    window.addEventListener('keydown', (e) => {
        if (!previewPiece.value) {
            if ((!$(e.target).is(':input') && e.key === ' ') || e.key === 'Enter') {
                if(!e.repeat) {
                    if (selected.value.length) {
                        openPiece(selectedPieces.value[0], selectedPieces.value.length > 1 ? selectedPieces.value : filteredPieces.value);
                    }
                }

                e.preventDefault();
            }
            else if(e.key.startsWith('Arrow')) {
                const firstSelected = Object.keys(selected.value).find(key => selected.value[key] === true);

                if (firstSelected) {
                    const {piece, fullGroup} = visiblePiecesWithFullGroup.value[firstSelected];
                    const groupIndex = fullGroups.value.indexOf(fullGroup);
                    const pieceIndex = fullGroup.paginatedPieces.indexOf(piece);
                    const groupSize = fullGroup.paginatedPieces.length;
                    const previousFullGroup = groupIndex > 0 ? fullGroups.value[groupIndex - 1] : null;

                    const totalCount = visiblePieces.value.length;
                    let newSelected = parseInt(firstSelected);

                    if(e.key === 'ArrowRight') {
                        if (fullGroup.page < fullGroup.maxPage && pieceIndex === fullGroup.paginatedPieces.length - 1) {
                            pageByGroup.value[groupIndex]++;
                            changingPage.value = true;
                            newSelected = 0;
                        } else {
                            newSelected++;
                        }
                    }
                    else if(e.key === 'ArrowLeft') {
                        if (fullGroup.page > 1 && pieceIndex === 0) {
                            pageByGroup.value[groupIndex]--;
                            changingPage.value = true;
                            newSelected = piecePerPage.value-1;
                        } else {
                            if(previousFullGroup && previousFullGroup.page !== previousFullGroup.maxPage) {
                                pageByGroup.value[groupIndex -1] = previousFullGroup.maxPage;
                                changingPage.value = true;

                                newSelected = visiblePieces.value.indexOf(piece) - 1;
                            } else {
                                newSelected--;
                            }
                        }
                    }
                    else if(e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        const maxRowSize = Math.trunc($('.piece-container').width() / $('.piece').outerWidth(true));

                        if (e.key === 'ArrowUp') {
                            if(pieceIndex < maxRowSize) {
                                if(previousFullGroup) {
                                    const minRowSize = previousFullGroup.paginatedPieces.length % maxRowSize || maxRowSize;

                                    if(pieceIndex < minRowSize) {
                                        newSelected-= minRowSize;
                                    }
                                }
                            } else {
                                newSelected -= maxRowSize;
                            }
                        } else if (e.key === 'ArrowDown') {
                            const minRowSize = fullGroup.paginatedPieces.length % maxRowSize || maxRowSize;

                            if (pieceIndex < groupSize - minRowSize - (maxRowSize - minRowSize) || pieceIndex >= groupSize - minRowSize) {
                                newSelected += pieceIndex < groupSize - minRowSize ? maxRowSize : minRowSize;
                            }
                        }
                    }

                    if(changingPage.value || (newSelected >= 0 && newSelected < totalCount)) {
                        selected.value = [];
                        selected.value[newSelected] = true;
                        e.preventDefault();
                    }
                }
            }
        }
    });
});

onUnmounted(() => {
    $.contextMenu('destroy', contextMenuSelector.value);
});

defineExpose({
    openPiece
});
</script>
