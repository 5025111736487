<template>
    <sefi-popup title="Calcul des montants" :loading="loading" :error="error" :result="result" @refresh="refresh" :no-form="true">
        <template v-slot:result v-if="result">
            <div class="alert alert-success">
                <strong>{{ Translator.trans('flash.success') }}</strong> Les montants calculés ont bien été reportés dans la facture.
            </div>
            <sefi-ft-calculer-montants-prestation-recapitulatif v-if="Object.keys(result).length === 1" :data="total"></sefi-ft-calculer-montants-prestation-recapitulatif>
            <b-tabs align="center" pills v-else>
                <b-tab title="Total" active>
                    <sefi-ft-calculer-montants-prestation-recapitulatif :data="total"></sefi-ft-calculer-montants-prestation-recapitulatif>
                </b-tab>
                <b-tab :title="'Trajet n°'+(index+1)" v-for="trajet, index in Object.values(result)" :key="index">
                    <sefi-ft-calculer-montants-prestation-recapitulatif :data="trajet"></sefi-ft-calculer-montants-prestation-recapitulatif>
                </b-tab>
            </b-tabs>
        </template>
    </sefi-popup>
</template>

<script>
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import Request from "@/App/Request";

export default {
    props: ['data'],
    data() {
        return {
            result: null,
            loading: false,
            error: null,
            Translator,
        };
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.error = null;
            this.loading = true;

            const factureData = $('#form').serializeObject();

            const route = this.data && this.data.id ? Router.generate('facturation.facture.edit.ajax', {id: this.data.id}) : Router.generate('facturation.facture.new.ajax', {type: 'standard'});

            Request.postJson(route, {
                ...factureData,
                trajets: {
                    sefi: true,
                }
            }).then((data) => {
                if(data.success) {
                    console.log(data);
                    this.result = data.data;

                    App.module.update(data);
                } else {
                    this.error = data.error;
                }

                this.loading = false;
            });
        },
        valider() {
            if(!this.result.success) {
                return;
            }

            $('#facture_estValidee').val(true);
            $('#facture_sefiClause').val(this.result.clause);
            $('#facture_sefiCertificat').val(this.result.certificat);
            $('#form').submit();
        }
    },
    computed: {
        total() {
            const total = {};

            for(const index in this.result) {
                const trajet = this.result[index];

                for(const field in trajet) {
                    if(field in total) {
                        if (typeof trajet[field] === 'object') {
                            total[field].quantite += trajet[field].quantite;
                            total[field].montant = parseFloat(total[field].montant) + parseFloat(trajet[field].montant);
                        } else {
                            total[field] = parseFloat(total[field]) + parseFloat(trajet[field]);
                        }
                    } else {
                        if (typeof trajet[field] === 'object') {
                            total[field] = {...trajet[field]};
                        } else {
                            total[field] = trajet[field];
                        }
                    }
                }
            }

            return total;
        }
    }
}
</script>
