<template>
    <div class="d-flex flex-column overflow-hidden" style="height: calc(100vh - 104px);">
        <h3 class="title-heading" v-if="trajet">{{ Translator.trans('chapitre.trajet-selectionne') }}</h3>
        <div class="js-perfect-scrollbar">
            <template v-if="trajet">
            <div>
                <div v-if="trajet.estAnnule" class="bg-danger text-white text-center p-2">
                    <i class="fa-regular fa-circle-xmark"></i> {{ Translator.trans('regulation.trajet.etat.libelleComplet.annule') }}
                </div>
                <div v-else-if="trajet.estAFaire" class="bg-warning text-white text-center p-2">
                    <i class="fa-regular fa-pen-to-square"></i> {{ Translator.trans('regulation.trajet.etat.libelleComplet.a-faire') }}
                </div>
                <div v-else-if="trajet.estPret" class="bg-info text-white text-center p-2">
                    <i class="fa-solid fa-check"></i> {{ Translator.trans('regulation.trajet.etat.libelleComplet.pret') }}
                </div>
                <div v-else-if="trajet.estEnCours" class="bg-info text-white text-center p-2">
                    <i class="fa-solid fa-hourglass"></i> {{ Translator.trans('regulation.trajet.etat.libelleComplet.enCours') }}
                </div>
                <div v-else-if="trajet.estTermine" class="bg-success text-white text-center p-2">
                    <i class="fa-solid fa-check"></i> {{ Translator.trans('regulation.trajet.etat.libelleComplet.termine') }}
                </div>
            </div>
            <TrajetMap height="180px"
                        :structures="structures"
                        :latitude-degre-prise-en-charge="trajet.latitudeDegrePriseEnCharge"
                        :longitude-degre-prise-en-charge="trajet.longitudeDegrePriseEnCharge"
                        :latitude-degre-destination="trajet.latitudeDegreDestination"
                        :longitude-degre-destination="trajet.longitudeDegreDestination"
                        @select-vehicule="emit('map-select-vehicule', {trajet, id: $event})"
            ></TrajetMap>
            <div>
                <div class="d-flex align-items-center py-4">
                    <TrajetMiniBlock type="aller" :trajet="trajet"></TrajetMiniBlock>
                    <i class="fa-solid fa-chevron-right text-secondary fa-2x ml-1" style="color: rgb(243, 243, 243);"></i>
                    <TrajetMiniBlock type="retour" :trajet="trajet"></TrajetMiniBlock>
                </div>
                <TrajetTimeline :data="trajet.timeline" class="mx-4 mb-4"></TrajetTimeline>
                <h3 class="title-heading">{{ Translator.trans('chapitre.informations') }}</h3>
                <div class="px-3">
                    <div class="row view-row no-border">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.bureau') }}</div>
                        <div class="col-8 col-sm-8">
                            <a v-if="trajet.roles.ROLE_REFERENTIEL_STRUCTURE_VIEW" :class="{'badge badge-block badge-secondary label-outline': true}" :href="Router.generate('referentiel.structure.view', {id: trajet.structure.id})">{{ trajet.structure.text }}</a>
                            <template v-else>{{ trajet.structure.text }}</template>
                        </div>
                    </div>
                    <div class="row view-row" v-if="trajet.transport.identiteTemporaire">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.identiteTemporaire') }}</div>
                        <div class="col-8 col-sm-8">{{ trajet.transport.identiteTemporaire }}</div>
                    </div>

                    <template v-for="type in ['assure', 'beneficiaire']">
                        <div class="row view-row" v-if="trajet.transport.patient[type]">
                            <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.'+type) }}</div>
                            <div class="col-8 col-sm-8">
                                <a v-if="trajet.roles.ROLE_REGULATION_PATIENT_VIEW" :href="Router.generate('shared.patient.view', {app: 'regulation', id: trajet.transport.patient[type].id})">{{ trajet.transport.patient[type].text }}</a>
                                <template v-else>{{ trajet.transport.patient[type].text }}</template>
                            </div>
                        </div>
                        <div class="row view-row" v-if="trajet.transport.patient[type] && trajet.transport.patient[type].note">
                            <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.note-'+type) }}</div>
                            <div class="col-8 col-sm-8 text-break white-space-pre-wrap">{{ trajet.transport.patient[type].note }}</div>
                        </div>
                        <div class="row view-row" v-if="trajet.transport.patient[type] && trajet.transport.patient[type].notePublique">
                            <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.note-publique-'+type) }}</div>
                            <div class="col-8 col-sm-8 text-break white-space-pre-wrap">{{ trajet.transport.patient[type].notePublique }}</div>
                        </div>
                    </template>
                    <div class="row view-row">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.categorie') }}</div>
                        <div class="col-8 col-sm-8"><trajet-categorie :trajet="trajet" :full="true"></trajet-categorie></div>
                    </div>
                    <div class="row view-row">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.distance') }}</div>
                        <div class="col-8 col-sm-8">
                            <template v-if="trajet.kilometre">
                                {{ trajet.kilometre }} km
                            </template>
                        </div>
                    </div>
                    <div class="row view-row">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.interfaceMobileMissionId') }}</div>
                        <div class="col-8 col-sm-8">{{ trajet.interfaceMobileMissionId }}</div>
                    </div>
                    <div class="row view-row" v-if="trajet.transport.contrat">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.contrat') }}</div>
                        <div class="col-8 col-sm-8">{{ trajet.transport.contrat.libelle }}</div>
                    </div>
                    <div class="row view-row" v-if="trajet.serie">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.serie') }}</div>
                        <div class="col-8 col-sm-8">
                            <a v-if="trajet.roles.ROLE_REGULATION_SERIE_VIEW" :href="Router.generate('shared.serie.view', {app: 'regulation', id: trajet.serie.id})">{{ trajet.serie.libelle }}</a>
                            <template v-else>{{ trajet.serie.libelle }}</template>
                        </div>
                    </div>
                    <div class="row view-row" v-if="trajet.tournee">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.tournee') }}</div>
                        <div class="col-8 col-sm-8">
                            <a v-if="trajet.roles.ROLE_REGULATION_TOURNEE_VIEW" :href="Router.generate('shared.tournee.view', {app: 'regulation', id: trajet.tournee.id})">{{ trajet.tournee.libelle }}</a>
                            <template v-else>{{ trajet.tournee.libelle }}</template>
                        </div>
                    </div>
                    <div class="row view-row" v-if="trajet.transport.note">
                        <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.note-transport') }}</div>
                        <div class="col-8 col-sm-8 text-break white-space-pre-wrap">{{ trajet.transport.note }}</div>
                    </div>
                    <div class="row view-row" v-if="trajet.montantFactureTouteTaxe !== null">
                        <div class="col-4 col-sm-4 view-label" :title="Translator.trans('libelle.montant-trajet-ttc')">{{ Translator.trans('libelle.montant') }}</div>
                        <div class="col-8 col-sm-8">{{ Euro(trajet.montantFactureTouteTaxe) }}</div>
                    </div>
                    <div class="row view-row" v-else-if="trajet.estimationMontantTouteTaxe !== null">
                        <div class="col-4 col-sm-4 view-label" :title="Translator.trans('libelle.estimationMontantTouteTaxe')">{{ Translator.trans('libelle.montant') }}</div>
                        <div class="col-8 col-sm-8 text-muted">~ {{ Euro(trajet.estimationMontantTouteTaxe) }}</div>
                    </div>
                    <template v-if="trajet.estAnnule">
                        <div class="row view-row" v-if="trajet._motifAnnulation">
                            <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.motif-annulation') }}</div>
                            <div class="col-8 col-sm-8 text-break">{{ trajet._motifAnnulation.libelle }}</div>
                        </div>
                        <div class="row view-row" v-if="trajet.commentaireAnnulation !== null">
                            <div class="col-4 col-sm-4 view-label">{{ Translator.trans('libelle.commentaire-annulation') }}</div>
                            <div class="col-8 col-sm-8 text-break white-space-pre-wrap">{{ trajet.commentaireAnnulation }}</div>
                        </div>
                    </template>
                </div>
            </div>
            </template>
            <div v-else class="text-center pt-5 mt-5">
                <i class="fa-4x im-select2"></i>
                <h3>{{ Translator.trans('regulation.trajet.empty.aucun-trajet') }}</h3>
                <p class="text-muted">{{ Translator.trans('regulation.trajet.empty.selectionner-trajet') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import Translator from "@/App/Translator";
import {toRefs} from "vue";
import Euro from "@/Vue/Filters/Euro";
import TrajetMap from '@/Vue/Components/Trajet/Map';
import TrajetMiniBlock from '@/Vue/Components/Trajet/MiniBlock';
import TrajetTimeline from '@/Vue/Components/Trajet/Timeline';
import Router from "@/App/Router";

const emit = defineEmits(['map-select-vehicule'])
const props = defineProps({
    trajet: Object,
    structures: Array,
})
const {trajet} = toRefs(props);
</script>
