<template>
    <a class="trajet-mini-block flex-1 mx-4 text-center text-dark" :class="{'disabled': disabled, 'border-info': trajetBlock && trajetBlock.id === trajet.id}" @click="select($event)" :href="trajetBlock ? '#' : editUrl">
        <div class="text-muted font-size-11px text-uppercase" :title="disabled ? 'Non visible dans la liste' : ''">{{ type }}</div>
        <div v-if="trajetBlock">
            <trajet-etat :trajet="trajetBlock"></trajet-etat>
            <span :title="trajetBlock.rendezVous.date">{{ trajetBlock.rendezVous.time }}</span>

        </div>
        <div v-else><i class="fa-solid fa-plus text-muted"></i> Ajouter</div>
    </a>
</template>

<script setup>
import Router from "@/App/Router";
import {computed, toRefs, inject} from "vue";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";

const regulationStore = useRegulationStore();

const selectTrajetById = inject('selectTrajetById');
const {trajetIndexById} = storeToRefs(regulationStore);

const props = defineProps({
    trajet: Object,
    type: String,
});

const {trajet, type} = toRefs(props);

const trajetBlock = computed(() => {
    if(type.value === trajet.value.type) {
        return trajet.value;
    }

    return trajet.value[type.value];
});
const disabled = computed(() => trajetBlock.value && !(trajetBlock.value.id in trajetIndexById.value));
const editUrl = computed(() => Router.generate('shared.transport.edit', {app: 'regulation', id: trajet.value.transport.id, addRetour: true}));

const select = (event) => {
    if(trajetBlock.value) {
        if(!disabled.value) {
            selectTrajetById(trajetBlock.value.id, true);
        }

        event.preventDefault();
    }
};

</script>