'use strict';

import {createApp} from 'vue'
import * as selectable from 'vue-selectable';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import Ladda from 'ladda';
import Translator from "@/App/Translator";
import {usePlanningStore} from "@/Vue/Stores/Planning";
import {mapStores} from "pinia";
import Request from '@/App/Request';
import {useRequest} from "@/Vue/Composables/Request";

App.Salaries.Planning = class {
    constructor(params) {
        const moment = extendMoment(Moment);
        moment.locale('fr');

        const element = document.getElementById('app')

        createApp({
            delimiters: ['[[', ']]'],
            props: ['color'],
            setup: () => {
                const {postJson} = useRequest();

                return {postJson};
            },
            data: () => ({
                initStructures: false,
                initPersonnels: false,
                tool: 'select',
                types: [],
                categories: [],
                filteredTypesIds: [],
                currentType: null,
                periode: {
                    debut: null,
                    fin: null,
                },
                mode: 'month',
                modeGlissant: false,
                selected: [],
                selecting: [],
                hoverIndexes: {i: null, j: null},
                mouseDown: false,
                clipboard: {},
                editEnabled: false,
                periodeAjax: null,
                structures: [],
                personnelsFilter: [],
                indicateursFilter: [],
                saving: false,
                editLoading: false,
                contextMenuActive: false,
                isOpen: false,
                hasEditedEvents: false,
                publicationHover: null,
                publicationDragging: false,
                validationHover: null,
                validationDragging: false,
                isLoading: false,
                hideGlow: false,
                historyVersions: undefined,
            }),
            watch: {
                types(newTypes, oldTypes) {
                    if(JSON.stringify(newTypes.type) !== JSON.stringify(oldTypes.type)) {
                        this.filteredTypesIds = [];
                    }
                },
                editEnabled: function(edit) {
                    $('#form_structure').toggleClass('disabled', edit);
                    $('#form_personnelCollection').prop('disabled', edit);
                    this.hasEditedEvents = false;
                },
                tool: function(tool) {
                    if(tool === 'select') {
                        this.currentType = null;
                    } else {
                        this.selected = [];
                        this.selecting = [];
                    }
                },
                currentType: function(type) {
                    if(type) {
                        this.tool = 'insert';
                    }
                },
                mode: function (mode) {
                    if(mode === 'custom') {
                        this.modeGlissant = false;
                    } else {
                        this.resetPeriode();
                    }
                },
                modeGlissant: function(glissant) {
                    if(!glissant && this.mode !== 'custom') {
                        this.resetPeriode();
                    }
                },
                periode: {
                    handler: function(periode) {
                        this.$nextTick(() => selectable.setSelectableItems(this.$refs.vsel));
                        this.periodeAjax = {
                            debut: periode.debut.format('DD/MM/YYYY'),
                            fin: periode.fin.format('DD/MM/YYYY'),
                        };
                    },
                    deep: true,
                },
                periodeAjax: function(periode, oldPeriode) {
                    if(oldPeriode && JSON.stringify(periode) !== JSON.stringify(oldPeriode)) {
                        this.reload();
                    }
                },
                structures: function() {
                    this.onFormChange();
                    this.initStructures = true;
                },
                personnelsFilter: function() {
                    this.onFormChange();
                    this.initPersonnels = true;
                },
                filteredPersonnels() {
                    this.$nextTick(() => selectable.setSelectableItems(this.$refs.vsel));
                },
                clipboard(cp) {
                    if (Object.keys(cp).length) {
                        this.hideGlow = false;
                    }
                },
            },
            computed: {
                ...mapStores(usePlanningStore),
                typeGroups() {
                    const groups = [];

                    for (const categorie of this.categories) {
                        const types = this.modelesByCategorie[categorie.id];

                        if (types) {
                            groups.push({
                                text: categorie.libelle,
                                types,
                            });
                        }
                    }

                    const others = this.modelesByCategorie[null];

                    if (others) {
                        const travail = others.filter(item => item.estTravailAbstract);

                        if (travail.length) {
                            groups.push({
                                text: 'Travail',
                                types: travail,
                            });
                        }

                        const conge = others.filter(item => item.estConge);

                        if (conge.length) {
                            groups.push({
                                text: 'Congé',
                                types: conge,
                            });
                        }

                        const repos = others.filter(item => item.estRepos);

                        if (repos.length) {
                            groups.push({
                                text: 'Repos',
                                types: repos,
                            });
                        }
                    }

                    return groups;
                },
                modelesByCategorie() {
                    return (this.types.type || []).reduce((res, item) => ((res[item.categorie] = res[item.categorie] || []).push(item), res), {});
                },
                countTypeHoraireList() {
                    return this.daysWithEvents.map(dayWithEvent => {
                        let countTypeHoraireList = [];
                        for (let i in dayWithEvent.events) {
                            const event = dayWithEvent.events[i];
                            if (event) {
                                const currentTypeObject = {
                                    type: {
                                        code: event.code,
                                        libelle: event.libelle,
                                        couleur: event.couleur,
                                        couleurTexte: event.couleurTexte,
                                    },
                                    count: 1,
                                };

                                const index = countTypeHoraireList.findIndex(function(item) {
                                    return item.type.code === currentTypeObject.type.code
                                        && item.type.libelle === currentTypeObject.type.libelle
                                        && item.type.couleur === currentTypeObject.type.couleur
                                });
                                if (index !== -1) {
                                    countTypeHoraireList[index].count++;
                                } else {
                                    countTypeHoraireList.push(currentTypeObject);
                                }
                            }
                        }

                        countTypeHoraireList.sort((a, b) => (a.count < b.count) ? 1 : -1);

                        return countTypeHoraireList;
                    });
                },
                debutInput: {
                    get: function() {
                        return moment(this.periode.debut).format('DD/MM/YYYY');
                    },
                    set: function(value) {
                        this.dateInputSetter(value, this.periode.fin)
                    }
                },
                finInput: {
                    get: function() {
                        return moment(this.periode.fin).format('DD/MM/YYYY');
                    },
                    set: function(value) {
                        this.dateInputSetter(value, this.periode.debut)
                    }
                },
                datePeriode: function() {
                    let dateDebut = this.periode.debut;
                    let dateFin = this.periode.fin;

                    let str = dateDebut.format('MMMM');

                    if(dateDebut.format('MMMM YYYY') !== dateFin.format('MMMM YYYY')) {
                        if (dateDebut.format('YYYY') !== dateFin.format('YYYY')) {
                            str += ' ' + dateDebut.format('YYYY')
                        }

                        str += ' - ' + dateFin.format('MMMM');
                    }

                    str += ' ' + dateFin.format('YYYY');

                    return str;
                },
                personnels: function() {
                    return this.planningStore.personnels;
                },
                personnelCount: function() {
                    return Object.keys(this.filteredPersonnels).length;
                },
                days: function() {
                    let now = moment();
                    let range = moment.range(this.periode.debut, this.periode.fin);

                    return Array.from(range.by('days')).map((date) => {
                        return {
                            id: date.format('YYYY-MM-DD'),
                            weekend: [6,7].includes(date.isoWeekday()),
                            today: now.isSame(date, 'day'),
                            date,
                        };
                    });
                },
                filteredPersonnels() {
                    return this.personnels.filter(personnel => {
                        if(!this.filteredTypesIds.length && !this.indicateursFilter.length) {
                            return true;
                        }

                        if(this.indicateursFilter.length && !this.indicateursFilter.map(item => item.id).some(id => personnel.indicateurs.map(indicateur => indicateur.id).includes(id))) {
                            return false;
                        }

                        if(this.filteredTypesIds.length) {
                            for(const id in personnel.evenements) {
                                const event = personnel.evenements[id];

                                if(this.filteredTypesIds.includes(event.modeleTypeId)) {
                                    return true;
                                }
                            }

                            return false;
                        }

                        return true;
                    });
                },
                premierPersonnelInactif() {
                    for (const personnel of this.filteredPersonnels) {
                        if (!personnel.estActif) {
                            return personnel;
                        }
                    }

                    return null;
                },
                daysWithEvents: function() {
                    return this.days.map((day) => {
                        let events = {};
                        for(let i in this.filteredPersonnels) {
                            events[i] = this.filteredPersonnels[i].evenements[day.id];
                        }

                        day.events = events;

                        return day;
                    });
                },
                typeHoraireCount: function() {
                    return this.daysWithEvents.map(item => Object.values(item.events).filter(Boolean).length);
                }
            },
            created() {
                window.addEventListener('keydown', (e) => {
                    if(!this.editEnabled || this.isOpen) {
                        return;
                    }

                    if (e.key === 'Delete') {
                        this.removeSelected();
                    }
                    else if(e.key.startsWith('Arrow')) {
                        let totalCount = Object.keys(this.days).length * this.personnelCount;
                        let firstSelected = Object.keys(this.selected).find(key => this.selected[key] === true);
                        if (firstSelected) {
                            let newSelected = parseInt(firstSelected);
                            let delta = firstSelected % this.personnelCount;

                            if(e.key === 'ArrowRight') {
                                newSelected += this.personnelCount;
                            }
                            else if(e.key === 'ArrowLeft') {
                                newSelected -= this.personnelCount;
                            }
                            else if(e.key === 'ArrowUp') {
                                newSelected -= delta > 0 ? 1 : 0;
                            }
                            else if(e.key === 'ArrowDown') {
                                newSelected += delta < this.personnelCount - 1 ? 1 : 0;
                            }

                            if(newSelected >= 0 && newSelected < totalCount) {
                                this.selected = {[newSelected]: true};
                                e.preventDefault();
                            }
                        }
                    } else if (e.key === 'Escape' && !this.isOpen) {
                        if ('insert' === this.tool) {
                            this.tool = 'select';
                        }
                        else {
                            if (Object.keys(this.clipboard).length) {
                                this.hideGlow = true;
                            }
                            this.selected = [];
                        }
                    }

                    let lowerKey = e.key.toLowerCase();

                    if(e.ctrlKey || e.metaKey) {
                        if('z' === lowerKey) {
                            if(e.shiftKey) {
                                if(this.planningStore.canRedo) {
                                    this.planningStore.redo();
                                }
                            } else {
                                if(this.planningStore.canUndo) {
                                    this.planningStore.undo();
                                }
                            }
                        }
                        else if('v' === lowerKey) {
                            this.paste();
                        }
                        else if(lowerKey === 'c') {
                            this.copy();
                        }
                        else if(lowerKey === 'x') {
                            this.cut();
                        }
                    }
                });
                window.addEventListener('mousedown', (e) => {
                    this.mouseDown = true;
                });
                window.addEventListener('mouseup', (e) => {
                    this.mouseDown = false;
                    this.publicationDragging = false;
                    this.validationDragging = false;
                });
            },
            beforeMount: function() {
                this.periode = {
                    debut: moment(element.dataset.debut),
                    fin: moment(element.dataset.fin),
                };
                this.types = JSON.parse(element.dataset.types);
                this.categories = JSON.parse(element.dataset.categories);
                this.evenements = JSON.parse(element.dataset.evenements);
                this.planningStore.personnelsJson = element.dataset.personnels;
                this.planningStore.emptyState();
                this.url = element.dataset.url;
                this.exportUrl = element.dataset.exportUrl;
                this.updateUrl = element.dataset.updateUrl;
                this.viewTypeUrl = element.dataset.viewTypeUrl;
                this.formTypeUrl = element.dataset.formTypeUrl;

                let treeSelectInputSelector = 'input[name="form[structure][]"]';
                $('body').on('change', treeSelectInputSelector, () => {
                    let $checkedInputs = $(treeSelectInputSelector + ':checked');
                    this.structures = $.map($checkedInputs, el =>  el.value);
                }).one('treeselect.init', () => {
                    let $checkedInputs = $(treeSelectInputSelector + ':checked');
                    this.structures = $.map($checkedInputs, el =>  el.value);
                }).on('change', 'select#form_structure', (e) => {
                    this.structures = $(e.currentTarget).val();
                }).on('change', '#form_personnelCollection', (event) => {
                    this.personnelsFilter = $(event.currentTarget).val();
                });

                this.personnelsFilter = $('#form_personnelCollection').val() || [];

                $.contextMenu({
                    selector: '#app .item-event',
                    itemClickEvent: 'click',
                    build: ($trigger, event) => {
                        let items = {};

                        const x = $trigger.index('.item-event');
                        const j = x % this.personnelCount;
                        const personnel = this.filteredPersonnels[j];
                        const events = personnel.evenements;
                        const day = this.days[Math.floor(x/this.personnelCount)].id;

                        if(this.editEnabled && !$trigger.hasClass('ui-selected')) {
                            let selected = {};
                            selected[x] = true;
                            this.selected = selected;
                        }

                        let typeSubMenu = {};
                        for(let type of this.types.type) {
                            typeSubMenu[type.id] = {
                                name: type.libelle, icon: function(opt, $itemElement, itemKey, item) {
                                    $itemElement.html('<span style="vertical-align: middle;border-radius: 10px;background-color: #' + type.couleur + ';height: 16px;width: 25px;margin-left: -20px;margin-right: 5px;display: inline-block;"></span> ' + item.name);
                                }, callback: () => {
                                    this.insert(type);
                                }
                            };
                        }
                        if(!Object.keys(typeSubMenu).length) {
                            typeSubMenu.empty = {icon: 'fa-solid fa-circle-info', name: Translator.trans('libelle.aucun-element'), disabled: true};
                        }


                        if(events[day] && Object.keys(this.selected).filter(key => this.selected[key] === true).length <= 1) {
                            if (this.editEnabled) {
                                delete typeSubMenu[events[day].modeleTypeId];
                                items.edit = {name: Translator.trans('action.modifier'), icon: 'fa-solid fa-pencil', callback: () => {
                                    this.open(events[day]);
                                }};
                                items.replace = {name: 'Remplacer', icon: 'fa-solid fa-right-left', items: typeSubMenu};
                            } else if(params.roles.ROLE_SALARIES_PLANNING_VIEW_DETAIL || (params.roles.ROLE_SALARIES_PLANNING_VIEW_DETAIL_SELF && personnel.id === params.personnelId)) {
                                items.view = {name: Translator.trans('action.voir'), icon: 'fa-solid fa-eye', callback: () => {
                                    this.open(events[day]);
                                }};
                            }
                        } else {
                            if (this.editEnabled) {
                                items.insert = {name: 'Insérer', icon: 'fa-solid fa-plus', items: typeSubMenu};
                            } else {
                                items.empty = {name: 'Aucun évenement', icon: 'fa-solid fa-circle-info', disabled: true};
                            }
                        }

                        if(this.editEnabled) {
                            items.sep2 = '';
                            items.publier = {name: 'Publier jusqu\'à cette date', icon: 'fa-solid fa-share', callback: () => {
                                    this.publier(day);
                                }};
                            items.valider = {name: 'Valider jusqu\'à cette date', icon: 'fa-solid fa-check', callback: () => {
                                    this.valider(day);
                                }};

                            let copyCutDisabled = !Object.keys(this.selected).filter((key) => {
                                let j = key % this.personnelCount;
                                let events = this.filteredPersonnels[j].evenements;
                                let day = this.days[Math.floor(key/this.personnelCount)].id;

                                return this.selected[key] === true && events[day];
                            }).length;

                            items.sep0 = '';
                            items.remove = {name: 'Supprimer', icon: 'fa-regular fa-trash', callback: () => {
                                    this.removeSelected();
                                }, disabled: copyCutDisabled};
                            items.sep1 = '';
                            items.copy = {name: 'Copier', icon: 'fa-regular fa-copy', callback: () => {
                                    this.copy();
                                }, disabled: copyCutDisabled};
                            items.cut = {name: 'Couper', icon: 'fa-solid fa-scissors', callback: () => {
                                    this.cut();
                                }, disabled: copyCutDisabled};
                            items.paste = {name: 'Coller', icon: 'fa-regular fa-clipboard', callback: () => {
                                    this.paste();
                                }, disabled: !Object.keys(this.clipboard).length};
                        }

                        return Object.keys(items).length ? {
                            items: items
                        } : false;
                    },
                    events: {
                        show: () => {
                            this.contextMenuActive = true;
                        },
                        hide: () => {
                            this.contextMenuActive = false;
                        }
                    }
                });
            },
            mounted() {
                document.dispatchEvent(new Event('app:vuejs:mounted'));

                const stopGroupingCb = () => {
                    if (this.editEnabled) {
                        this.planningStore.stopGrouping();
                    }
                }

                window.addEventListener('mouseup', stopGroupingCb);
                window.addEventListener('mouseleave', stopGroupingCb);

            },
            methods: {
                toggleModeleTypeFilter(event, type) {
                    if(this.filteredTypesIds.includes(type.id)) {
                        const index = this.filteredTypesIds.indexOf(type.id);
                        this.filteredTypesIds.splice(index, 1);
                    } else {
                        this.filteredTypesIds.push(type.id);
                    }

                    event.preventDefault();
                },
                onFormChange() {
                    if(!this.initStructures || !this.initPersonnels) {
                        return;
                    }

                    if (!this.structures.length && !this.personnelsFilter.length) {
                        console.log(this.planningStore.personnelsJson);
                        this.types = [];
                        this.categories = [];
                        this.evenements = [];
                        this.planningStore.personnelsJson = JSON.stringify([]);
                        this.planningStore.disableAndClearHistory();
                    }

                    this.reload(true);
                },
                startPublicationDrag() {
                    this.publicationDragging = true;
                    this.selected = [];
                    this.selecting = [];
                },
                hoverPublication(day) {
                    if(this.editEnabled && this.tool === 'select') {
                        this.publicationHover = day;
                    }
                },
                publier(day) {
                    for(let personnel of this.filteredPersonnels) {
                        const user = this.personnels.indexOf(personnel);
                        this.planningStore.setPublication({day, user});
                    }
                },
                startValidationDrag() {
                    this.validationDragging = true;
                    this.selected = [];
                    this.selecting = [];
                },
                hoverValidation(day) {
                    if(this.editEnabled && this.tool === 'select') {
                        this.validationHover = day;
                    }
                },
                valider(day) {
                    for(let personnel of this.filteredPersonnels) {
                        const user = this.personnels.indexOf(personnel);
                        this.planningStore.setValidation({day, user});
                    }
                },
                contextMenu($event) {
                    if(this.tool !== 'select') {
                        $event.stopPropagation();
                        $event.preventDefault();
                    }
                },
                copy(cb = null) {
                    this.clipboard = {};

                    Object.keys(this.selected).filter(key => this.selected[key] === true).forEach((x) => {
                        const j = x % this.personnelCount;
                        const personnel = this.filteredPersonnels[j];
                        const events = personnel.evenements;
                        const dayIndex = Math.floor(x/this.personnelCount);
                        const day = this.days[dayIndex].id;
                        const user = this.personnels.indexOf(personnel);

                        if(events[day]) {
                            this.clipboard[this.personnels.length*dayIndex+user] = events[day];
                            if(cb) {
                                cb({user, day});
                            }
                        }
                    });
                },
                cut() {
                    this.copy((data) => {
                        this.planningStore.cutEvent(data);
                    });
                },
                paste() {
                    const firstSelectedX = Object.keys(this.selected).find(key => this.selected[key] === true);
                    const firstSelectedJ = firstSelectedX % this.personnelCount;
                    const firstSelectedPersonnel = this.filteredPersonnels[firstSelectedJ];
                    const firstSelectedDayIndex = Math.floor(firstSelectedX/this.personnelCount);
                    const firstSelectedUser = this.personnels.indexOf(firstSelectedPersonnel);
                    const firstSelected = this.personnels.length*firstSelectedDayIndex+firstSelectedUser;

                    if(firstSelected && this.clipboard) {
                        const firstClipboard = Object.keys(this.clipboard)[0];

                        for(let i in this.clipboard) {
                            const x = parseInt(i) + parseInt(firstSelected) - parseInt(firstClipboard);
                            const event = JSON.parse(JSON.stringify(this.clipboard[i]));
                            const dayIndex = Math.floor(x/this.personnels.length);
                            const day = this.days[dayIndex];
                            const user = x % this.personnels.length;
                            const personnel = this.personnels[x % this.personnels.length]
                            const oldUser = this.personnels.map(function(p) { return p.id; }).indexOf(event.personnel);
                            event.id = null;
                            event.personnel = personnel.id;
                            delete event.acquittementAt;
                            delete event.acquittementDebutPrevu;
                            delete event.debutReel;
                            delete event.finReel;

                            const baseDate = event.debutPrevu ? Moment(event.debutPrevu.date, 'DD/MM/YYYY') : day;
                            const oldDay = baseDate.format('YYYY-MM-DD');

                            let fixDates = (type) => {
                                for (let field of ['Prevu', 'Reel']) {
                                    const debut = type['debut' + field] ? Moment(type['debut' + field].date, 'DD/MM/YYYY') : null;
                                    const fin = type['fin' + field] ? Moment(type['fin' + field].date, 'DD/MM/YYYY') : null;
                                    let diffDebut = 0;

                                    if (debut && debut.isValid()) {
                                        diffDebut = debut.diff(baseDate, 'day');
                                        type['debut' + field].date = day.date.clone().add(diffDebut, 'day').format('DD/MM/YYYY');
                                    }
                                    if (fin && fin.isValid()) {
                                        type['fin' + field].date = day.date.clone().add(diffDebut + (debut ? fin.diff(debut, 'day') : 0), 'day').format('DD/MM/YYYY');
                                    }
                                }
                            };

                            fixDates(event);

                            for (let collection of ['pauseCollection', 'tacheCollection']) {
                                if(event[collection]) {
                                    for (let type of event[collection]) {
                                        fixDates(type);
                                    }
                                }
                            }

                            this.planningStore.pasteEvent({
                                user,
                                day: day.id,
                                value: event,
                                oldUser,
                                oldDay,
                            });

                            this.selected[x] = true;
                        }
                    }
                },
                insert(type) {
                    Object.keys(this.selected).filter(key => this.selected[key] === true).forEach((x) => {
                        const dayIndex = Math.floor(x/this.personnelCount);
                        const day = this.days[dayIndex];
                        const personnel = this.filteredPersonnels[x % this.personnelCount];
                        const user = this.personnels.indexOf(personnel);

                        this.planningStore.addEvent({
                            user,
                            day: day.id,
                            value: App.Salaries.createType(type, personnel.id, day.date),
                        });
                    });
                },
                canView(type) {
                    return !this.editEnabled && (params.roles.ROLE_SALARIES_PLANNING_VIEW_DETAIL || (params.roles.ROLE_SALARIES_PLANNING_VIEW_DETAIL_SELF && type && type.personnel === params.personnelId));
                },
                openView(type) {
                    if(this.canView(type)) {
                        this.open(type);
                    }
                },
                openEdit(type) {
                    if(this.editEnabled && 'select' === this.tool) {
                        this.open(type);
                    }
                },
                open(type) {
                    this.isOpen = true;
                    let url = this.editEnabled ? this.formTypeUrl.replace('_TYPE_', type.type) : this.viewTypeUrl.replace('_ID_', type.id);
                    App.Salaries.openTypeSwal(type, url, this.editEnabled, this.types, (data) => {
                        type = Object.assign(type, data);
                        this.hasEditedEvents = true;
                        this.planningStore.editEvent({
                            user: this.personnels.findIndex((item) => {return item.id === type.personnel}),
                            day: moment(data.debutPrevu.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                            value: type,
                        })
                    }, () => {
                        this.isOpen = false;
                    });
                },
                print(event) {
                    let ladda = Ladda.create(event.currentTarget);
                    ladda.start();

                    let params = {
                        debut: this.periode.debut.format('DD/MM/YYYY'),
                        fin: this.periode.fin.format('DD/MM/YYYY'),
                        structureCollection: this.structures,
                        personnelCollection: this.filteredPersonnels.map(x => x.id),
                        indicateurCollection: this.indicateursFilter.map(item => item.id),
                    }

                    App.Utils.print(this.exportUrl.replace('_TYPE_', 'html') + '?' + $.param(params)).then(() => {
                        ladda.stop();
                    })
                },
                save(event) {
                    this.saving = true;
                    this.tool = 'select';
                    this.selected = [];
                    this.selecting = [];
                    this.done = [];
                    this.clipboard = {};

                    let ladda = Ladda.create(event.currentTarget);
                    ladda.start();

                    let data = {};
                    for(let personnel of this.personnels) {
                        let types = [];
                        for(let day in personnel.evenements) {
                            let date = Moment(day, 'YYYY-MM-DD');
                            if(date >= this.periode.debut && date <= this.periode.fin) {
                                let event = personnel.evenements[day];
                                types.push(App.Salaries.normalizeType(event));
                            }
                        }

                        data[personnel.id] = {
                            types,
                            publication: personnel.publication,
                            validation: personnel.validation,
                        };
                    }

                    Request.postJson(this.updateUrl, {
                        planning: {
                            debut: this.periode.debut.format('DD/MM/YYYY'),
                            fin: this.periode.fin.format('DD/MM/YYYY'),
                            structureCollection: this.structures,
                            personnelCollection: this.personnelsFilter,
                            data
                        }
                    }).then((data) => {
                        this.planningStore.personnelsJson = JSON.stringify(data.personnels || []);
                        this.planningStore.emptyState();
                        this.planningStore.disableAndClearHistory();
                        this.saving = false;
                        this.editEnabled = false;
                        ladda.stop();
                    }, (response) => {
                        // TODO - handle error

                        this.saving = false;
                        ladda.stop();
                    });
                },
                reload(withModeles = false, cb = null) {
                    const planning = {
                        debut: this.periodeAjax.debut,
                        fin: this.periodeAjax.fin,
                        withModeles,
                        structureCollection: this.structures,
                        personnelCollection: this.personnelsFilter,
                    };

                    this.isLoading = true;

                    this.postJson(this.url, {planning}).then((data) => {
                        this.isLoading = false;

                        if (data.types) {
                            this.types = data.types;
                        }
                        if (data.categories) {
                            this.categories = data.categories;
                        }
                        this.evenements = data.evenements;
                        this.planningStore.personnelsJson = JSON.stringify(data.personnels || []);
                        this.planningStore.emptyState();
                        this.planningStore.disableAndClearHistory();

                        cb && cb();
                    }, (response) => {
                        if (response.status !== 0) { // On n'enlève pas le loader si la requête a été annulée pour une autre.
                            this.isLoading = false;
                        }
                    });
                },
                dateInputSetter(value, date) {
                    let date1 = moment(value, 'DD/MM/YYYY');
                    if(date1.isValid()) {
                        let diff = date1.diff(date, 'month');
                        let date2 = Math.abs(diff) >= 2 ? date1.clone().add((diff < 0 ? 1 : -1) * 2, 'month') : date;

                        this.periode = {
                            debut: date1.isBefore(date2) ? date1 : date2,
                            fin: date1.isBefore(date2) ? date2 : date1,
                        };
                    }
                },
                resetPeriode() {
                    let date = moment(this.periode.debut);
                    this.periode.debut = date.startOf(this.mode);
                    this.periode.fin = date.clone().endOf(this.mode);
                },
                edit() {
                    this.editLoading = true;

                    this.reload(true, () => {
                        this.editEnabled = true;
                        this.editLoading = false;
                        this.planningStore.enableHistory();

                        $('.dropdown-toggle').dropdown();
                    });
                },
                cancel() {
                    this.editEnabled = false;
                    this.tool = 'select';
                    this.selected = [];
                    this.selecting = [];
                    this.done = [];
                    this.clipboard = {};

                    this.planningStore.emptyState();
                    this.planningStore.disableAndClearHistory();
                },
                now() {
                    let now = moment();

                    this.periode.debut = now.clone().startOf(this.mode);
                    this.periode.fin = now.clone().endOf(this.mode);
                },
                onHover(i, j) {
                    this.hoverIndexes = {i, j};

                    if(this.mouseDown) {
                        this.onMouseDown(i, j)
                    }
                },
                onMouseDown(i, j) {
                    if(this.editEnabled) {
                        let day = this.days[i];

                        if(this.publicationDragging) {
                            this.publier(day.id);
                        }
                        else if(this.validationDragging) {
                            this.valider(day.id);
                        }
                        else if (this.tool === 'insert') {
                            this.planningStore.startGrouping();

                            const personnel = this.filteredPersonnels[j];

                            this.planningStore.addEvent({
                                user: this.personnels.indexOf(personnel),
                                day: day.id,
                                value: App.Salaries.createType(this.currentType, personnel.id, day.date)
                            });
                        }
                    }
                },
                isEventStartEnd(i, j, x) {
                    let currentEvent = this.days[i].events[j];
                    let nextEvent = this.days[i+x] ? this.days[i+x].events[j] : null;

                    return currentEvent && nextEvent && currentEvent.modeleTypeId === nextEvent.modeleTypeId && !this.isHovered(i+x, j);
                },
                isEventStart(i, j) {
                    return this.isEventStartEnd(i, j, 1);
                },
                isEventEnd(i, j) {
                    return this.isEventStartEnd(i, j, -1);
                },
                isHovered(i, j) {
                    let currentEvent = this.days[i].events[j];

                    return this.tool === 'insert' && this.hoverIndexes.i === i && this.hoverIndexes.j  === j && (!currentEvent || currentEvent.modeleTypeId !== this.currentType.id);
                },
                removeSelected() {
                    Object.keys(this.selected).filter(key => this.selected[key] === true).forEach((x) => {
                        const personnel = this.filteredPersonnels[x % this.personnelCount];
                        const user = this.personnels.indexOf(personnel);
                        const dayIndex = Math.floor(x/this.personnelCount);
                        const day = this.days[dayIndex].id

                        this.planningStore.removeEvent({
                            user,
                            day,
                        });
                    });
                },
                step(amount) {
                    const unit = this.modeGlissant ? 'day' : this.mode;

                    this.periode.debut = moment(this.periode.debut).add(amount, unit);
                    this.periode.fin = this.modeGlissant ? moment(this.periode.fin).add(amount, unit) : moment(this.periode.debut).endOf(unit);
                },
                selectedGetter () { return this.selected; },
                selectedSetter(v) {
                    if(!this.validationDragging && !this.publicationDragging && !this.contextMenuActive && !this.saving && this.editEnabled && this.tool === 'select') {
                        this.selected = v.reduce((res, item, index) => (item ? (res[index] = item) : null, res), {});
                    }
                },
                selectingSetter(v) {
                    if(!this.validationDragging && !this.publicationDragging && !this.contextMenuActive && !this.saving && this.editEnabled && this.tool === 'select') {
                        this.selecting = v.reduce((res, item, index) => (item ? (res[index] = item) : null, res), {});
                    }
                },
                getJourNonTravailleTitle(joursNonTravailles, day) {
                    if (!joursNonTravailles.length) {
                        return null;
                    }

                    let isJourNonTravaille = false;

                    let title = 'Ce jour est marqué comme un jour non travaillé pour ce personnel';
                    let commentaires = [];

                    const typeSemaine = (0 === day.date.format('w') % 2) ? 'paires' : 'impaires';

                    for (const jourNonTravaille of joursNonTravailles) {
                        if (jourNonTravaille.debut <= day.id && (!jourNonTravaille.fin || day.id <= jourNonTravaille.fin) && jourNonTravaille.semaines[jourNonTravaille.estPairImpair ? typeSemaine : 'paires'][day.date.isoWeekday()-1]) {
                            isJourNonTravaille = true;
                            if(null !== jourNonTravaille.commentaire && !commentaires.includes(jourNonTravaille.commentaire)) {
                                commentaires.push(jourNonTravaille.commentaire);
                            }
                        }
                    }

                    if(!isJourNonTravaille) {
                        return null;
                    }

                    if(commentaires.length) {
                        title += ' ('+commentaires.join(', ')+')';
                    }

                    title += '.';

                    return title;
                },
                estJourNonTravaille(joursNonTravailles, day) {
                    if (!joursNonTravailles.length) {
                        return false;
                    }

                    const typeSemaine = (0 === day.date.format('w') % 2) ? 'paires' : 'impaires';

                    for (const jourNonTravaille of joursNonTravailles) {
                        if (jourNonTravaille.debut <= day.id && (!jourNonTravaille.fin || day.id <= jourNonTravaille.fin) && jourNonTravaille.semaines[jourNonTravaille.estPairImpair ? typeSemaine : 'paires'][day.date.isoWeekday()-1]) {
                            return true;
                        }
                    }

                    return false;
                },
                estDansPressePapier(i, j) {
                    const clipboardEvent = this.clipboard[this.personnels.length*i+j];

                    return clipboardEvent && clipboardEvent === this.days[i].events[j];
                },
                getEventState(i, j) {
                    const dayStr = this.periode.debut.clone().add(i,'day').format('YYYY-MM-DD');
                    if (this.planningStore.eventStates[j]) {
                        return this.planningStore.eventStates[j][dayStr] ?? null;
                    }
                    return null;
                },
            },
            directives: { selectable: selectable.default },
        }).use(App.Pinia).mount(element);
    }
};
