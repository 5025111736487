<template>
    <sefi-popup :title="title" :loading="loading" :error="error" :result="result" :init-form="true" @refresh="refresh">
        <template v-slot:initForm>
            <div class="mx-5">
                <sefi-form-row v-if="!fromTransportForm && !fromFactureForm" v-model="inputData.structure" :errors="errors.structure" component="sefi-form-structure" label="Société" required></sefi-form-row>
                <sefi-form-row v-model="inputData.numero" :errors="errors.numero" label="Numéro" required></sefi-form-row>
                <sefi-form-row v-model="inputData.nomFamille" :errors="errors.nomFamille" label="Nom de famille" required></sefi-form-row>
            </div>
        </template>
        <template v-slot:initFormButtons>
            <b-btn variant="primary" @click="refresh">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
        </template>
        <template v-slot:form>
            <div class="mb-3" v-if="!fromTransportForm && !fromFactureForm">
                <sefi-form-widget component="sefi-form-structure" v-model="inputData.structure" :errors="errors.type"></sefi-form-widget>
            </div>
            <div class="mb-3">
                <sefi-form-widget component="form-input-group" v-model="inputData.numero" :props="{label: 'Numéro'}" :errors="errors.numero"></sefi-form-widget>
            </div>
            <div>
                <sefi-form-widget component="form-input-group" v-model="inputData.nomFamille" :props="{label: 'Nom'}" :errors="errors.nomFamille"></sefi-form-widget>
            </div>
        </template>/
        <template v-slot:resultButtons v-if="result">
            <sefi-result-reporter v-if="fromTransportForm || fromFactureForm" :enabled="!reporterClicked" @click="reporter"></sefi-result-reporter>
            <b-btn variant="info" :title="trajets.length === 1 ? 'Réserver le trajet' : 'Réserver les trajets'" v-if="trajets.length" :disabled="!canReserverTrajets" @click="trajetAction('reserver', trajets)">
                <i class="fa-solid fa-fw fa-lock"></i> Réserver
            </b-btn>
            <b-btn variant="success" :title="trajets.length === 1 ? 'Marquer le trajet comme réalisé' : 'Marquer les trajets comme réalisés'" v-if="trajets.length" :disabled="!canExecuterTrajets" @click="trajetAction('executer', trajets)">
                <i class="fa-solid fa-fw fa-check"></i> Réaliser
            </b-btn>

        </template>
        <template v-slot:result v-if="result">
            <div class="alert alert-success" v-if="actionFlash !== null">
                <strong>{{ Translator.trans('flash.success') }}</strong> {{ actionFlash }}
            </div>
            <template v-if="actionResult">
                <div class="alert alert-danger" v-for="resultError in actionResult.errors">
                    <strong>[{{ resultError.code }}]</strong> {{ resultError.libelle }} ({{ resultError.trajet }})
                </div>
            </template>
            <div class="row">
                <div class="col-5">
                    <h3>Prescription</h3>
                    <div class="sefi-prescription">
                        <div class="d-flex flex-1 flex-column justify-content-center pl-3">
                            <div class="text-monospace mb-2">
                                <i class="fa-solid fa-hashtag mr-3 font-size-16px"></i>{{ result.prescription.numero }}
                            </div>
                            <div>
                                <i class="fa-solid fa-calendar-days mr-3 font-size-16px"></i>{{ result.prescription.date }}
                            </div>
                        </div>
                        <div class="sefi-prescription-status bg-info text-white" :title="result.prescription.dateEtat">
                            <div>
                                <i v-if="'PRES' === result.prescription.etat" class="fa-regular fa-fw fa-pen-to-square"></i>
                                <i v-else-if="'ECO' === result.prescription.etat" class="fa-solid fa-fw fa-hourglass"></i>
                                <i v-else-if="'PRM' === result.prescription.etat" class="fa-solid fa-fw fa-ban"></i>
                                <i v-else-if="'CLO' === result.prescription.etat" class="fa-regular fa-fw fa-circle-xmark"></i>
                            </div>
                            <div class="mt-2 sefi-prescription-status-text">
                                <template v-if="'PRES' === result.prescription.etat">Prescrite</template>
                                <template v-else-if="'ECO' === result.prescription.etat">En cours</template>
                                <template v-else-if="'PRM' === result.prescription.etat">Périmée</template>
                                <template v-else-if="'CLO' === result.prescription.etat">Clôturée</template>
                            </div>
                        </div>
                    </div>
                    <h3 class="mt-3">Prescripteur</h3>
                    <div class="d-flex align-items-center">
                        <table class="table table-bordered m-0 flex-1">
                            <tbody>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">Libellé</th>
                                <td class="py-2">{{ result.prescripteur.libelle }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">FINESS</th>
                                <td class="py-2">{{ result.prescripteur.finess }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">Prénom</th>
                                <td class="py-2">{{ result.prescripteur.prescripteur.prenomUsuel }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">Nom</th>
                                <td class="py-2">{{ result.prescripteur.prescripteur.nomUsuel }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">RPPS</th>
                                <td class="py-2">{{ result.prescripteur.prescripteur.rpps }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">AM</th>
                                <td class="py-2">{{ result.prescripteur.prescripteur.am }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="width-32px text-right">
                            <b-btn v-if="result.prescripteur.entity" class="px-1 py-5" variant="outline-info" :href="Router.generate('referentiel.organisme.prescripteur.view', {id: result.prescripteur.entity.id})" target="_blank" ref="prescripteurBtn">
                                <i class="fa-solid fa-fw fa-link"></i>
                            </b-btn>
                            <b-btn v-else-if="newPrescripteurClicked" class="px-1 py-5" variant="secondary" @click="refresh" title="Rafraichir">
                                <i class="fa-solid fa-fw fa-arrows-rotate"></i>
                            </b-btn>
                            <b-btn v-else @click="newPrescripteurClicked = true" class="px-1 py-5" :href="Router.generate('referentiel.organisme.prescripteur.praticien.new', {libelle: result.prescripteur.libelle, finess: result.prescripteur.finess, adresse: result.prescripteur.adresse, ville: result.prescripteur.ville, codePostal: result.prescripteur.codePostal, prenom: result.prescripteur.prescripteur.prenomUsuel, nom: result.prescripteur.prescripteur.nomUsuel, rpps: result.prescripteur.prescripteur.rpps, am: result.prescripteur.prescripteur.am})" target="_blank" variant="outline-success" title="Créer le prescripteur">
                                <i class="fa-solid fa-fw fa-plus"></i>
                            </b-btn>
                        </div>
                    </div>
                    <h3 class="mt-3">Patient</h3>
                    <div class="d-flex align-items-center">
                        <table class="table table-bordered m-0 flex-1">
                            <tbody>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap" title="Numéro de sécurité sociale">NIR</th>
                                <td class="py-2">{{ result.patient.numeroSecuriteSociale }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">Prénom</th>
                                <td class="py-2">{{ result.patient.prenomUsuel }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap">Nom usuel</th>
                                <td class="py-2">{{ result.patient.nomUsuel }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap" title="Nom de naissance">Nom naiss.</th>
                                <td class="py-2">{{ result.patient.nomNaissance }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap" title="Date de naissance">Date naiss.</th>
                                <td class="py-2">{{ result.patient.dateNaissance }}</td>
                            </tr>
                            <tr>
                                <th class="py-2 bg-light width-1px text-nowrap" title="Rang de naissance">Rang naiss.</th>
                                <td class="py-2">{{ result.patient.rangNaissance }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="width-32px text-right">
                            <b-btn v-if="result.patient.entity" class="px-1 py-5" variant="outline-info" target="_blank" :href="Router.generate('shared.patient.view', {app: getCurrentApp(), id: result.patient.entity.id})" ref="patientBtn">
                                <i class="fa-solid fa-fw fa-link"></i>
                            </b-btn>
                        </div>
                    </div>
                </div>
                <div class="col-7">
                    <h3>Transport</h3>
                    <table class="table table-bordered m-0 text-center">
                        <tbody>
                        <tr class="bg-light">
                            <th class="py-1">Motif</th>
                            <th class="py-1">Catégorie</th>
                            <th class="py-1">Type</th>
                            <th class="py-1">Quantité</th>
                            <th class="py-1" :title="Translator.trans('libelle.tauxCaisse')">Taux</th>
                        </tr>
                        <tr>
                            <td class="py-2">
                                {{ result.transport.motif.text }}
                            </td>
                            <td class="py-2">
                                {{ result.transport.categorie.text }}
                            </td>
                            <td class="py-2">
                                {{ result.transport.type }}
                            </td>
                            <td class="py-2">
                                {{ result.transport.quantite }}
                            </td>
                            <td class="py-2">
                                <pourcent :value="result.transport.tauxCaisse"></pourcent>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-6 d-flex flex-column" v-for="lieu, title in {'Prise en charge': result.transport.priseEnCharge, 'Destination': result.transport.destination}">
                            <h3 class="mt-3">{{ title }}</h3>
                            <div class="sefi-adresse flex-1">
                                <div>
                                    <span class="badge badge-secondary py-1 px-2" v-if="lieu.type === 'DOM'">Domicile</span>
                                    <span class="badge badge-secondary py-1 px-2" title="Établissement" v-else-if="lieu.type === 'STRS'">Étab.</span>
                                    <template v-if="lieu.libelle">
                                        {{ lieu.libelle }}
                                    </template>
                                </div>
                                <div v-if="lieu.adresse">
                                    {{ lieu.adresse }}
                                </div>
                                <div title="21900">
                                    {{ lieu.codePostal }} {{ lieu.ville }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 class="mt-3">Trajets</h3>
                    <b-table class="table-compact" no-sort-reset show-empty bordered :per-page="trajetPerPage" :current-page="trajetPage"  :items="result.trajets" :fields="trajetFields" sort-by="id" primary-key="id">
                        <template #empty="scope">
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #head(checkbox)="scope">
                            <div class="checkbox">
                                <input type="checkbox" id="checkbox_trajet_all" class="form-check-input" :checked="trajets.length" @input="toggleAll" v-indeterminate="trajets.length && trajets.length !== checkableTrajets.length">
                                <label for="checkbox_trajet_all"></label>
                            </div>
                        </template>
                        <template #cell(checkbox)="{item}">
                            <div class="checkbox">
                                <input type="checkbox" :id="'checkbox_trajet_'+item.id" class="form-check-input" v-model="trajets" :value="item" :disabled="!isCheckable(item)">
                                <label :for="'checkbox_trajet_'+item.id"></label>
                            </div>
                        </template>
                        <template #cell(type)="{value}">
                            <template  v-if="value === 'AS'">
                                <i class="fa-solid fa-fw fa-chevron-right" :title="Translator.trans('libelle.trajet-aller')"></i> Aller
                            </template>
                            <template v-else-if="value === 'RS'">
                                <i class="fa-solid fa-fw fa-chevron-left" :title="Translator.trans('libelle.trajet-retour')"></i> Retour
                            </template>
                        </template>
                        <template #cell(etat)="{item, value}">
                            <span class="badge badge-dark" v-if="value === 'NFA'" :title="item.dateEtat">
                                <i class="fa-solid fa-xmark fa-fw"></i> Non facturable
                            </span>
                            <span class="badge badge-success" v-else-if="value === 'FAC'" :title="item.dateEtat">
                                <i class="fa-solid fa-euro-sign fa-fw"></i> Facturé
                            </span>
                            <span class="badge badge-success" v-else-if="value === 'REA'" :title="item.dateEtat">
                                <i class="fa-solid fa-check fa-fw"></i> Réalisé
                            </span>
                            <template v-else-if="value === 'RES'">
                                <span class="badge badge-info" :title="item.dateEtat" v-if="item.owner">
                                    <i class="fa-solid fa-hourglass fa-fw"></i> Reservé
                                </span>
                                <span class="badge badge-dark" :title="item.dateEtat" v-else>
                                    <i class="fa-solid fa-lock fa-fw"></i> Reservé
                                </span>
                            </template>
                            <span class="badge badge-secondary" :title="item.dateEtat" v-else-if="value === 'PRES'">
                                <i class="fa-regular fa-pen-to-square fa-fw"></i> Prescrit
                            </span>
                        </template>
                        <template #cell(lien)="{item}">
                            <template v-if="item.id === trajetAllerTransportId">
                                <i class="fa-solid fa-arrow-down text-success" title="Ce transport (aller)"></i>
                            </template>
                            <template v-else-if="item.id === trajetRetourTransportId">
                                <i class="fa-solid fa-arrow-down text-success" title="Ce transport (retour)"></i>
                            </template>
                            <template v-else-if="item.transport && item.transport.id !== currentTransportId">
                                <b-btn size="xs" variant="secondary" :title="item.transport.text" target="_blank" :href="Router.generate('shared.transport.view', {app: getCurrentApp(), id: item.transport.id})">
                                    <i class="fa-solid fa-fw fa-up-right-from-square"></i>
                                </b-btn>
                            </template>
                        </template>
                        <template #cell(action)="{item}">
                            <b-btn size="xs" variant="outline-info" class="pl-1 pr-2" title="Réserver le trajet" v-if="canReserverTrajet(item)" @click="trajetAction('reserver', [item])">
                                <i class="fa-solid fa-fw fa-lock"></i> Réserver
                            </b-btn>
                            <b-btn size="xs" variant="outline-success" class="pl-1 pr-2" title="Marquer le trajet comme réalisé" v-else-if="canExecuterTrajet(item)" @click="trajetAction('executer', [item])">
                                <i class="fa-solid fa-fw fa-check"></i> Réaliser
                            </b-btn>
                            <template v-if="canLinkTrajetTransport(item)">
                                <b-btn v-if="isLinkTrajetTransport(item)" size="xs" class="pl-1 pr-2" variant="outline-danger" title="Délier ce trajet du trajet du transport" @click="linkTrajetTransport(item, false)">
                                    <i class="fa-solid fa-fw fa-xmark"></i> Délier
                                </b-btn>
                                <b-btn v-else size="xs" class="pl-1 pr-2" variant="outline-secondary" title="Lier ce trajet au trajet du transport" @click="linkTrajetTransport(item)">
                                    <i class="fa-solid fa-fw fa-arrow-down"></i> Lier
                                </b-btn>
                            </template>
                            <template v-else-if="canLinkTrajetFacture(item)">
                                <b-btn v-if="item.id in trajetsFactureBySefiId" size="xs" class="pl-1 pr-2" variant="outline-danger" title="Délier ce trajet du trajet de la facture" @click="unlinkTrajetFacture(item)">
                                    <i class="fa-solid fa-fw fa-xmark"></i> Délier
                                </b-btn>
                                <b-dropdown v-else variant="outline-secondary" title="Lier ce trajet à un trajet de la facture" size="xs">
                                    <template #button-content>
                                        <i class="fa-solid fa-fw fa-arrow-down"></i> Lier
                                    </template>
                                    <b-dropdown-item v-for="trajet in trajetsFacture" :key="trajet.id" @click="linkTrajetFacture(item, trajet)" :link-class="{'disabled': Boolean(trajet.sefiId)}">
                                        {{ trajet.topPriseEnChargeDepartReel.date }} {{ trajet.topPriseEnChargeDepartReel.time }}
                                        <template v-if="trajet.sefiId"> ({{ trajet.sefiId }})</template>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </template>
                    </b-table>
                    <table-pagination :count="result.trajets.length" :per-page="trajetPerPage" v-model="trajetPage" :short="true"></table-pagination>
                </div>
            </div>
        </template>
    </sefi-popup>
</template>

<script>
import Translator from "@/App/Translator";
import Vue from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";

export default {
    props: ['data'],
    directives: {
        indeterminate: function(el, binding) {
            el.indeterminate = Boolean(binding.value);
        }
    },
    data() {
        return {
            result: null,
            inputData: {
                structure: null,
                numero: null,
                nomFamille: null,
            },
            racinePartage: null,
            loading: false,
            error: null,
            trajets: [],
            trajetPerPage: 7,
            trajetPage: 1,
            newPrescripteurClicked: false,
            reporterClicked: false,
            actionFlash: null,
            actionResult: null,
            trajetAllerTransportId: null,
            trajetRetourTransportId: null,
            Router,
            Translator,
        };
    },
    mounted() {
        if(this.fromTransportForm) {
            this.racinePartage = App.Shared.getStructureElement().data('structure');
            this.inputData = {
                structure: {structure: App.Shared.getStructureElement().data('societe')},
                numero: $('#transport_numeroPrescription').val(),
                nomFamille: $('#transport_patientAssure_entity_nomUsuel').val(),
            };

            for (const type of ['trajetAller', 'trajetRetour']) {
                const $sefiId = $('[data-field="sefiId"][data-type="' + type + '"]');
                $sefiId.change((event) => {
                    this[type + 'TransportId'] = event.currentTarget.value || null;
                })

                this[type + 'TransportId'] = $sefiId.val() || null;
            }
        } else if (this.fromFactureForm) {
            this.racinePartage = App.Shared.getStructureElement().data('racinePartageId');
            this.inputData = {
                structure: {structure: App.Shared.getStructure()},
                numero: $('#facture_numeroPrescription').val(),
                nomFamille: $('#facture_patientAssure').data('custom').nomUsuel,
            };

        } else if(this.data && this.data.inputData) {
            this.inputData = this.data.inputData;
        }

        // todo better condition
        if(this.inputData.structure && this.inputData.numero && this.inputData.nomFamille) {
            this.refresh();
        }
    },
    methods: {
        reporter() {
            this.reporterClicked = true;

            const reporter = () => {
                let $prescripteurForm = null;

                if (this.fromTransportForm) {
                    $('#transport_numeroPrescription').val(this.result.prescription.numero).addClass('border-theme');
                    $('#transport_prescription').val(this.result.prescription.date)

                    $prescripteurForm = $('#transport_prescripteurForm');

                    if (this.result.transport.motif.id) {
                        $('#transport_motif').html(new Option(this.result.transport.motif.text, this.result.transport.motif.id, true, true));
                        $("#transport_motif").addClass('js-select2-readonly');
                    }
                    if (this.result.transport.categorie.id) {
                        $('#transport_categorie').val(this.result.transport.categorie.id).trigger('change.select2');
                    }

                    $('#beneficiaireClose').click();
                    if (this.result.patient.entity) {
                        App.module.fillPatientById(this.result.patient.entity.id)
                    } else {
                        App.Utils.clearAllInputsSelector('#assurePanel');
                        $('#transport_patientAssure_entity_numeroSecuriteSociale').typeahead('val', this.result.patient.numeroSecuriteSociale);
                        $('#transport_patientAssure_entity_nomUsuel').typeahead('val', this.result.patient.nomUsuel);
                        $('#transport_patientAssure_entity_prenomUsuel').typeahead('val', this.result.patient.prenomUsuel);
                        $('#transport_patientAssure_entity_naissance').val(this.result.patient.dateNaissance);
                        $('#transport_patientAssure_entity_rangNaissance').val(this.result.patient.rangNaissance);
                        $('#transport_patientAssure_entity_adresse').typeahead('val', this.result.patient.adresse);
                        $('#transport_patientAssure_entity_ville').typeahead('val', this.result.patient.ville);
                        $('#transport_patientAssure_entity_codePostal').typeahead('val', this.result.patient.codePostal);
                        $('#transport_patientAssure_entity_codeInsee').val(this.result.patient.codeInsee);
                    }

                    $('#transport_prescription').prop('readonly', true).next().find('.js-datetimebutton').prop('disabled', true);
                    $('#transport_prescriptionEnLigneData').val(JSON.stringify(this.reporterData));
                } else if (this.fromFactureForm) {
                    $('#facture_numeroPrescription').val(this.result.prescription.numero).addClass('border-theme');
                    $('#facture_datePrescription').val(this.result.prescription.date);

                    $prescripteurForm = $('#facture_prescripteurForm');

                    // TODO


                    $('#facture_datePrescription').prop('readonly', true).next().find('.js-datetimebutton').prop('disabled', true);
                    $('#facture_prescriptionEnLigneData').val(JSON.stringify(this.reporterData));
                }

                if ($prescripteurForm) {
                    if (this.result.prescripteur.entity) {
                        console.log(this.result.prescripteur.entity);
                        $prescripteurForm.typeahead('val', this.result.prescripteur.entity.text);
                        $prescripteurForm.trigger('typeahead:select', this.result.prescripteur.entity);
                    } else {
                        $prescripteurForm.typeahead('val', this.result.prescripteur.libelle).trigger('input');
                    }
                }

                // trigger change
            }

            if (this.result.prescripteur.entity) {
                reporter();
            } else {
                const prescripteur = {structure: this.racinePartage, ...this.result.prescripteur};
                if (!prescripteur.prescripteur.am) {
                    prescripteur.prescripteur.am = prescripteur.finess;
                }
                delete prescripteur.finess;
                Request.postJson(Router.generate('facturation.sefi.pl.prescripteur'), {
                    prescripteur,
                }).then(data => {
                    Vue.set(this.result.prescripteur, 'entity', data.data);
                    this.updatePopovers();

                    reporter();
                })
            }
        },
        linkTrajetTransport(trajet, link = true) {
            const estRetour = 'RS' === trajet.type;
            const type = estRetour ? 'trajetRetour' : 'trajetAller';
            $('[data-field="sefiId"][data-type="'+type+'"]').val(link ? trajet.id : '').change();

            if (link) {
                const lieuData = {
                    'PriseEnCharge': estRetour ? this.result.transport.destination : this.result.transport.priseEnCharge,
                    'Destination': estRetour ? this.result.transport.priseEnCharge : this.result.transport.destination,
                };

                for (const typeLieu in lieuData) {
                    const lieu = lieuData[typeLieu];
                    let adresse = lieu.adresse;
                    if (lieu.libelle) {
                        adresse = lieu.libelle + '\n' + adresse;
                    }

                    const $base = $('#' + type + 'Panel [data-adresse-type="' + typeLieu + '"]');

                    $base.find('[data-field="codeInsee"]').val(lieu.codeInsee);
                    $base.find('[data-field="precision"]').val(0).change();
                    $base.find('[data-field="adresse"]').typeahead('val', adresse);
                    $base.find('[data-field="ville"]').typeahead('val', lieu.ville);
                    $base.find('[data-field="codePostal"]').typeahead('val', lieu.codePostal);
                }
            }
        },
        isLinkTrajetTransport(trajet) {
            const id = 'RS' === trajet.type ? this.trajetRetourTransportId : this.trajetAllerTransportId;

            return id === ''+trajet.id;
        },
        canLinkTrajetTransport(trajet) {
            return this.fromTransportForm && trajet.owner && (trajet.etat === 'RES' || trajet.etat === 'REA') && (!trajet.transport || trajet.transport.id === this.currentTransportId);
        },
        unlinkTrajetFacture(item) {
            const trajet = this.trajetsFactureBySefiId[item.id];

            if (trajet) {
                App.module.vm.onTrajetUpdate(trajet, {
                    sefiId: null,
                });
            }
        },
        linkTrajetFacture(item, trajet) {
            App.module.vm.onTrajetUpdate(trajet, {
                sefiId: item.id,
                adressePriseEnCharge: 'RS' === item.type ? this.result.transport.destination.adresse : this.result.transport.priseEnCharge.adresse,
                codeInseePriseEnCharge: 'RS' === item.type ? this.result.transport.destination.codeInsee : this.result.transport.priseEnCharge.codeInsee,
                codePostalPriseEnCharge: 'RS' === item.type ? this.result.transport.destination.codePostal : this.result.transport.priseEnCharge.codePostal,
                villePriseEnCharge: 'RS' === item.type ? this.result.transport.destination.ville : this.result.transport.priseEnCharge.ville,
                adresseDestination: 'RS' === item.type ? this.result.transport.priseEnCharge.adresse : this.result.transport.destination.adresse,
                codeInseeDestination: 'RS' === item.type ? this.result.transport.priseEnCharge.codeInsee : this.result.transport.destination.codeInsee,
                codePostalDestination: 'RS' === item.type ? this.result.transport.priseEnCharge.codePostal : this.result.transport.destination.codePostal,
                villeDestination: 'RS' === item.type ? this.result.transport.priseEnCharge.ville : this.result.transport.destination.ville,
                motif: this.result.transport.motif,
            });
        },
        canLinkTrajetFacture(trajet) {
            return this.fromFactureForm && trajet.owner && trajet.etat === 'REA';
        },
        canReserverTrajet(trajet) {
            return trajet.etat === 'PRES' || (trajet.etat === 'RES' && !trajet.owner);
        },
        canExecuterTrajet(trajet) {
            return trajet.etat === 'RES' && trajet.owner;
        },
        trajetAction(action, trajets) {
            this.trajets = [];
            this.actionFlash = null;
            this.actionResult = null;
            this.error = null;
            this.loading = true;

            Request.postJson(Router.generate('facturation.sefi.pl.reserver-executer-trajet'), {
                ...this.inputData,
                action,
                trajets: trajets.map(trajet => trajet.id),
            }).then((data) => {
                if(data.success) {
                    this.result = data.data.result;
                    this.actionResult = data.data.actionResult;

                    const trajetIds = this.actionResult.trajets.map(t => t.id);
                    if (trajetIds.length) {
                        const s = trajetIds.length > 1 ? 's' : '';
                        const actionStr = data.data.action === 'reserver' ? 'réservé' + s : 'réalisé' + s;
                        this.actionFlash = (s ? 'Les trajets suivants ont été' : 'Le trajet suivant a été') + ' ' + actionStr + ' : ' + trajetIds.join(', ');

                        if (this.fromTransportForm) {
                            let allerLinked = this.trajetAllerTransportId !== null;
                            let retourLinked = this.trajetRetourTransportId !== null;

                            for (const trajet of trajets) {
                                if (trajetIds.includes(trajet.id)) {
                                    if (!allerLinked && trajet.type === 'AS') {
                                        this.linkTrajetTransport(trajet);
                                        allerLinked = true;
                                    }
                                    if (!retourLinked && trajet.type === 'RS') {
                                        this.linkTrajetTransport(trajet);
                                        retourLinked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    this.error = data.error;
                }

                this.loading = false;
            });

        },
        isCheckable(item) {
            return this.canReserverTrajet(item);
        },
        toggleAll() {
            if(this.trajets.length) {
                this.trajets = [];
            } else {
                this.trajets = this.checkableTrajets;
            }
        },
        refresh() {
            this.trajets = [];
            this.actionFlash = null;
            this.newPrescripteurClicked = false
            this.error = null;
            this.reporterClicked = false;
            this.loading = true;

            Request.postJson(Router.generate('facturation.sefi.pl.acquerir-prescription'), this.inputData).then((data) => {
                if(data.success) {
                    this.result = data.data;
                } else {
                    this.error = data.error;
                }

                this.loading = false;
            });
        },
        updatePopovers() {
            this.$nextTick(() => {
                for(const field of ['patient', 'prescripteur']) {
                    const $ref = this.$refs[field+'Btn'];
                    if ($ref) {
                        $($ref.$el).popover({
                            trigger: 'hover',
                            placement: 'right',
                            fallbackPlacement: [],
                            boundary: 'window',
                            html: true,
                            sanitize: false,
                            content: this.result[field].entity.card,
                        }).on('show.bs.popover', function () {
                            const $tip = $($(this).data('bs.popover').tip);
                            $tip.css('min-width', '25%');
                            const $content = $tip.find('.popover-body');
                            $content.addClass('p-3');
                        });
                    }
                }
            });
        }
    },
    computed: {
        reporterData() {
            if (this.result) {
                return {
                    numero: this.result.prescription.numero,
                    date: this.result.prescription.date,
                    motif: this.result.transport.motif.id,
                    categorie: this.result.transport.categorie.id,
                    patient: {
                        numeroSecuriteSociale: this.result.patient.numeroSecuriteSociale,
                        dateNaissance: this.result.patient.dateNaissance,
                        rangNaissance: this.result.patient.rangNaissance,
                        nomNaissance: this.result.patient.nomNaissance,
                        nomUsuel: this.result.patient.nomUsuel,
                        prenomUsuel: this.result.patient.prenomUsuel,
                    },
                    prescripteur: {
                        finess: this.result.prescripteur.finess,
                        am: this.result.prescripteur.prescripteur.am,
                        rpps: this.result.prescripteur.prescripteur.rpps,
                    },
                    priseEnCharge: {
                        adresse: this.result.transport.priseEnCharge.adresse,
                        codeInsee: this.result.transport.priseEnCharge.codeInsee,
                        codePostal: this.result.transport.priseEnCharge.codePostal,
                        ville: this.result.transport.priseEnCharge.ville,
                    },
                    destination: {
                        adresse: this.result.transport.destination.adresse,
                        codeInsee: this.result.transport.destination.codeInsee,
                        codePostal: this.result.transport.destination.codePostal,
                        ville: this.result.transport.destination.ville,
                    },
                };
            }

            return null;
        },
        trajetsFactureBySefiId() {
            if (this.fromFactureForm) {
                return App.module.vm.trajetsBySefiId;
            }

            return [];
        },
        trajetsFacture() {
            if (this.fromFactureForm) {
                return App.module.vm.trajetsArray;
            }

            return [];
        },
        trajetFields() {
            return [
                {key: 'checkbox', thClass: 'pt-1 pb-2 bg-light width-1px', tdClass: 'py-2'},
                {key: 'id', label: '#', thClass: 'pt-1 pb-2 bg-light', tdClass: 'py-2'},
                {key: 'type', label: Translator.trans('libelle.type'), thClass: 'pt-1 pb-2 bg-light', tdClass: 'py-2 text-nowrap'},
                {key: 'etat', label: Translator.trans('libelle.etat'), thClass: 'pt-1 pb-2 bg-light', tdClass: 'py-2 text-nowrap'},
                {key: 'lien', label: Translator.trans('libelle.lien'), thClass: 'pt-1 pb-2 bg-light', tdClass: 'py-2 text-nowrap text-center'},
                this.fromTransportForm || this.fromFactureForm ? {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'pt-1 pb-2 bg-light width-1px text-center', tdClass: 'py-2 text-nowrap'} : null,
            ];
        },
        currentTransportId() {
            if(this.fromTransportForm) {
                return App.module.id;
            }

            return null;
        },
        canReserverTrajets() {
            return this.trajets.length && this.trajets.filter((trajet) => this.canReserverTrajet(trajet)).length === this.trajets.length;
        },
        canExecuterTrajets() {
            return this.trajets.length && this.trajets.filter((trajet) => this.canExecuterTrajet(trajet)).length === this.trajets.length;
        },
        checkableTrajets() {
            return this.result ? this.result.trajets.filter(trajet => this.isCheckable(trajet)) : [];
        },
        fromTransportForm() {
            return this.data && this.data.transportForm;
        },
        fromFactureForm() {
            return this.data && this.data.factureForm;
        },
        title() {
            return this.fromTransportForm || this.fromFactureForm  ? 'Acquérir les données de la prescription' : 'Consulter les données de la prescription';
        },
        errors() {
            if(this.error) {
                return this.error.errors || [];
            }

            return [];
        }
    },
    watch: {
        result() {
            if(this.result) {
                this.updatePopovers();
            }
        }
    }
}
</script>
