<template>
    <SelectButton class="flex" :pt:pcToggleButton="buttonPt" v-model="model" :allowEmpty="multiple" :options="Object.keys(choices).map(value => ({value, ...typeof choices[value] === 'object' ? choices[value] : {label: choices[value]}}))" optionLabel="label" optionValue="value" :multiple="multiple" aria-labelledby="multiple">
        <template #option="slotProps">
            <i v-if="slotProps.option.icon" :class="slotProps.option.icon"></i>
            <span v-if="slotProps.option.label" class="font-medium leading-[normal]">{{ slotProps.option.label }}</span>
        </template>

    </SelectButton>
</template>

<script setup>
import SelectButton from "primevue/selectbutton";
import {toRefs} from "vue";

const props = defineProps({
    choices: Object,
    multiple: Boolean,
});
const {choices, multiple} = toRefs(props);
const model = defineModel();

const buttonPt = (options) => {
    const res = {root: {class: '!px-3 flex-1 justify-center text-nowrap'}};

    // todo need fix https://github.com/primefaces/primevue/issues/6127
    if (options.context) {
        res.root.title = options.context.option.title;
        res.root.onDblclick = multiple.value ? () => model.value = [options.context.option.value] : null
    }

    return res;
}
</script>