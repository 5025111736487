<template>
    <sefi-popup title="Contrôler la recevabilité de la facture" :loading="loading" :error="error" :result="result" @refresh="refresh" :no-form="true">
        <template v-slot:resultButtons v-if="result">
            <b-btn variant="info" @click="valider" v-if="result.success">
                <i class="fa-solid fa-check fa-fw"></i> Accepter, sauvegarder et valider
            </b-btn>
        </template>
        <template v-slot:result v-if="result">
            <template v-if="result.success">
                <div class="pt-1">
                    <div class="swal2-icon swal2-success swal2-animate-success-icon">
                        <div class="swal2-success-circular-line-left" style="background: rgb(255, 255, 255);"></div>
                        <span class="swal2-success-line-tip swal2-animate-success-line-tip"></span>
                        <span class="swal2-success-line-long swal2-animate-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div class="swal2-success-fix" style="background: rgb(255, 255, 255);"></div>
                        <div class="swal2-success-circular-line-right" style="background: rgb(255, 255, 255);"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mx-auto">
                            <div class="alert alert-secondary text-center">La facture est <strong>recevable</strong>.</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-10 mx-auto">
                        <blockquote class="border-dark text-dark font-size-14px mt-3 mb-0">
                            {{ result.clause }}
                        </blockquote>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="alert alert-danger"><strong>La facture soumise n'est pas recevable.</strong> Veuillez corriger les erreurs suivantes : </div>
                <div class="mx-5">
                    <template v-if="result.errors.facture">
                        <sefi-result-errors title="Facture" :errors="result.errors.facture"></sefi-result-errors>
                    </template>
                    <template v-if="result.errors.trajets">
                        <sefi-result-errors v-for="errors, index in result.errors.trajets" :key="index" :title="'Trajet n°'+index" :errors="errors"></sefi-result-errors>
                    </template>
                </div>
            </template>
        </template>
    </sefi-popup>
</template>

<script>
import Request from "@/App/Request";
import Router from "@/App/Router";

export default {
    props: ['data'],
    data() {
        return {
            result: null,
            loading: false,
            error: null,
        };
    },
    mounted() {
       this.refresh()
    },
    methods: {
        refresh() {
            this.error = null;
            this.loading = true;

            const params = this.data && this.data.id ? {standard: this.data.id} : {};

            if(this.data && this.data.form) {
                const factureData = $('#form').serializeObject();
                Request.postJson(Router.generate('facturation.facture.sefi.controler-recevabilite', params), factureData).then(this.handleResponse);
            } else {
                Request.getJson(Router.generate('facturation.facture.sefi.controler-recevabilite', params)).then(this.handleResponse);
            }

        },
        handleResponse(data) {
            if(data.success) {
                this.result = data.data;
            } else {
                this.error = data.error;
            }

            this.loading = false;
        },
        valider() {
            if(!this.result.success) {
                return;
            }

            this.loading = true;

            if(this.data && this.data.form) {
                $('#facture_estValidee').val(true);
            }

            $('#facture_sefiClause').val(this.result.clause);
            $('#facture_sefiCertificat').val(this.result.certificat);
            $('#form').submit();
        }
    },
}
</script>
