<template>
    <MenuDropdown v-model="open" style="margin-right: 8px;">
        <template #button>
            <div @click="toggle" class="menu-dropdown-btn-item cti-notification">
                <div style=" position:absolute;left:0;top:0;bottom:0;   transform: translate(-50%);     display: flex;
    align-items: center;">
                    <div :class="bubbleClass" style="width: 38px; height: 38px;color:#fff;border-radius: 100%;display: flex; align-items: center;justify-content: center;    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);">
                        <i class="fa-solid fa-pause" v-if="call.isHeld"></i>
                        <i class="fa-solid fa-phone-hangup" v-else-if="call.isCleared"></i>
                        <i :class="'fa-solid '+('incoming' === direction ? 'fa-phone-arrow-down-left' : 'fa-phone-arrow-up-right')" v-else></i>
                    </div>
                </div>
                <div class="width-140px">
                    <div class="text-normal text-ellipsis">{{ call.matches.length && call.matches[0].name || 'Inconnu·e' }}</div>
                    <div class="d-flex justify-content-between align-items-center small">
                        <div>{{ remote }}</div>
                        <div class="text-muted pl-1">
                            <span v-if="call.isCleared">Terminé</span>
                            <Timer v-else-if="call.answerAt" class="text-monospace" date-format="YYYY-MM-DD\THH:mm:ssZ" :value="call.answerAt" seconds></Timer>
                            <span v-else-if="call.callAt">Appel...</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #dropdown>
            <div class="menu-dropdown-content-title">
                <h3>
                    <span v-if="direction === 'outgoing'">Appel sortant</span>
                    <span v-else>Appel entrant</span>
                </h3>
                <b-button v-if="'Regulation' === module && roles.ROLE_DOSSIER_NEW" size="sm" variant="success" class="ml-auto" @click="createAppel(call)">
                    <i class="fa-solid fa-plus"></i> Nouveau dossier
                </b-button>
                <b-button v-if="call.isCleared" size="sm" variant="danger" class="ml-1" title="Fermer cet appel" @click="$emit('closeNotification')">
                    <i class="fa-solid fa-times"></i>
                </b-button>
            </div>
            <div class="p-3">
                <div class="row mb-n3" v-if="call.matches.length">
                    <div class="col-6 mb-3 px-2" v-for="match in call.matches">
                        <a :href="match.url" class="cti-match">
                            <div class="cti-match-type">{{ match.type }}</div>
                            <div class="cti-match-name" :title="match.name">{{ match.name }}</div>
                            <div class="cti-match-description" :title="match.description">{{ match.description }}</div>
                        </a>
                    </div>
                </div>
                <div class="text-muted" v-else>Aucune correspondance.</div>
            </div>
            <div class="p-3 border-top">
                <div class="text-muted" v-if="call.isCleared">
                    Terminé
                </div>
                <div class="d-flex mx-n1" v-else>
                    <button class="mx-1 flex-1 btn btn-success" @click="answer(call.lastCommunication.id)" v-if="call.canAnswer && direction === 'incoming' && !isAnswering">
                        <i class="fa-solid fa-phone"></i> Répondre
                    </button>
                    <button class="mx-1 flex-1 btn btn-warning" @click="hold(call.lastCommunication.id)" v-if="call.canHold">
                        <i class="fa-solid fa-pause"></i> Attente
                    </button>
                    <button class="mx-1 flex-1 btn btn-success" @click="resume(call.lastCommunication.id)" v-if="call.canResume && !isAnswering">
                        <i class="fa-solid fa-play"></i> Reprendre
                    </button>
                    <button class="mx-1 flex-1 btn btn-danger" @click="hang(call.lastCommunication.id)" v-if="call.canHang">
                        <i class="fa-solid fa-phone-hangup"></i> Raccrocher
                    </button>
                </div>
            </div>
        </template>
    </MenuDropdown>
</template>

<script setup>
import {ref, toRefs, computed} from "vue";
import MenuDropdown from "@/Vue/Components/MenuDropdown";
import Timer from "@/Vue/Components/Cti/Timer";

const props = defineProps({
    call: {
        type: Object,
        required: true,
    },
    device: {
        type: String,
        required: true,
    },
})

const {call, device} = toRefs(props);
const open = ref(false);
const toggle = () => open.value = !open.value;

const answer = (com_id) => {
    App.webSocket.publish('cti', {action: 'answer', com_id});
};
const hang = (com_id) => {
    App.webSocket.publish('cti', {action: 'hang', com_id});
};
const hold = (com_id) => {
    App.webSocket.publish('cti', {action: 'hold', com_id});
};
const resume = (com_id) => {
    App.webSocket.publish('cti', {action: 'resume', com_id});
};

const direction = computed(() => {
    if (call.value.lastCommunication) {
        if (call.value.lastCommunication.local === device.value) {
            return call.value.lastCommunication.incoming ? 'incoming' : 'outgoing';
        }
        if (call.value.lastCommunication.remote === device.value) {
            return call.value.lastCommunication.incoming ? 'outgoing' : 'incoming';
        }
    }

    return null;
});

const remote = computed(() => {
    if (call.value.lastCommunication) {
        if (call.value.lastCommunication.local === device.value) {
            return call.value.lastCommunication.remote;
        }
        if (call.value.lastCommunication.remote === device.value) {
            return call.value.lastCommunication.local;
        }
    }

    return call.value.remote;
});

const bubbleClass = computed(() => {
    const classes = [];

    switch (true) {
        case call.value.isOriginated:
            classes.push('call-blue');
            break;
        case call.value.isDelivered:
            classes.push('call-wiggle');
            classes.push('call-blue');
            break;
        case call.value.isHeld:
            classes.push('call-pause');
            classes.push('call-orange');
            break;
        case call.value.isEstablished:
        case call.value.isRetrieved:
            classes.push('call-green');
            break;
        case call.value.isCleared:
            classes.push('call-gray');
            break;
    }

    return classes;
});
</script>