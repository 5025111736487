import html2canvas from 'html2canvas';
import Visibility from 'visibilityjs';
import Router from "@/App/Router";
import * as SentryVue from '@sentry/vue'

App.WebSocket = class {
    constructor(uri, debugMode) {
        this.unload = false;
        this._uri = uri;
        this._debugMode = debugMode;

        this._publications = [];

        this._subscriptions = [
            {route: '/', callback: this._globalHandler}
        ];
        this._session = null;
        this._init = false;

        $(document).on('click', '#webSocketDebug', () => {
            if(this._session) {
                this._session.close();
            } else {
                this.connect();
            }

            return false;
        });

        window.addEventListener('unload', () => {
            this.unload = true;
        });

        $('#webSocketDebug').show();

        this._client = WS.connect(this._uri);
        this._client.on('socket/connect', (session) => {
            this._session = session;

            $('#webSocketStatus').attr('class', 'fa-solid fa-check text-success');

            for(let data of this._subscriptions) {
                this._rawSubscribe(data.route, data.callback.bind(this));
            }
            this._rawPublish('/', {
                action: 'init',
                page: {
                    title: document.title,
                    url: window.location.href,
                    application: App.Constants.APPLICATION,
                    version: App.Constants.VERSION,
                },
                visibility: Visibility.state(),
            });

            for(let data of this._publications) {
                this._rawPublish(data.route, data.data);
            }
            this._publications = [];

            $('.js-hide-ws-init').hide();
            $('.js-hide-ws').hide();
            $('.js-show-ws').show();
            $('.js-websocket-test').hide();
            $('.js-websocket-off').hide();
            $('.js-websocket-on').show();

            this._init = true;

            if(this._debugMode) {
                App.Notification.success('<b>[WS]</b> Connexion au WebSocket établie.');
            }

            document.dispatchEvent(new CustomEvent('websocket:status', {detail: {online: true}}));
        });

        this._client.on('socket/disconnect', (error) => {
            this._session = null;

            $('#webSocketStatus').attr('class', 'fa-solid fa-xmark text-danger').parent().attr('title', '[Code ' + error.code + '] ' + error.reason);

            $('.js-hide-ws-init').hide();
            $('.js-hide-ws').show();
            $('.js-show-ws').hide();

            this._init = true;

            // !error.reason.includes('WS-1001')
            setTimeout(() => {
                if(!this.unload && !this._session) {
                    $('.js-websocket-test').hide();
                    $('.js-websocket-off').show();
                    $('.js-websocket-on').hide();
                }
            }, 5000);

            SentryVue.captureMessage('Connexion au WebSocket interrompue', {
                tags: {
                    websocketDisconnectCode: error.code,
                    websocketDisconnectReason: error.reason,
                },
            });

            if(this._debugMode) {
                App.Notification.error('<b>[WS]</b> Connexion au WebSocket interrompue (' + error.reason + ' code ' + error.code + ')');
            }

            document.dispatchEvent(new CustomEvent('websocket:status', {detail: {online: false}}));
        });

        Visibility.change((e, state) => {
            if (this._session) {
                this._rawPublish('/', {
                    action: 'visibility',
                    state
                });
            }
        });
    }

    _globalHandler(uri, data) {
        let action = data.action;
        let param = data.param;

        if(action === 'notification') {
            App.Notification[param.type].apply(App.Notification, param.content);
        }
        else if(action === 'refresh') {
            window.location.reload();
        }
        else if(action === 'reload-user') {
            fetch(Router.generate('reload-user'));
        }
        else if(action === 'test') {
            App.Notification.info('TEST');
        }
        else if(action === 'redirect') {
            if(data.param.startsWith('http')) {
                let win = window.open(data.param, '_blank');
                if(win) {
                    win.focus();
                } else {
                    window.location.href = data.param;
                }
            } else {
                window.location.href = data.param;
            }
        }
        else if(action === 'screenshot') {
            html2canvas(document.body).then((canvas) => {
                let data = canvas.toDataURL();

                this.publish('/', {
                    action: 'screenshot',
                    data: {
                        id: param.id,
                        image: data
                    }
                });
            });
        }
    }
    connect() {
        if(!this._session) {
            this._client._connect(this._uri);
        }
    }

    isOnline() {
        return this._session !== null;
    }

    isInit() {
        return this._init;
    }

    subscribe(route, callback) {
        if(this._session) {
            this._rawSubscribe(route, callback);
        }

        this._subscriptions.push({route, callback});
    }

    _rawSubscribe(route, callback) {
        this._session.subscribe(route, (uri, data) => {
            if(this._debugMode) {
                App.Notification.info('<b>[WS]</b> Message reçu dans le channel <i>' + uri + '</i>.');
            }

            callback(uri, data);
        });

        if(this._debugMode) {
            App.Notification.info('<b>[WS]</b> Abonnement au channel <i>' + route + '</i>.');
        }
    }

    unsubscribe(route) {
        let isSubscribed = false;

        for(let i in this._subscriptions) {
            let item = this._subscriptions[i];
            if(item.route === route) {
                isSubscribed = true;
                delete this._subscriptions[i];
            }
        }

        if(this._session && isSubscribed) {
            this._session.unsubscribe(route);

            if(this._debugMode) {
                App.Notification.info('<b>[WS]</b> Désabonné du channel <i>' + route + '</i>.');
            }
        }
    }

    publish(route, data) {
        if(this._session) {
            this._rawPublish(route, data);
        } else {
            this._publications.push({route, data});
        }
    }

    _rawPublish(route, data) {
        try {
            this._session.publish(route, data);

            if(this._debugMode) {
                App.Notification.info('<b>[WS]</b> Publication dans le channel <i>' + route + '</i>.');
            }
        } catch (e) {}
    }

    setDebugMode(val) {
        this.debugMode = val;
    }
};
