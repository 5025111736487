<template>
    <template v-if="activeSegment">
        <template v-if="activeSegment.enMouvement">
            <Pin type="start" v-if="activeSegmentPosition !== firstPosition" :position="firstPosition"></Pin>
            <Pin type="end" v-if="activeSegmentPosition !== lastPosition" :position="lastPosition"></Pin>
            <Route id="activeSegmentRoute" @loading="activeSegmentRouteLoading = $event" v-if="activeSegmentShowRoute" mode="ant" :ant-speed="50" :animate="true" color="#3b82f6" :steps="[[firstPosition.latitude, firstPosition.longitude], [lastPosition.latitude, lastPosition.longitude]]"></Route>
        </template>
        <Pin type="active" v-if="activeSegmentPosition" :position="activeSegmentPosition"></Pin>
        <template v-if="activeSegment.enMouvement">
            <Path v-if="activeSegment.routesGeoJSON" id="activeSegmentTrace" color="#64748b" autoFit :fitOptions="{maxZoom: 20, padding: 75}" :features="activeSegment.routesGeoJSON"></Path>
        </template>
        <template v-else>
            <Radius :segment="activeSegment"></Radius>
        </template>
        <Positions :key="activeSegment.id" id="activeSegmentPositions" :autoFit="activeSegment.enMouvement && !activeSegment.routesGeoJSON" :fitOptions="{maxZoom: 20, padding: 75}" :data="positionsGeoJson" @click="onPositionClick"></Positions>
    </template>
</template>

<script setup>
import {storeToRefs} from "pinia";
import Path from '@/Vue/Components/RegulationV2/Map/Base/Path';
import Radius from '@/Vue/Components/RegulationV2/Map/Layer/Segment/Radius';
import Positions from '@/Vue/Components/RegulationV2/Map/Base/Positions';
import Pin from "@/Vue/Components/RegulationV2/Map/Layer/Segment/Pin";
import {computed} from "vue";
import Route from "@/Vue/Components/RegulationV2/Map/Base/Route.vue";
import {useHistoriqueStore} from "@/Vue/Stores/RegulationV2/Historique";

const historiqueStore = useHistoriqueStore();
const {activeSegment, activeSegmentPosition, activeSegmentShowRoute, activeSegmentPositionIndex, activeSegmentRouteLoading} = storeToRefs(historiqueStore);

const positionsGeoJson = computed(() => ({
    type: 'FeatureCollection',
    features: activeSegment.value.positionCollection.map((position, index) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [position.longitude, position.latitude],
        },
        properties: {
            index,
            angle: activeSegment.value.enMouvement ? position.angle ?? undefined : undefined,
            icon: position.angle !== null ? (position.vitesse === null || position.vitesseLimite === null || position.vitesse <= position.vitesseLimite + 3 ? 'arrowGray':  (position.vitesse > position.vitesseLimite + 5 ? 'arrowRed' : 'arrowOrange')) : null,
        },
    })),
}));

const firstPosition = computed(() => activeSegment.value.positionCollection[0]);
const lastPosition = computed(() => activeSegment.value.positionCollection[activeSegment.value.positionCollection.length - 1]);

const onPositionClick = (index) => {
    activeSegmentPositionIndex.value = index;
};
</script>