<template>
    <li class="cti-menu d-flex">
        <template v-if="webSocketOnline && device && group">
            <Notification
                v-for="(call, index) in calls"
                :key="call.id"
                :call="call"
                :device="device"
                @closeNotification="calls.splice(index, 1)"
            ></Notification>
        </template>
        <Poste
            :device="device"
            :group="group"
            :groups="groups"
            :phones="phones"
            :ready="ready"
            :status="!webSocketOnline ? 'UNAVAILABLE' : status"
            @dialing="onDialing($event.number, $event.match)"
        ></Poste>
    </li>
</template>

<script setup>
import {ref, watch} from "vue";
import {useWebSocket} from "@/Vue/Composables/WebSocket";
import Moment from "moment";
import Notification from "@/Vue/Components/Cti/Notification";
import Poste from "@/Vue/Components/Cti/Poste";

const props = defineProps({
    ctiData: Object
})

let dialingTimeout = null;

const calls = ref([]);
const status = ref('UNAVAILABLE');
const group = ref(props.ctiData.groupe);
const device = ref(props.ctiData.poste);
const groups = ref([]);
const phones = ref([]);
const {webSocketOnline} = useWebSocket();
const ready = ref(false);

App.webSocket.subscribe('cti', (uri, data) => {
    if (data.type === 'failure') {
        App.Notification.error(data.flash);
    } else {
        ready.value = true;

        if (data.groups) {
            groups.value = data.groups;
        }
        if (data.phones) {
            phones.value = data.phones;
        }
        if (data.status) {
            status.value = data.status;
        }
        if (data.device) {
            device.value = data.device;
        }
        if (data.group) {
            group.value = data.group;
        }
        if (Array.isArray(data.calls)) {
            calls.value.splice(0, calls.value.length, ...data.calls);
            if (dialingTimeout) {
                clearTimeout(dialingTimeout);
            }
        }
    }
});

const updateAgent = () => {
    if (device.value && group.value && '1' !== localStorage.getItem('ctiManuallyLoggedOff')) {
        App.webSocket.publish('cti', {action: 'updateAgent', device: device.value, group: group.value});
    }
};

const removeClearedCalls = (idToKeep = null) => {
    for (let index = 0; index < calls.value.length; index++) {
        if (calls.value[index].id !== idToKeep && calls.value[index].isCleared) {
            calls.value.splice(index, 1);
        }
    }
};


const onDialing = (number, match) => {
    // Pour éviter le temps de latence entre l'appel à l'api et la réponse du ws, fait office de loader
    removeClearedCalls();
    let now = Moment().format();
    calls.value.push({
        "id": "dialing",
        "remote": number,
        "isConnected": false,
        "isDelivered": false,
        "isOriginated": true,
        "isEstablished": false,
        "isRetrieved": false,
        "isHeld": false,
        "isCleared": false,
        "canAnswer": false,
        "canDivert": false,
        "canTransfer": false,
        "canHang": false,
        "canHold": false,
        "canResume": false,
        "callAt": now,
        "answerAt": null,
        "lastCommunication": {
            "id": "dialing",
            "local": device.value,
            "remote": number,
            "state": "initiated",
            "incoming": false,
            "callAt": now,
            "answerAt": null,
            "hangAt": null
        },
        "matches": match ? [match] : [],
    });
    dialingTimeout = setTimeout(() => {
        App.Notification.error('Échec de l\'appel au ' + number + '. Délai d\'attente dépassé.');
        const indexDialingCall = calls.value.findIndex(c => c.id === 'dialing');
        if (indexDialingCall !== -1) {
            calls.value.splice(indexDialingCall, 1);
        }
    }, 10000);
};


watch(webSocketOnline, () => {
    if (webSocketOnline.value) {
        updateAgent();
    }
}, {immediate: true});

$('body').on('click', 'a[href^="tel:"]', (e) => {
    const number = e.currentTarget.href.replace('tel:', '');

    App.webSocket.publish('cti', {action: 'call', number});

    // popover vue
    // this.call(number);

    return false;
});
</script>