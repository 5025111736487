<template>
    <div class="d-flex align-items-center position-relative">
        <div ref="btn" class="menu-dropdown-btn" :class="{'menu-dropdown-btn-open': value}">
            <slot name="button"></slot>
        </div>
        <transition name="menu-dropdown-content">
            <div class="menu-dropdown-content" v-if="value" ref="dropdown">
                <slot name="dropdown"></slot>
            </div>
        </transition>
    </div>
</template>

<script setup>
import {toRefs, ref} from "vue";
import {onClickOutside} from "@vueuse/core";

const emit = defineEmits(['input'])

const props = defineProps({
    value: Boolean,
})

const {value} = toRefs(props);

const btn = ref();


const dropdown = ref();
onClickOutside(dropdown, () => emit('input', false), {ignore: [btn]});

</script>