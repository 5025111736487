'use strict';

import Vue from "vue";

App.Cti = class {
    constructor() {
        const $ctiMenu = $('#ctiMenu');

        if ($ctiMenu.length) {
            const data = $ctiMenu.data('data');

            new Vue({
                el: $ctiMenu[0],
                pinia: App.Pinia,
                template: '<cti-menu :ctiData="ctiData"></cti-menu>',
                data: {
                    ctiData: data,
                },
            });
        }
    }
}
