import {defineStore, storeToRefs} from "pinia";
import Request from "@/App/Request";
import Router from "@/App/Router";
import {computed, nextTick, ref, watch} from "vue";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";

export const useHistoriqueStore = defineStore('historique', () => {
    const geolocalisationStore = useGeolocalisationStore();
    const {tab, activeRessource, activeRessourcePopup} = storeToRefs(geolocalisationStore);

    const ressourceHistoriqueCurrentPage = ref(1);
    const ressourceHistoriqueSortDirection = ref(-1);

    const activeSegment = ref(null);
    const activeSegmentLoading = ref(false);
    const activeSegmentShowRoute = ref(false);
    const activeSegmentRouteLoading = ref(false);
    const activeSegmentRessource = ref(null);
    const activeSegmentPositionIndex = ref(0);
    const activeSegmentPosition = computed(() => activeSegment.value?.positionCollection[activeSegmentPositionIndex.value]);
    const ressource = computed(() => activeSegmentRessource.value ?? activeRessourcePopup.value);

    watch(ressource, (newRessource, oldRessource) => {
        if (!activeSegmentLoading.value) {
            if (newRessource && newRessource.key !== oldRessource?.key) {
                ressourceHistoriqueCurrentPage.value = 1;

                // todo utiliser futur champ sourceGeolocalisation
                let hasGps = false;
                for (const appareil of ressource.value.geolocalisation.appareilCollection) {
                    if (appareil.type.estGeoloc) {
                        hasGps = true;
                        break;
                    }
                }

                filter.value.type = hasGps ? 'geoloc' : 'tla';
            }
        }
    });

    watch(activeSegment, () => {
        activeSegmentPositionIndex.value = 0;
    })

    const loadSegment = (id) => {
        activeSegmentLoading.value = true;

        return new Promise((resolve) => {
            Request.getJson(Router.generate('regulation-v2.segment', {id})).then(data => {
                tab.value = 'historique';
                if (activeRessource.value) {
                    activeSegmentRessource.value = activeRessource.value;
                }
                activeSegment.value = data;
                nextTick(() => activeSegmentLoading.value = false);

                resolve();
            });
        });
    };

    const filter = ref({
        type: 'geoloc',
        debut: null,
        fin: null,
    });

    const resetFilter = () => {
        filter.value = {
            type: 'geoloc',
            debut: null,
            fin: null,
        };
    }

    const close = () => {
        if (tab.value === 'historique') {
            tab.value = null;
        }
        activeSegment.value = null;
        activeRessourcePopup.value = activeSegmentRessource.value;
        activeSegmentRessource.value = null;
        resetFilter();
    }

    return {
        ressourceHistoriqueCurrentPage,
        ressourceHistoriqueSortDirection,
        activeSegment,
        activeSegmentLoading,
        activeSegmentShowRoute,
        activeSegmentRouteLoading,
        activeSegmentRessource,
        activeSegmentPositionIndex,
        activeSegmentPosition,
        loadSegment,
        resetFilter,
        filter,
        close,
    }
});