import {computed, ref, watch} from "vue";

export function piniaHistory({store}) {
    const enabled = ref(false);
    let initialState = undefined;
    let trackedKeys = [];
    let undoStack = ref([]);
    let redoStack = ref([]);
    let currentState = undefined;
    let suspendTracking = false;
    let undoStackDates = [];
    let redoStackDates = [];
    let groupBuffer = undefined;
    const nbAroundCurrent = 3;

    const updateCurrentState = () => {
        currentState = _.cloneDeep(store.$state);
    }

    const undo = () => {
        if (canUndo.value) {
            suspendTracking = true;

            const {patch, date} = popUndoStack();
            pushRedoStack(patch, date);

            reset();

            for (const patchObject of undoStack.value) {
                store.$patch(_.cloneDeep(patchObject));
            }

            updateCurrentState();
            suspendTracking = false;
        }
    }

    const redo = () => {
        if (canRedo.value) {
            suspendTracking = true;

            const {patch, date} = popRedoStack();

            store.$patch(_.cloneDeep(patch));

            pushUndoStack(patch, date);

            updateCurrentState();
            suspendTracking = false;
        }
    }

    const reset = () => {
        store.$patch((stateMutator) => {
            const initial = _.cloneDeep(initialState);
            for (const key in initial) {
                stateMutator[key] = initial[key];
            }
        });
    }

    const init = (initial) => {
        initialState = _.cloneDeep(initial);
        currentState = _.cloneDeep(initial);
        trackedKeys = Object.keys(initialState);
    }

    let unsubscribe = null;
    watch(enabled, () => {
        if (enabled.value) {
            unsubscribe = store.$subscribe((mutation, state) => {
                if (!suspendTracking) {
                    const newState = _.cloneDeep(state);
                    if (undefined === initialState) {
                        init(newState);
                    } else {
                        const patchObject = getStateDiff(currentState, newState);
                        if (Object.keys(patchObject).length) {
                            if (undefined === groupBuffer) {
                                savePatch(patchObject);
                                currentState = newState;
                            } else {
                                groupBuffer.push(patchObject);
                            }
                        }
                    }
                }
            });
        } else if (unsubscribe) {
            unsubscribe();
            unsubscribe = null;
        }
    });

    const savePatch = (patchObject) => {
        pushUndoStack(patchObject, Date.now());

        if (redoStack.value.length > 0) {
            clearRedoStack();
        }
    }

    const canUndo = computed(() => {
        return enabled.value && undoStack.value.length > 0;
    })

    const canRedo = computed(() => {
        return enabled.value && redoStack.value.length > 0;
    })

    const getStateDiff = (oldState, newState) => {
        if (undefined === oldState) {
            return newState;
        }

        let diff = {};
        for (const key of trackedKeys) {
            if (!_.isEqual(newState[key], oldState[key])) {
                diff[key] = newState[key];
            }
        }

        return diff;
    }

    // Si on passe un objet, ce sera le state initial de l'historique ;
    // Si on ne passe rien, le prochain patch fera office d'état initial ;
    // Seules les clés présentes dans l'état initial seront historisées.
    const enableHistory = (initial = null) => {
        if (!enabled.value) {
            enabled.value = true;
            init(initial ?? store.$state);
        }
    }

    const disableAndClearHistory = () => {
        enabled.value = false;
        undoStack.value = [];
        redoStack.value = [];
        undoStackDates = [];
        redoStackDates = [];
        initialState = undefined;
        trackedKeys = [];
        currentState = undefined;
    }

    const pushUndoStack = (patch,date) => {
        undoStack.value.push(patch);
        undoStackDates.push(date);
    }

    const popUndoStack = () => {
        let patch = undoStack.value.pop();
        return {patch, date : undoStackDates.pop()};
    }

    const pushRedoStack = (patch, date) => {
        redoStack.value.push(patch);
        redoStackDates.push(date);
    }

    const popRedoStack = () => {
        let patch = redoStack.value.pop();
        return {patch, date: redoStackDates.pop()};
    }

    const clearRedoStack = () => {
        redoStack.value = [];
        redoStackDates = [];
    }

    const dateTimeLocalFormatter = new Intl.DateTimeFormat('fr-FR', {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
    })

    const datesByVersion =  computed(() => {
        let versionsMap = new Map();

        if (!enabled) {
            return versionsMap;
        }

        versionsMap.set('initial', null);

        let nbUndoMax = nbAroundCurrent + Math.max(0, nbAroundCurrent - redoStack.value.length) + 1;
        let nbRedoMax = nbAroundCurrent + Math.max(0, nbAroundCurrent - undoStack.value.length);

        if (undoStack.value.length > nbUndoMax) {
            versionsMap.set('undoEllipsis', null);
        }

        for (let undoDateIndex in undoStackDates) {
            undoDateIndex = Number.parseInt(undoDateIndex);

            if ((undoDateIndex >= (undoStack.value.length - nbUndoMax))) {
                if (undoDateIndex === (undoStack.value.length - 1)) {
                    versionsMap.set('current', dateTimeLocalFormatter.format(undoStackDates[undoDateIndex]));
                } else {
                    versionsMap.set('undo-'+undoDateIndex, dateTimeLocalFormatter.format(undoStackDates[undoDateIndex]));
                }
            }
        }

        for (let redoDateIndex in redoStackDates) {
            redoDateIndex = Number.parseInt(redoDateIndex);
            let reverseIndex = redoStack.value.length - 1 - redoDateIndex;

            if (0 === reverseIndex || (reverseIndex >= (redoStack.value.length - nbRedoMax))) {
                if (0 === reverseIndex) {
                    if (redoStack.value.length > (nbRedoMax + 1)) {
                        versionsMap.set('redoEllipsis', null);
                    } else {
                        nbRedoMax--;
                    }
                }
                versionsMap.set('redo-'+reverseIndex, dateTimeLocalFormatter.format(redoStackDates[reverseIndex]));
            }
        }

        return versionsMap;
    })

    const goTo = (version) => {
        if ('initial' === version) {
            while(undoStack.value.length > 0) {
                undo();
            }
            return;
        }

        let parts = version.split('-');

        if ('undo' === parts[0] && parts[1] !== (''+(undoStack.value.length - 1))) {
            let undoStackLengthCache =  undoStack.value.length;

            for (let i = 1; i < undoStackLengthCache - Number.parseInt(parts[1]); i++) {
                undo();
            }
        } else if ('redo' === parts[0] && redoStack.value.length > 0) {
            let redoStackLengthCache =  redoStack.value.length;

            for (let i = 0; i < redoStackLengthCache - Number.parseInt(parts[1]); i++) {
                redo();
            }
        }
    }

    const startGrouping = () => {
        if (undefined === groupBuffer) {
            groupBuffer = [];
        }
    }

    const stopGrouping = () => {
        if (undefined !== groupBuffer) {
            let squashedPatch = {};
            _.assign(squashedPatch, ...groupBuffer);

            suspendTracking = true;
            savePatch(squashedPatch);
            suspendTracking = false;

            groupBuffer = undefined;
        }
    }

    return {undo, redo, disableAndClearHistory, canUndo, canRedo, enableHistory, datesByVersion, goTo, startGrouping, stopGrouping};
}