'use strict';

import {createApp} from "vue";
import Cookies from "js-cookie";
import Ladda from "ladda";
import Router from "../Router";
import Euro from "@/Vue/Filters/Euro";
import Filesize from "@/Vue/Filters/Filesize";
import Truncate from "@/Vue/Filters/Truncate";

App.Facturation.Scor = class {};

App.Facturation.Scor.View = class {
    constructor(params) {
        createApp({
            delimiters: ['[[', ']]'],
            data: () => ({
                ...params.vueData,
                previewPiece: null,
                previewDossier: null,
                Router,
            }),
            methods: {
                Euro, Filesize, Truncate,
                openPiece(piece, dossier) {
                    this.previewPiece = piece;
                    if (dossier) {
                        this.previewDossier = dossier;
                    }
                },
            },
            computed: {
                previewPieceScor() {
                    return this.previewPiece ? this.piecesScorByPieceId[this.previewPiece.id] : null;
                },
                previewStack() {
                    return this.previewDossier ? this.previewDossier.pieceCollection.map(pieceScor => pieceScor.piece).filter(Boolean) : [];
                },
                piecesScorByPieceId() {
                    return this.dossiers.reduce((res, dossier) => {
                        for (const pieceScor of dossier.pieceCollection) {
                            if (pieceScor.piece) {
                                res[pieceScor.piece.id] = pieceScor;
                            }
                        }

                        return res;
                    }, {});
                },
            },
            mounted() {
                $('#exportBtn').popover({
                    placement: 'top',
                    fallbackPlacement: [],
                    content: params.exportPopoverTemplate,
                    html: true,
                    sanitize: false,
                    title: params.exportPopoverTitle,
                }).on('show.bs.popover', function () {
                    $($(this).data('bs.popover').tip).css('min-width', '20%');
                }).on('shown.bs.popover', () => {
                    Cookies.remove('scorExportDownloadFinished');

                    let $exportPrintBtn = $('#exportPrintBtn');
                    let $exportDownloadBtn = $('#exportDownloadBtn');

                    let exportPrintBtnLadda = Ladda.create($exportPrintBtn[0]);
                    let exportDownloadBtnLadda = Ladda.create($exportDownloadBtn[0]);

                    let printUrl = Router.generate('facturation.lot.scor.export', {app: App.Constants.APPLICATION, id: params.entityId, type: 'html'});
                    let downloadUrl = Router.generate('facturation.lot.scor.export', {app: App.Constants.APPLICATION, id: params.entityId, type: 'pdf'});

                    $exportPrintBtn.on('click', (event) => {
                        exportPrintBtnLadda.start();
                        $exportDownloadBtn.prop('disabled', true);

                        App.Utils.print(printUrl).then(() => {
                            exportPrintBtnLadda.stop();
                            $exportDownloadBtn.prop('disabled', false);
                        });
                    })

                    $exportDownloadBtn.on('click', (event) => {
                        exportDownloadBtnLadda.start();
                        $exportPrintBtn.prop('disabled', true);

                        App.Utils.download(downloadUrl).then(() => {
                            let checkFinish = () => {
                                if (Cookies.get('scorExportDownloadFinished')) {
                                    exportDownloadBtnLadda.stop();
                                    $exportPrintBtn.prop('disabled', false);
                                    Cookies.remove('scorExportDownloadFinished');
                                } else {
                                    setTimeout(checkFinish, 1000);
                                }
                            };

                            checkFinish();
                        });
                    })
                });

                document.dispatchEvent(new Event('app:vuejs:mounted'));
            }
        }).mount('#app');
    }
};
