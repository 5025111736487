<template>
    <div class="text-left">
        <div class="row">
            <div class="col-6">
                <h3 class="text-theme mt-0">
                    <i class="fa-solid fa-grid-2"></i>
                    Ajouter une pièce
                </h3>
            </div>
            <div class="col-6">
                <div class="d-flex mb-3 mt-n1">
                    <div class="flex-1">
                        <div v-html="structureHtml"></div>
                    </div>
                    <b-btn variant="info" class="ml-3" @click="upload" :disabled="!structure || !hasFile || loading">
                        <i class="fa-solid fa-check"></i>
                        Ajouter
                    </b-btn>
                </div>
            </div>
        </div>
        <div id="globalDropzone" class="dropzone">
            <div class="dz-message">
                <div class="font-size-24px text-normal">Cliquez ici</div>
                <div class="text-muted">ou</div>
                <div class="font-size-16px text-normal mt-2">déposez directement les fichiers</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import swal from "sweetalert2";
import {nextTick, onBeforeMount, onMounted, ref} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";

const structureHtml = ref('');
const structure = ref(null);
let dropzone = null;
const loading = ref(true);
const hasFile = ref(false);

const upload = () => {
    if(dropzone.getQueuedFiles().length) {
        loading.value = true;
        hasFile.value = false;
        dropzone.options.url = Router.generate('piece.upload', {structure: structure.value});
        dropzone.processQueue();
    }
};

onBeforeMount(() => {
    const treeSelectInputSelector = 'input[name="global_piece[structure]"]';

    $('body').on('change', treeSelectInputSelector, () => {
        structure.value = $(treeSelectInputSelector + ':checked').val();
    }).one('treeselect.init', () => {
        $(treeSelectInputSelector).filter(':checked').change();
    }).on('change', 'select#global_piece_structure', (e) => {
        structure.value = $(e.currentTarget).val();
    });
});

onMounted(() => {
    dropzone = App.Shared.Piece.dropzone('#globalDropzone', {
        url: '/',
        hiddenInputContainer: '#globalUploadInput',
        autoProcessQueue: false,
    });

    dropzone.on('error', () => {
        loading.value = false;
        hasFile.value = false;
    });

    dropzone.on('addedfile', () => {
        hasFile.value = true;
    });

    dropzone.on('success', (file, data) => {
        loading.value = false;

        setTimeout(() => swal.close(), 1000);
        // todo open viewer
    });

    dropzone.handleFiles(document.getElementById('globalUploadInput').files);

    Request.getJson(Router.generate('piece.structure')).then((data) => {
        structureHtml.value = data.html;
        nextTick(() => {
            App.Layout.enableTreeSelect();
            loading.value = false;
        })
    });
});
</script>
