<template>
    <select :class="selectClass" :multiple="multiple">
        <slot></slot>
    </select>
</template>

<script>
    import addRemovedHook from "vue-removed-hook-mixin";
    import Router from "@/App/Router";

    export default {
        props: ['value', 'route', 'routeParams', 'placeholder', 'isInvalid', 'outlined', 'clearFieldsUnselect', 'template', 'multiple'],
        mixins: [addRemovedHook],
        data: function() {
            return {
                newUrl: null,
                currentUrl: null,
                currentData: [],
                initialClass: '',
            };
        },
        mounted: function () {
            $(this.$el)
                .select2(this.options)
                .on('change', (e) => {
                    if(this.multiple) {
                        const data = $(this.$el).find('option:selected').map((i, option) => ({id: option.value, text: option.text})).get();

                        this.$emit('input', data);
                        this.$emit('change', data);
                    } else  {
                        const id = e.currentTarget.value;

                        if(id) {
                            let text = e.currentTarget.options[e.currentTarget.selectedIndex].text;
                            this.$emit('input', {id, text});
                            this.$emit('change', {id, text});
                        } else {
                            this.$emit('input', null);
                            this.$emit('change', null);
                            this.$emit('reset');
                        }
                    }
                }).on('select2:select', (e) => {
                    let data = e.params.data;

                    if(data.fields) {
                        this.$emit('changes', data.fields);
                    }
                }).on('select2:unselecting', (e) => {
                    if(this.clearFieldsUnselect) {
                        this.$emit('changes', this.clearFieldsUnselect.reduce((res, item) => (res[item] = null, res), {}));
                    }
                })
            ;

            this.update();

            this.initialClass = this.$el.className;
        },
        methods: {
            update() {
                if (this.value) {
                    if(this.multiple) {
                        const options = [];
                        for(const item of this.value) {
                            options.push(new Option(item.text, item.id, true, true))
                        }

                        $(this.$el).html(options);

                    } else {
                        $(this.$el).html(new Option(this.value.text, this.value.id, true, true));
                    }
                } else {
                    $(this.$el).val(null);
                }

                $(this.$el).trigger('change.select2');
            }
        },
        computed: {
            options() {
                return {
                    ajax: {
                        url: (params) => {
                            let query = params && params.term ? params.term : '*';

                            this.newUrl = Router.generate(this.route, Object.assign(this.routeParams || {}, {query}));

                            if (this.newUrl === this.currentUrl) {
                                return null;
                            }

                            return this.newUrl;
                        },
                        transport: (params, success, failure) => {
                            if (params.url === false) return success([]);
                            if (params.url === null) return success(this.currentData);

                            let $request = $.ajax(params);

                            $request.then(success);
                            $request.fail(failure);

                            return $request;
                        },
                        dataType: 'json',
                        delay: 250,
                        data: {},
                        cache: false,
                        processResults: (data) => {
                            this.currentData = data;
                            return {
                                results: data
                            };
                        },
                        success: () => {
                            this.currentUrl = this.newUrl;
                        }
                    },
                    language: App.Constants.LOCALE_SHORT,
                    allowClear: !this.multiple && !$(this.$el).prop('required'),
                    placeholder: this.placeholder || '…',
                    templateResult: App.Utils.getAutocompleteTemplate(this.template),
                };
            },
            selectClass() {
                return {[this.initialClass]:true, 'is-invalid js-vue-validation': this.isInvalid, 'border-primary': this.outlined};
            }
        },
        watch: {
            value: function () {
                this.update();
            },
        },
        removed: function () {
            $(this.$el).off().select2('destroy')
        },
    }
</script>
