<template>
    <div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>ARLs échoués</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-table table-class="table-compact" ref="arlsTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="arls" :fields="arlsFields" :per-page="perPage" :current-page="currentPage" sort-by="date" sort-desc primary-key="id">
                        <template #empty>
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #table-busy>
                            <div class="p-3">
                                <b-spinner class="mr-2"></b-spinner> Chargement…
                            </div>
                        </template>
                        <template #cell(date)="{value}">
                            {{ value.format('DD/MM/YYYY HH:mm') }}
                        </template>
                        <template #cell(type)="{item}">
                            <template v-if="'rsp' === item.type">
                                <span class="badge badge-info">{{ Translator.trans('libelle.rsp') }}</span>
                            </template>
                            <template v-else-if="'scor' === item.type">
                                <span class="badge badge-primary">{{ Translator.trans('libelle.scor') }}</span>
                            </template>
                            <template v-else-if="'scor_chiffrement' === item.type">
                                <span class="badge badge-warning">{{ Translator.trans('libelle.scor_chiffrement') }}</span>
                            </template>
                        </template>
                        <template #cell(action)="{item}">
                            <a :href="Router.generate('administration.rsp.view', {id: item.id})" target="_blank" :role="item._roles.ROLE_ADMINISTRATION_RSP_VIEW" :title="Translator.trans('action.voir')" class="btn btn-table btn-info">
                                <i class="fa-solid fa-fw fa-eye"></i>
                            </a>
                        </template>
                    </b-table>
                    <table-pagination :count="arls.length" :per-page="perPage" v-model="currentPage"></table-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Moment from "moment/moment";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {onMounted, ref} from "vue";
import {useRequest} from "@/Vue/Composables/Request";

const {getJson} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const loading = ref(false);
const perPage = ref(12);
const currentPage = ref(0);
const arls = ref([]);
const arlsFields = [
    {key: 'date', label: Translator.trans('libelle.date'), thClass: 'width-1px', tdClass: 'text-nowrap', sortable: true, formatter: (value) => Moment(value, 'YYYY-MM-DD HH:mm')},
    {key: 'type', label: Translator.trans('libelle.type'), thClass: 'width-1px'},
    {key: 'from', label: Translator.trans('libelle.expediteur'), thClass: 'width-1px'},
    {key: 'to', label: Translator.trans('libelle.destinataire'), thClass: 'width-1px'},
    {key: 'subject', label: Translator.trans('libelle.sujet'), thClass: 'width-1px'},
    {key: 'lastError', label: Translator.trans('libelle.erreur')},
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px', tdClass: 'text-center'}
];

const refresh = () => {
    loading.value = true;

    getJson(Router.generate('administration.tableau-de-bord.arl')).then((data) => {
        arls.value = data;
        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
};

onMounted(() => refresh());

</script>
